import React from 'react';
import { Stack } from '@mui/material';
import TabRouteComponent from '../../../../components/TabRouteComponent.component';
import { Outlet } from 'react-router-dom';
import { DataRoutes } from '../../../../utils/routes.utils';
import Header from '../../../../components/header/Header.component';
import { UserRole } from '../../../../store/users';
import { useResponsive } from '../../../../utils/useResponsive';

const Data = (props: { role: UserRole }) => {
  const isMobile: boolean = useResponsive();

  const { role } = props;
  return (
    <Stack gap={1} pt={3} pb={3} height='100%' position="relative" >
      <Header selectedTab='data' role={role} />
      <Stack
        width='100%'
        height='100%'
        direction={isMobile ? 'column' : 'row'}
      >
        <TabRouteComponent role={role} routes={DataRoutes.routes} />
        <Outlet />
      </Stack>
    </Stack>
  )
}

export default Data;
