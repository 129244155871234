import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { BlacklistFilters } from "./blacklist.models";
import { blacklistServices } from "./blacklist.services";

export const searchBlacklistEffect = createEffectFn((params$: Observable<BlacklistFilters>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((filters) => blacklistServices.getBlacklist(filters))
  )
})