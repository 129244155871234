import { createRequestDataSource } from '@ngneat/elf-requests';
import { select } from '@ngneat/elf'
import { globalSettingsStore } from './globalSettings.store';

export const getGlobalSettingsDataSource = createRequestDataSource({
  store: globalSettingsStore,
  data$: () => globalSettingsStore.pipe(
    select((state) => state.globalSettings)
  ),
  requestKey: 'getGlobalSettings',
  dataKey: 'globalSettings'
})