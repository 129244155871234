import { SxProps, Theme, TextField, Typography } from "@mui/material";
import { Colors } from "src/constants/colors.constants";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
interface BasicTextfieldProps {
  title?: string | null;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  readonly?: boolean;
  password?: boolean;
  onChange?: (value: string) => void;
  onClick?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  multiline?: boolean;
  required?: boolean;
  minRows?: number;
  size?: "small" | "medium";
  color?: "primary" | "secondary";
  number?: boolean;
  error?: string;
  label?: string | null;
  type?: string;
  ariaLabel?: string;
  fullWidth?: boolean;
  height?: string;
  width?: string;
  autoComplete?: string;
  endAdornment?: JSX.Element;
  onBlur?: (e: any) => void;
  sx?: SxProps<Theme>;
  variant?: "outlined" | "filled";
  backgroundColor?: "primary" | "secondary";
  inputProps?: RegExp;
}

const BasicTextfield = (props: BasicTextfieldProps) => {
  const {
    title,
    placeholder,
    defaultValue,
    value,
    readonly,
    onChange,
    password,
    onKeyDown,
    label,
    onClick,
    multiline,
    required,
    minRows,
    size,
    number,
    color,
    error,
    type,
    ariaLabel,
    fullWidth,
    autoComplete,
    endAdornment,
    height,
    width,
    onBlur,
    sx,
    variant,
    inputProps,
  } = props;

  const { t } = useTranslation();
  console.log(label);
  console.log(required);

  return (
    <Stack>
      {title && (
        <Typography>
          {title} {required ? "*" : undefined}
        </Typography>
      )}
      <TextField
        aria-label={ariaLabel}
        onKeyDown={onKeyDown}
        label={label}
        size={size ?? "small"}
        style={{ height: height, width: width }}
        fullWidth={fullWidth ?? true}
        placeholder={required ? t("common.toBeCompleted") ?? "" : placeholder}
        defaultValue={defaultValue}
        color={color}
        onBlur={onBlur}
        onChange={(evt) => {
          let value = evt.target.value;
          onChange?.(value);
        }}
        onClick={onClick}
        InputProps={{
          readOnly: readonly,
          endAdornment: endAdornment,
          type: password ? "password" : number ? "number" : undefined,
        }}
        multiline={multiline}
        minRows={minRows}
        value={value}
        error={!!error}
        helperText={error}
        inputProps={{ inputProps }}
        InputLabelProps={{ shrink: true }}
        type={type}
        autoComplete={autoComplete ?? ""}
        sx={{
          ...sx,
          "& .MuiInputLabel-root": {
            color: required ? Colors.red : "rgba(0, 0, 0, 0.87)",
          },
          input: {
            "&::placeholder": {
              color: required ? "red" : undefined,
              opacity: 1,
            },
          },
        }}
        variant={variant}
      />
    </Stack>
  );
};

export default BasicTextfield;
