import { useState } from "react";
import { UserRole } from "../../store/users";
import { Button, IconButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import File from "../../assets/icons/icon_file_light.svg";
import Help from "../../assets/icons/icon_help_light.svg";
import DataWhite from "../../assets/icons/icon_database_light.svg";
import DataDark from "../../assets/icons/icon_database_dark.svg";
import ParamsWhite from "../../assets/icons/icon_settings_light.svg";
import ParamsDark from "../../assets/icons/icon_settings_dark.svg";
import { useResponsive } from "../../utils/useResponsive";

import CGUModale from "./CGU.modale";
import HelpModale from "./Help.modale";
import { sessionQuery } from "src/store/session";

interface HeaderProps {
  role: UserRole;
  selectedTab?: "data" | "params";
}

const Header = (props: HeaderProps) => {
  const isMobile: boolean = useResponsive();
  const { role, selectedTab } = props;

  const navigate = useNavigate();
  const user = sessionQuery.user;

  const [cguModalOpen, setCguModalOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  return (
    <>
      {cguModalOpen && (
        <CGUModale
          handleClose={() => {
            setCguModalOpen(false);
          }}
        />
      )}
      {helpModalOpen && (
        <HelpModale
          handleClose={() => {
            setHelpModalOpen(false);
          }}
        />
      )}
      <Stack
        direction="row"
        width="100%"
        justifyContent={isMobile ? "center" : "space-between"}
        flexWrap="wrap-reverse"
        gap={2}
      >
        {(role === UserRole.ADMIN_COMPANY || user.accountHolderId) && (
          <Stack direction="row" gap={2}>
            <Button
              onClick={() => navigate("/data/payments")}
              color={selectedTab !== "data" ? "info" : "secondary"}
              startIcon={<img src={selectedTab !== "data" ? DataDark : DataWhite} alt="" />}
            >
              Données
            </Button>
            <Button
              onClick={() => navigate("/dashboard")}
              color={selectedTab !== "data" ? "secondary" : "info"}
              startIcon={<img src={selectedTab !== "data" ? ParamsWhite : ParamsDark} alt="" />}
            >
              Paramètres
            </Button>
          </Stack>
        )}
        <Stack justifyContent="flex-end" direction="row" width="100%" gap={2}>
          <IconButton onClick={() => setCguModalOpen(true)} aria-label="dashboard" color="secondary">
            <img alt="" src={File} />
          </IconButton>
          <IconButton onClick={() => setHelpModalOpen(true)} aria-label="dashboard" color="primary">
            <img alt="" src={Help} />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
};

export default Header;
