import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { CategoryChildrenFilter } from "./categoryChildren.models";
import { categoryChildrenServices } from "./categoryChildren.services";

export const searchCategoryChildrenEffect = createEffectFn((params$: Observable<CategoryChildrenFilter>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((filters) => categoryChildrenServices.getCategoryChildren(filters))
  )
})