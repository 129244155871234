import { select } from '@ngneat/elf';

import { groupsStore } from './groups.store';
import { getGroupsDataSource } from './groups.requests';

export class GroupsQuery {
  store = groupsStore;

  groups$ = getGroupsDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));
}

export const groupsQuery = new GroupsQuery();