import dayjs from 'dayjs';
import frLocale from 'dayjs/locale/fr';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';

dayjs.locale(frLocale);

dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localeData);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.extend(weekday);

dayjs.updateLocale('FR', {
  months: [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet",
    "Août", "Septembre", "Octobre", "Novembre", "Decembre"
  ]
})

export const globalMonthShort = dayjs.monthsShort();
export const dayOfMonth = dayjs().date();

export default dayjs;
