import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { GroupsFilter, Group } from "./groups.models";

interface GroupStoreProps {
  filters: GroupsFilter;
}

export const groupsStore = createStore(
  { name: "groups" },
  withProps<GroupStoreProps>({
    filters: { page: 1, orderBy: "name", pageSize: 10 },
  }),
  withEntities<Group>(),
  withRequestsStatus()
);
