// @ts-ignore
import UrbanistThin from './Urbanist/Urbanist-Thin.ttf'

// @ts-ignore
import UrbanistLight from './Urbanist/Urbanist-Light.ttf'

// @ts-ignore
import UrbanistRegular from './Urbanist/Urbanist-Regular.ttf'

// @ts-ignore
import UrbanistMedium from './Urbanist/Urbanist-Medium.ttf'

// @ts-ignore
import UrbanistSemiBold from './Urbanist/Urbanist-SemiBold.ttf'

// @ts-ignore
import UrbanistBold from './Urbanist/Urbanist-Bold.ttf'

// @ts-ignore
import UrbanistExtraBold from './Urbanist/Urbanist-ExtraBold.ttf'

// @ts-ignore
import UrbanistBlack from './Urbanist/Urbanist-Black.ttf'

// @ts-ignore
import BitterRegular from './Bitter/Bitter-Regular.ttf';

// @ts-ignore
import BitterBold from './Bitter/Bitter-Bold.ttf';

// @ts-ignore
import MontserratBold from './Montserrat/Montserrat-Bold.ttf';

const fonts = `
  @font-face {
    font-family: 'Montserrat',
    font-weight: 700;
    src: local("MontserratBold"),
    url(${MontserratBold});
  }
  @font-face {
    font-family: 'Bitter';
    font-weight: 400;
    src: local("BitterRegular"),
    url(${BitterRegular});
  }
  @font-face {
    font-family: 'Bitter';
    font-weight: 700;
    src: local("BitterBold"),
    url(${BitterBold})
  }
  @font-face {
    font-family: 'Urbanist';
    font-weight: 200;
    src: local("UrbanistThin"),
    url(${UrbanistThin}) format("truetype");
  }
  @font-face {
    font-family: 'Urbanist';
    font-weight: 300;
    src: local("UrbanistLight"),
    url(${UrbanistLight}) format("truetype");
  }
  @font-face {
    font-family: 'Urbanist';
    font-weight: 400;
    src: local("UrbanistRegular"),
    url(${UrbanistRegular}) format("truetype");
  }
  @font-face {
    font-family: 'Urbanist';
    font-weight: 500;
    src: local("UrbanistMedium"),
    url(${UrbanistMedium}) format("truetype");
  }
  @font-face {
    font-family: 'Urbanist';
    font-weight: 600;
    src: local("UrbanistSemiBold"),
    url(${UrbanistSemiBold}) format("truetype");
  }
  @font-face {
    font-family: 'Urbanist';
    font-weight: 700;
    src: local("UrbanistBold"),
    url(${UrbanistBold}) format("truetype");
  }
  @font-face {
    font-family: 'Urbanist';
    font-weight: 800;
    src: local("UrbanistExtraBold"),
    url(${UrbanistExtraBold}) format("truetype");
  }
  @font-face {
    font-family: 'Urbanist';
    font-weight: 900;
    src: local("UrbanistBlack"),
    url(${UrbanistBlack}) format("truetype");
  }
`
export default fonts;