import { Card, CardActionArea, Stack, Typography, alpha } from "@mui/material";

interface FilterCardProps {
  color: string;
  label: string;
  data: string;
  total: number;
  selected?: boolean;
  onClick?: () => void;
}

const FilterCard = (props: FilterCardProps) => {
  const { color, label, data, total, selected, onClick } = props;

  return (
    <Card>
      <CardActionArea onClick={onClick} aria-label={selected ? "selected" : "filter"}>
        <Typography fontSize={14} fontWeight={700}>
          {label}
        </Typography>
        <Stack direction="row" gap={1} alignItems="baseline">
          <Typography color={color} fontSize={20} fontWeight={800}>
            {data}€
          </Typography>
          <Typography color={`${alpha(color, 0.5)}`} fontSize={14} fontWeight={500}>{`(${total})`}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default FilterCard;
