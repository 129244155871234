import { DashboardExpense, DashboardExpensesFilters } from "./dashboardExpenses.models";
import { enqueueSnackbar } from "notistack";
import APIAxios, { APIRoutes } from "src/api/axios.api";

export const getDashboardExpenses = async (filters: DashboardExpensesFilters) => {
  try {
    const res = await APIAxios<DashboardExpense[]>({ ...APIRoutes.GetDashboardExpenses(filters) });
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
  }
};
