import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { TransactionFilter, TransactionLight, TransactionFull, TransactionTotal, ExportTransaction } from "./transaction.models";

interface TransactionStoreProps {
  filters: TransactionFilter;
  transaction?: TransactionFull;
  totals?: TransactionTotal;
  exportTransaction?: ExportTransaction;
}

export const transactionStore = createStore(
  { name: 'transaction' },
  withProps<TransactionStoreProps>({
    filters: {},
    exportTransaction: {} as ExportTransaction
  }),
  withEntities<TransactionLight>(),
  withRequestsStatus()
)
