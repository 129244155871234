import { Card, Switch, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Colors } from '../../constants/colors.constants';

interface SwitchCardProps {
  title: string;
  subtitle: string;
  onChange: () => void;
  textfield?: JSX.Element;
  noSwitch?: boolean;
  isOn?: boolean;
}

const SwitchCard = (props: SwitchCardProps) => {

  const { title, subtitle, onChange, textfield, noSwitch, isOn } = props;

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card sx={{padding: sm ? '20px' : '40px'}}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
        <Stack>
          <Typography fontSize='22' fontWeight='700' color={Colors.primaryText}>{title}</Typography>
          <Typography fontSize='18' fontWeight='18' color={Colors.secondaryText}>{subtitle}</Typography>
        </Stack>
        <Stack direction='row' alignItems='center' gap={2}>
          <>
          {textfield}
          {!noSwitch && (
            <Switch checked={isOn} onChange={onChange} />
          )}
          </>
        </Stack>
      </Stack>
    </Card>
  )
}

export default SwitchCard;