import { createRequestDataSource } from '@ngneat/elf-requests';
import { selectAllEntities } from '@ngneat/elf-entities';

import { taxesStore } from './taxes.store';

export const getTaxesDataSource = createRequestDataSource({
  store: taxesStore,
  data$: () => taxesStore.pipe(selectAllEntities()),
  requestKey: 'getTaxes',
  dataKey: 'taxes'
});