import { Stack, Typography } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { format } from "date-fns";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { scoreQuery } from "src/store/scores/score.query";
import { scoreService } from "src/store/scores/score.services";
import { useResponsive, useResponsiveTablet } from "src/utils/useResponsive";
import GroupScore from "./individualDashboardComponent/GroupScore.component";
import PersonalScore from "./individualDashboardComponent/PersonalScore.component";

const IndividualDashboradScreen = () => {
  const isMobile: boolean = useResponsive();
  const isTablet: boolean = useResponsiveTablet();
  const { t: t_dashboard } = useTranslation(undefined, {
    keyPrefix: "individualDashboard",
  });

  const getFirstDayOfCurrentMonth = (): Date => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const from_date = useMemo(() => new Date(getFirstDayOfCurrentMonth()), []);
  const to_date = useMemo(() => new Date(), []);
  const [{ scores }] = useObservable(scoreQuery.scores$);

  useEffect(() => {
    scoreService
      .getScore(format(from_date, "yyyy-MM-dd"), format(to_date, "yyyy-MM-dd"))
      .subscribe();
  }, [from_date, to_date]);

  return (
    <Stack
      p={isMobile ? 0 : 3}
      width="100%"
      marginBottom={3}
      maxHeight={!isMobile ? "calc(100vh - 265px)" : undefined}
      overflow={!isMobile ? "auto" : undefined}
    >
      <Stack pb={3}>
        <Typography
          fontSize="30px"
          fontWeight="800"
          style={isMobile ? { marginTop: "40px" } : undefined}
        >
          {t_dashboard("title")}
        </Typography>
        <Typography fontSize="23px" fontWeight="600" color="text.secondary">
          {t_dashboard("subtitle")}
        </Typography>
      </Stack>
      <Stack
        display={"flex"}
        justifyContent={"center"}
        flexDirection={isMobile || isTablet ? "column" : "row"}
        gap={"30px"}
      >
        <PersonalScore
          scores={scores[0]}
          from_date={from_date}
          to_date={to_date}
        />
        <GroupScore scores={scores[0]} />
      </Stack>
    </Stack>
  );
};

export default IndividualDashboradScreen;
