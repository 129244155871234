import { select } from "@ngneat/elf";

import { midStore } from "./mid.store";
import { getMidDataSource } from "./mid.requests";

export class MIDQuery {
  store = midStore;

  midExceptions$ = getMidDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));

  pagination$ = this.store.pipe(select((state) => state.pagination));
}

export const midExceptionsQuery = new MIDQuery();
