import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import UnAuth from './unAuth/UnAuth';
import Auth from './auth/Auth';

import { sessionQuery, sessionService } from '../store/session';
import { finalize } from 'rxjs';
import { useObservable } from '@ngneat/react-rxjs';
import { useLocation, useNavigate } from "react-router-dom"; // Importer useHistory
import {paymentService} from '../store/payments/payments.services'

const Screens = () => {
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [{ user }] = useObservable(sessionQuery.isAuthenticated$);

  // Function to handle payment ID from URL
  const handlePaymentIdFromURL = (callback: (paymentId: string) => void) => {
    const queryParams = new URLSearchParams(location.search);
    const paymentIdFromURL = queryParams.get("payment_id");
    if (paymentIdFromURL) {
      paymentService.setPaymentIdFromUrl(paymentIdFromURL)
    }
  };



  useEffect(() => {
    handlePaymentIdFromURL((hashedPaymentId) => {
    });
  }, [location.search]);

  useEffect(() => {
    if (sessionQuery.token) {
      sessionService
        .fetchCurrentUser()
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      setLoading(false);
    }

  }, [enqueueSnackbar]);

  const isAuthenticated = !!user;




  if (loading) return null;

  if (isAuthenticated) {
    return (
      <Auth />
    )
  }
  return <UnAuth />
};

export default Screens;
