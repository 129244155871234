import React from 'react'
import {
    Button,
    DialogTitle,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";

const PwaModal = ({show, onClose, onInstall}:any) => {
    return (
        <div>
            <Dialog
                open={show}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"installez l'application"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Cliquez sur le bouton ci-dessous pour installer l'application
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onInstall} sx={{
                        color: 'black',
                    }} >Install PWA</Button>
                    <Button onClick={onClose} sx={{
                        color: 'black',
                    }} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

export default PwaModal
