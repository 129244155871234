import { selectAllEntities } from "@ngneat/elf-entities";
import { createRequestDataSource } from "@ngneat/elf-requests";

import { scoreStore } from "./score.store";

export const GETScoreDataSource = createRequestDataSource({
  store: scoreStore,
  data$: () => scoreStore.pipe(selectAllEntities()),
  requestKey: 'getScore',
  dataKey: 'scores'
});