import { Components } from '@mui/material';

const chip: Components = {
  MuiChip: {
    styleOverrides: {
      root: () => ({
        borderRadius: 8,
        height: 32,
        paddingRight: 13,
        paddingLeft: 13,
      })
    }
  }
}

export default chip;