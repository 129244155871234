import { Components, alpha } from '@mui/material';
import { Colors } from '../constants/colors.constants';

const accordion: Components = {
  MuiAccordion: {
    styleOverrides: {
      root: (props) => ({
        marginTop: '10px !important',

        backgroundColor: Colors.background,
        boxShadow: 'none',
        borderRadius: 8,
        border: 'none',
        ':before': {
          display: 'none',
        },
      })
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: (props) => ({
        backgroundColor: `${alpha(Colors.primary, 0.2)}`,
        borderRadius: 8,
        minHeight: '39px !important',
        height: '39px !important',
        border: 'none',
      })
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: (props) => ({
        backgroundColor: Colors.white,
        borderRadius: 8,
        border: 'none',
        marginTop: 10,
      })
    }
  },
}

export default accordion;