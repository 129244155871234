import { Components } from "@mui/material";
import { Colors } from "../constants/colors.constants";

const textfield: Components = {
  MuiTextField: {
    styleOverrides: {
      root: (props) => ({
        backgroundColor: "white",
        fontSize: 16,
        fontWeight: 500,
        color: Colors.secondaryText,
        height: 54,
        borderRadius: 10,
        justifyContent: "center",
        "& .MuiInputBase-root": {
          borderRadius: 0,
          border: 0,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "& .Mui-focused": {
          border: 1,
        },
        "& .MuiInputLabel-root": {
          top: -10,
        },
        svg: {
          color: Colors.secondaryText,
          height: 24,
          width: 24,
          marginRight: "10px",
        },
        ...(props.ownerState["aria-label"] === "white"
          ? {
              backgroundColor: Colors.background,
            }
          : {}),
        "& .MuiFormHelperText-root": {
          backgroundColor: "transparent",
          padding: 0,
          margin: 0,
          marginBottom: -25,
        },
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        "& .MuiAutocomplete-endAdornment": {
          top: 15,
        },
      },
    },
  },
  MuiSelect: {
    variants: [
      {
        props: { color: "secondary" },
        style: {
          backgroundColor: Colors.background,
          borderRadius: 10,
          height: 54,
          paddingLeft: 14,
        },
      },
    ],
  },
};

export default textfield;
