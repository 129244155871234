import { BarChart, Bar, ResponsiveContainer, LabelList } from "recharts";
import { DashboardExpense } from "@store/dashboardExpenses/dashboardExpenses.models";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { Colors } from "src/constants/colors.constants";
import { useNavigate } from "react-router-dom";
import { sessionQuery } from "src/store/session";
import { useObservable } from "@ngneat/react-rxjs";
import { UserRole } from "src/store/users";

interface DashboardExpensesChartsProps {
  dashboardExpense: DashboardExpense;
}

export const barColors = { min: "#808080", user: "#DDA0DD", max: "#FFA500" };

const DashboardExpensesCharts = (props: DashboardExpensesChartsProps) => {
  const { dashboardExpense } = props;
  const navigate = useNavigate();
  const [user] = useObservable(sessionQuery.user$);

  const data = [
    {
      name: "data",
      min: dashboardExpense.min.toFixed(2),
      user: dashboardExpense.userAmount.toFixed(2),
      max: dashboardExpense.max.toFixed(2),
    },
  ];

  const handleClick = (id?: string) => {
    navigate("/data/payments", {
      state: { categoryName: dashboardExpense.categoryName, userId: id },
    });
  };

  const labelBarPosition = (value: number) => (value > 0 ? "inside" : "top");
  const isEmployee = user?.role === UserRole.EMPLOYEE;

  return (
    <Card>
      <CardContent>
        <Stack
          onClick={() => handleClick()}
          style={{ cursor: "pointer" }}
          direction={"row"}
          alignItems={"center"}
          spacing={2}
        >
          <img src={dashboardExpense.categoryLogo} alt="category logo" width="50px" />
          <Typography style={{ fontSize: "24px", fontWeight: "700" }}>{dashboardExpense.categoryName}</Typography>
        </Stack>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <Bar
              {...(!isEmployee
                ? { style: { cursor: "pointer" }, onClick: () => handleClick(dashboardExpense.minUser) }
                : {})}
              dataKey={"min"}
              fill={barColors.min}
            >
              <LabelList
                dataKey="min"
                style={{ fill: Colors.black, fontWeight: 700 }}
                position={labelBarPosition(dashboardExpense.min)}
              />
            </Bar>
            <Bar
              onClick={() => handleClick(user?.id)}
              style={{ cursor: "pointer" }}
              dataKey={"user"}
              fill={barColors.user}
            >
              <LabelList
                dataKey="user"
                style={{ fill: Colors.black, fontWeight: 700 }}
                position={labelBarPosition(dashboardExpense.userAmount)}
              />
            </Bar>
            <Bar
              {...(!isEmployee
                ? { style: { cursor: "pointer" }, onClick: () => handleClick(dashboardExpense.maxUser) }
                : {})}
              dataKey={"max"}
              fill={barColors.max}
            >
              <LabelList
                dataKey="max"
                style={{ fill: Colors.black, fontWeight: 700 }}
                position={labelBarPosition(dashboardExpense.max)}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default DashboardExpensesCharts;
