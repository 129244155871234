import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { ScoreDto } from "./score.models";

interface ScoreStoreProps {}

export const scoreStore = createStore(
  { name: "score" },
  withProps<ScoreStoreProps>({}),
  withEntities<ScoreDto>(),
  withRequestsStatus()
);
