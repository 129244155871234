import { enqueueSnackbar } from "notistack";
import APIAxios, { APIRoutes } from "src/api/axios.api";
import { AccountBalance } from "./swan.model";

export const getAccountBalance = async () => {
  try {
    const res = await APIAxios<AccountBalance[]>({ ...APIRoutes.GetAccountBalance() });
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
  }
};
export const SuspendSepa = async (sepaId: string) => {
  try {
    const res = await APIAxios({ ...APIRoutes.SuspendSepa(sepaId) });
    if (res.data) {
      return true;
    }
  } catch (err) {
    enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
  }
};
