import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Locales, LocaleTranslations } from 'src/constants/locales.constants';
import dayjs from '../utils/dayjs.utils';

i18n
  .use(initReactI18next)
  .init({
    resources: LocaleTranslations,
    fallbackLng: Locales.FR,
    lng: Locales.FR,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => undefined);

export default i18n;
