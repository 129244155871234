import { SxProps, Theme, TextField, Typography, InputAdornment, Switch } from "@mui/material";
import { Colors } from '../../constants/colors.constants';
import { Stack } from "@mui/system";
import BasicTextfield from "./BasicTextfield.component";
import { useState } from "react";
;

interface SwitchTextfieldProps {
  title?: string;
  isOn?: boolean;
  handleSwitch?: (isOn: boolean) => void;
  handleChange?: (value: string) => void;
  value?: string;
}

const SwitchTextfield = (props: SwitchTextfieldProps) => {
  const{
    title,
    isOn,
    handleSwitch,
    handleChange,
    value
  } = props;

  const [switchOn, setSwitchOn] = useState(isOn || false);

  return (
    <Stack 
      sx={{
        backgroundColor: '#FAFAFA', 
        borderRadius: '10px' }}
      height='54px' 
      alignItems='center'
      justifyContent='space-between' 
      p={1}
      direction='row'>
      <Stack>
        <Typography fontSize='16' fontWeight='500' color={Colors.secondaryText}>
          {title}
        </Typography>
      </Stack>
      <Stack>
        {switchOn && (
          <BasicTextfield placeholder='ex: 40' onChange={handleChange} value={value} height={'38px'} width='70px' fullWidth={false} color='secondary' size='small' variant='outlined' />
        )}
        </Stack>
        <Switch checked={switchOn} onChange={(e) => {
            handleSwitch?.(e.target.checked);
            setSwitchOn(e.target.checked);
          }} />
    </Stack>
  )
}

export default SwitchTextfield;