import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { categoriesStore } from "./categories.store";
import { CategoriesFilter, Category, CategoryType, GetCategories, formatRangeStringtoRange } from "./categories.model";
import { getCategoriesDataSource } from "./categories.requests";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import { AxiosError, AxiosResponse } from "axios";
import SnackError from "../../utils/errors.utils";
import { PaginationDto } from "@utils/pagination.dto";

export class CategoriesServices {
  store = categoriesStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<CategoriesFilter>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  setPagination = (pagination: PaginationDto) =>
    this.store.update((state) => ({
      ...state,
      pagination,
    }));

  addCategory = (newCategory: Partial<Category>): Observable<Category> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTCategory(),
        data: {
          ...newCategory,
          name: [{ lang: "FR", value: newCategory.name }],
          mcc: formatRangeStringtoRange(newCategory.mccString ?? ""),
          averageCO2Consumption:
            newCategory.type === CategoryType.MSP || newCategory.type === CategoryType.ASP
              ? newCategory.averageCO2Consumption
              : undefined,
          isSubjectToKilometers: newCategory.type === CategoryType.ASP ? newCategory.isSubjectToKilometers : undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Category>) => {
        return response.data;
      }),
      tap((category) => {
        this.store.update(addEntities(category));
      })
    );
  };

  getCategories = (filters: CategoriesFilter): Observable<GetCategories> => {
    return from(
      APIAxios({
        ...APIRoutes.GETCategories({
          page: filters.page,
          orderBy: filters.orderBy,
          orderByDirection: filters.orderByDirection || "asc",
          searchText: filters.searchText ? filters.searchText : undefined,
        }),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<GetCategories>) => {
        return response.data;
      }),
      tap((categories) => {
        this.store.update(setEntities(categories.serviceCategories), getCategoriesDataSource.setSuccess());
        this.setPagination(categories);
      }),
      getCategoriesDataSource.trackRequestStatus()
    );
  };

  getAllCategories = (images?: boolean): Observable<Category[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAllCategories(images),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Category[]>) => {
        return response.data;
      }),
      tap((categories) => {
        this.store.update(setEntities(categories), getCategoriesDataSource.setSuccess());
      }),
      getCategoriesDataSource.trackRequestStatus()
    );
  };

  updateCategory = (category: Partial<Category>, logoUrl?: string): Observable<Category> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTCategory(category?.id ?? ""),
        data: {
          ...category,
          name: [{ lang: "FR", value: category.name }],
          mcc: formatRangeStringtoRange(category.mccString ?? ""),
          id: undefined,
          averageCO2Consumption:
            category.type === CategoryType.MSP || category.type === CategoryType.ASP
              ? category.averageCO2Consumption
              : undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Category>) => {
        return response.data;
      }),
      tap((category) => {
        this.store.update(
          updateEntities(category.id, {
            ...category,
            logoUrl: logoUrl,
          })
        );
      })
    );
  };

  deleteCategory = (categoryId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETECategory(categoryId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        categoriesServices.store.update(deleteEntities(categoryId));
        return response.data;
      })
    );
  };
}

export const categoriesServices = new CategoriesServices();
