import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";

import { categoryChildrenStore } from "./categoryChildren.store";

export const getCategoryChildrenDataSource = createRequestDataSource({
  store: categoryChildrenStore,
  data$: () => categoryChildrenStore.pipe(selectAllEntities()),
  requestKey: 'getCategoryChildren',
  dataKey: 'categoryChildren'
});