import { FormEvent, useState, useEffect } from "react";
import { Button, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { taxesServices, CompanyTax } from "../../../../store/taxes";
import { FieldErrors } from "../../../../utils/yup.utils";
import APIAxios, { APIRoutes } from "../../../../api/axios.api";
import { Category } from "../../../../store/serviceCategories";
import { enqueueSnackbar } from "notistack";

import Close from "../../../../assets/icons/icon_close_dark.svg";
import BasicSelect from "../../../../components/inputs/BasicSelect.component";
import BasicTextfield from "../../../../components/inputs/BasicTextfield.component";
import { addTaxValidation } from "./AddTax.yup";

interface AddTaxProps {
  handleClose?: () => void;
  tax?: CompanyTax;
  companyId: string;
  taxes: CompanyTax[];
}

const AddTax = (props: AddTaxProps) => {
  const { handleClose, tax, companyId, taxes } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<CompanyTax>>(tax || {});
  const [errors, setErrors] = useState<FieldErrors>({});
  const [categories, setCategories] = useState<Category[]>([]);

  const { t } = useTranslation();

  const updateData = (name: string) => (value: any) => setData({ ...data, [name]: value });

  const getCategories = async (): Promise<void> => {
    return await APIAxios<Category[]>({ ...APIRoutes.GETAllCategories() }).then((res) => {
      if (res.data) {
        setCategories(res.data.filter((category) => !taxes.some((tax) => tax.serviceCategory.id === category.id)));
      }
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleValidate = (evt: FormEvent) => {
    evt.preventDefault();
    if (!data) return;

    if (data.taxPercent && data.taxPercent.toString().includes(",")) {
      data.taxPercent = parseFloat(data.taxPercent.toString().replace(",", "."));
    }

    const errors = addTaxValidation(data);

    setErrors(errors);

    if (!!Object.keys(errors).length) return;

    data.taxPercent = parseFloat((data.taxPercent ?? 0)?.toString());

    setLoading(true);

    if (tax) {
      taxesServices
        .updateTaxes(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: () => handleClose?.(),
        });
    } else {
      taxesServices
        .addTax(companyId, data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: () => handleClose?.(),
        });
    }
  };

  return (
    <Dialog open={true} maxWidth="md" style={{ borderRadius: "8px" }}>
      <form onSubmit={handleValidate} noValidate>
        <Stack direction="column" gap={4} p={4}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="30px" fontWeight={800}>
              {t("Règle de TVA automatique")}
            </Typography>
            <IconButton onClick={handleClose}>
              <img alt="" src={Close} />
            </IconButton>
          </Stack>
          <Grid container gap={3}>
            <Grid item xs>
              <BasicSelect
                ariaLabel="white"
                color="secondary"
                handleChange={(value) => {
                  updateData("serviceCategory")(categories.find((cat: Category) => cat.id === value));
                }}
                items={categories.map((it) => ({ label: it.name, value: it.id }))}
                fullWidth
                value={data.serviceCategory?.id || ""}
                placeholder={"Catégorie"}
                error={errors.serviceCategory}
              />
            </Grid>
            <Grid item xs>
              <BasicTextfield
                ariaLabel="white"
                type="number"
                onChange={updateData("taxPercent")}
                error={errors.taxPercent ? "Ce champ doit être un nombre valide" : ""}
                value={data.taxPercent?.toString()}
                placeholder="Pourcentage de TVA"
              />
            </Grid>
          </Grid>
          <Stack width="100%" alignItems="center">
            <Button
              type="submit"
              disabled={loading}
              style={{ width: "215px", height: "52px" }}
              color="secondary"
              variant="contained"
            >
              {tax ? t("global.validate") : t("Valider")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default AddTax;
