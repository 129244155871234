import { FormEvent, useState, useEffect } from "react";
import { Button, Checkbox, Dialog, FormControlLabel, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { Colors } from "../../../constants/colors.constants";
import { categoriesServices, Category, CategoryType, formatRangeToRangeString } from "../../../store/serviceCategories";
import { FieldErrors } from "../../../utils/yup.utils";
import { enqueueSnackbar } from "notistack";
import { addCategoryValidation } from "./AddCategory.yup";

import BasicTextfield from "../../../components/inputs/BasicTextfield.component";
import BasicSelect from "../../../components/inputs/BasicSelect.component";
import Upload from "../../../assets/icons/icon_upload_grey.svg";
import Close from "../../../assets/icons/icon_close_dark.svg";
import FileSelect from "../../../components/inputs/FileSelect.component";
import { globalSettingsServices } from "../../../store/globalSettings";

interface AddCategoryProps {
  handleClose?: () => void;
  category?: Category;
}

const AddCategory = (props: AddCategoryProps) => {
  const { handleClose, category } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<Category>>(category || {});
  const [errors, setErrors] = useState<FieldErrors>({});
  const [file, setFile] = useState<File | undefined>(undefined);

  const { t } = useTranslation();

  const updateData = (name: keyof Category) => (value: any) => setData({ ...data, [name]: value });

  useEffect(() => {
    if (category) {
      setData({
        ...category,
        mccString: formatRangeToRangeString(category.mcc),
      });
    }
  }, [category]);

  const handleValidate = (evt: FormEvent) => {
    evt.preventDefault();

    if (file) {
      handleUploadFile();
    } else {
      handleUpdateCategory();
    }
  };

  const handleUploadFile = () => {
    if (!file) return;
    globalSettingsServices.uploafFile(file).subscribe({
      error: (err) => {
        enqueueSnackbar(err.text, err.options);
      },
      next: (value) => {
        handleUpdateCategory(value.key, value.url);
      },
    });
  };

  const handleUpdateCategory = (key?: string, url?: string) => {
    if (!data) return;

    const errors = addCategoryValidation(data);

    setErrors(errors);

    if (!!Object.keys(errors).length) return;

    setLoading(true);

    if (category) {
      categoriesServices
        .updateCategory(
          {
            ...data,
            logoUrl: key,
          },
          url
        )
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: () => handleClose?.(),
        });
    } else {
      categoriesServices
        .addCategory({
          ...data,
          logoUrl: key,
        })
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => {
            enqueueSnackbar(err.text, err.options);
          },
          next: () => handleClose?.(),
        });
    }
  };

  return (
    <Dialog open={true} maxWidth={"md"} style={{ borderRadius: "8px" }}>
      <form onSubmit={handleValidate} noValidate>
        <Stack direction="column" gap={4} p={4}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="30px" fontWeight={800}>
              {t("categories.add.title")}
            </Typography>
            <IconButton onClick={handleClose}>
              <img alt="" src={Close} />
            </IconButton>
          </Stack>
          <Stack gap={3} direction="column">
            <Typography fontSize="20px" fontWeight={700}>
              {t("categories.add.subtitle")}
            </Typography>
            <Grid container gap={3}>
              <Grid item xs>
                <BasicTextfield
                  ariaLabel="white"
                  onChange={updateData("name")}
                  error={errors.name}
                  fullWidth
                  value={data?.name}
                  placeholder={t("categories.add.name") ?? ""}
                />
              </Grid>
              <Grid item xs>
                <BasicSelect
                  ariaLabel="white"
                  color="secondary"
                  handleChange={updateData("type")}
                  items={CategoryType.categories.map((it) => ({ label: it, value: it }))}
                  error={errors.type}
                  fullWidth
                  value={data?.type}
                  placeholder={t("categories.add.type") ?? ""}
                />
              </Grid>
            </Grid>
            {data.type === CategoryType.ASP && (
              <Grid item xs>
                <FormControlLabel
                  checked={data?.isSubjectToKilometers}
                  onChange={(e, checked) => updateData("isSubjectToKilometers")(checked)}
                  control={<Checkbox />}
                  label={t("categories.add.isSubjectToKilometers")}
                />
              </Grid>
            )}
            <Grid container gap={3}>
              <Grid item xs>
                <Stack direction="column">
                  <BasicTextfield
                    ariaLabel="white"
                    error={errors.mcc}
                    onChange={updateData("mccString")}
                    value={data?.mccString}
                    placeholder={t("categories.add.mcc") ?? ""}
                  />
                  <Typography top={errors.mcc ? 20 : 0} fontSize="12px" fontWeight={400} color={Colors.secondaryText}>
                    {t("categories.add.mccHelperText")}
                  </Typography>
                </Stack>
              </Grid>
              {(data.type === CategoryType.MSP || data.type === CategoryType.ASP) && (
                <Grid item xs>
                  <BasicTextfield
                    ariaLabel="white"
                    error={errors.averageCO2Consumption}
                    onChange={(value) => updateData("averageCO2Consumption")(value.split(",").join("."))}
                    value={data?.averageCO2Consumption ? data.averageCO2Consumption.toString() : ""}
                    placeholder={t("categories.add.co2") ?? ""}
                  />
                </Grid>
              )}
            </Grid>
            <Stack width="50%">
              <FileSelect
                fullwidth
                endIcon={<img src={Upload} alt="" />}
                ariaLabel="select-file-textfield"
                disabled={!category}
                file={file}
                handleFileChange={(v) => setFile(v)}
                label={t("categories.add.icon") ?? ""}
              />
            </Stack>
          </Stack>
          <Stack width="100%" alignItems="center">
            <Button
              type="submit"
              disabled={loading}
              style={{ width: "215px", height: "52px" }}
              color="secondary"
              variant="contained"
            >
              {category ? t("global.validate") : t("categories.add.create")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default AddCategory;
