import React, {ReactElement} from 'react';
import {Button, Dialog, IconButton, Stack, SxProps, Theme, Typography} from '@mui/material';
import Close from '../assets/icons/icon_close_dark.svg';

interface ConfirmDialogProps {
  handleClose: () => void;
  handleValidate: () => void;
  confirm: string;
  cancel: string;
  title: string;
  description?: string;
  customDescription?: ReactElement;
  sx?: SxProps<Theme>;
}


const ConfirmDialog = (props: ConfirmDialogProps) => {

  const { handleClose, handleValidate, confirm, cancel, title, description,customDescription, sx } = props;

  return (
    <Dialog sx={sx} maxWidth='md' open={true}>
      <Stack gap={4} p={4}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography fontSize='30px' fontWeight={800}>{title}</Typography>
          <IconButton onClick={() => handleClose()}><img alt='' src={Close} /></IconButton>
        </Stack>
        {description && (
            <Stack direction='row' justifyContent='space-between'>
              <Typography fontSize='18px' fontWeight={800}>{description}</Typography>
            </Stack>
          )}
        {
          customDescription && (
            customDescription
          )
        }
        <Stack direction='row' gap={2} justifyContent='center'>
          <Button variant='contained' color='error' onClick={() => handleValidate()}>{confirm}</Button>
          <Button color='secondary' onClick={() => handleClose()}>{cancel}</Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ConfirmDialog;
