import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { GlobalSettings } from "./globalSettings.models";

interface GlobalSettingsProps {
  globalSettings?: GlobalSettings;
}

export const globalSettingsStore = createStore(
  { name: 'globalSettings' },
  withProps<GlobalSettingsProps>({}),
  withRequestsStatus()
)
