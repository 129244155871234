import React, { FormEvent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { PaymentRule, paymentRulesServices, DayOfWeek, CountryCode } from "../../../../store/paymentRules";
import { finalize } from "rxjs";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Colors } from "../../../../constants/colors.constants";

import Close from "../../../../assets/icons/icon_close_dark.svg";
import SwitchTextfield from "../../../../components/inputs/SwitchTextfield.component";
import APIAxios, { APIRoutes } from "../../../../api/axios.api";
import BasicSelect from "../../../../components/inputs/BasicSelect.component";
import { Category } from "../../../../store/serviceCategories";

interface AddPaymentControlRuleProps {
  rule: PaymentRule | undefined;
  handleClose: () => void;
  groupId?: string;
}

const AddPaymentControlRule = (props: AddPaymentControlRuleProps) => {
  const { rule, handleClose, groupId } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<any>>(
    {
      ...rule,
      daysAffected: rule?.daysAffected ?? [
        DayOfWeek.MONDAY,
        DayOfWeek.TUESDAY,
        DayOfWeek.WEDNESDAY,
        DayOfWeek.THURSDAY,
        DayOfWeek.FRIDAY,
        DayOfWeek.SATURDAY,
        DayOfWeek.SUNDAY,
      ],
      group: groupId,
    } || {}
  );
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategoryCountry, setSelectedCategoryCountry] = useState(
    data.country ? "country" : data.category ? "category" : "none"
  );

  const { t } = useTranslation();

  const updateData = (name: string) => (value: any) => {
    setData({ ...data, [name]: value });
  };

  const getCategories = async (): Promise<void> => {
    return await APIAxios({ ...APIRoutes.GETAllCategories() }).then((res) => {
      if (res.data) {
        setCategories(res.data);
      }
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleValidate = (evt: FormEvent) => {
    evt.preventDefault();
    if (!data) return;

    setLoading(true);
    if (rule) {
      paymentRulesServices
        .updatePaymentRule({
          ...data,
          category: data.category,
        })
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: () => handleClose?.(),
        });
    } else {
      paymentRulesServices
        .addPaymentRule({
          ...data,
          category: data.category?.id,
        })
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: () => handleClose?.(),
        });
    }
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <form onSubmit={handleValidate} noValidate>
        <Stack direction="column" gap={4} p={4}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="30px" fontWeight={800}>
              {rule ? "Modifier une règle" : "Ajouter une règle"}
            </Typography>
            <IconButton onClick={handleClose}>
              <img alt="" src={Close} />
            </IconButton>
          </Stack>
          <Stack gap={3}>
            <Typography fontSize="20px" fontWeight={700}>
              {t("Informations sur la règle")}
            </Typography>
            <FormControl>
              <RadioGroup
                value={selectedCategoryCountry}
                onChange={(e) => setSelectedCategoryCountry(e.currentTarget.value)}
              >
                <Stack direction="row">
                  <FormControlLabel value="none" control={<Radio />} label="Aucun" />
                  <FormControlLabel value="category" control={<Radio />} label="Catégorie" />
                  <FormControlLabel value="country" control={<Radio />} label="Pays" />
                </Stack>
              </RadioGroup>
            </FormControl>
            <Grid container gap={3}>
              <Grid item xs>
                {selectedCategoryCountry === "category" && (
                  <BasicSelect
                    ariaLabel="white"
                    color="secondary"
                    handleChange={(value) =>
                      updateData("category")(categories.find((cat: Category) => cat.id === value))
                    }
                    items={categories.map((it) => ({ label: it.name, value: it.id }))}
                    fullWidth
                    value={data.category?.id || ""}
                    placeholder={"Catégorie"}
                  />
                )}
                {selectedCategoryCountry === "country" && (
                  <BasicSelect
                    ariaLabel="white"
                    color="secondary"
                    handleChange={(value) => updateData("country")(value)}
                    items={CountryCode.countryCodes.map((it) => ({ label: CountryCode.label(it), value: it }))}
                    fullWidth
                    value={data.country || ""}
                    placeholder={"Pays"}
                  />
                )}
              </Grid>
              <Grid item xs>
                <SwitchTextfield
                  handleChange={updateData("perTransactionThreshold")}
                  isOn={!!data.perTransactionThreshold && data.perTransactionThreshold !== -1}
                  value={data.perTransactionThreshold?.toString()}
                  handleSwitch={(isOn) => updateData("perTransactionThreshold")(isOn ? "0" : -1)}
                  title="Plafond par paiement"
                />
              </Grid>
            </Grid>
            <Grid container gap={3}>
              <Grid item xs>
                <SwitchTextfield
                  handleChange={updateData("perDayThreshold")}
                  handleSwitch={(isOn) => updateData("perDayThreshold")(isOn ? "0" : -1)}
                  isOn={!!data.perDayThreshold && data.perDayThreshold !== -1}
                  value={data.perDayThreshold?.toString()}
                  title="Plafond par jour"
                />
              </Grid>
              <Grid item xs>
                <Stack direction="row" alignItems="center" gap={0.6}>
                  <Typography fontSize="16" fontWeight="500" color={Colors.secondaryText}>
                    Jours non autorisés
                  </Typography>
                  {DayOfWeek.daysOfWeek.map((it) => (
                    <Button
                      aria-label={`weekday${data.daysAffected?.includes(it) ? "" : "-selected"}`}
                      onClick={() => {
                        var newDays = data.daysAffected ?? [];
                        if (newDays.includes(it)) {
                          newDays = newDays.filter((day: DayOfWeek) => day !== it);
                        } else {
                          newDays.push(it);
                        }
                        updateData("daysAffected")(newDays);
                      }}
                      sx={{
                        height: "38px",
                        maxWidth: "38px",
                        minWidth: 0,
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      {DayOfWeek.label(it)}
                    </Button>
                  ))}
                </Stack>
              </Grid>
            </Grid>
            <Grid container gap={3}>
              <Grid item xs>
                <SwitchTextfield
                  handleChange={(value) => updateData("perMonthThreshold")(value === "" ? -1 : value)}
                  isOn={!!data.perMonthThreshold && data.perMonthThreshold !== -1}
                  handleSwitch={(isOn) => {
                    updateData("perMonthThreshold")(isOn ? 0 : -1);
                  }}
                  value={data.perMonthThreshold?.toString()}
                  title="Plafond par mois"
                />
              </Grid>
              <Grid item xs>
                <SwitchTextfield
                  handleChange={updateData("perYearThreshold")}
                  isOn={!!data.perYearThreshold && data.perYearThreshold !== -1}
                  handleSwitch={(isOn) => updateData("perYearThreshold")(isOn ? "0" : -1)}
                  value={data.perYearThreshold?.toString()}
                  title="Plafond par année"
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack alignItems="center">
            <Button color="secondary" type="submit" style={{ height: "52px", textTransform: "none" }}>
              {rule ? t("global.validate") : t("global.add")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default AddPaymentControlRule;
