import { select } from '@ngneat/elf';
import { blacklistStore } from './blacklist.store';
import { getBlacklistDataSource } from './blacklist.requests';

export class BlacklistQuery {
  store = blacklistStore;

  blacklist$ = getBlacklistDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));
}

export const blacklistQuery = new BlacklistQuery();