import { ChevronLeft, ControlPoint } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { useEffectFn } from "@ngneat/effects-hooks";
import { useObservable } from "@ngneat/react-rxjs";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../constants/colors.constants";
import {
  Category,
  categoriesServices,
  formatRangeToRangeString,
  searchCategoriesEffect,
} from "../../../store/serviceCategories";
import { categoriesQuery } from "../../../store/serviceCategories/categories.query";
import { sessionQuery } from "../../../store/session";
import { UserRole } from "../../../store/users";

import { CustomDataGrid, HeaderTypography } from "src/utils/table/table.styles";
import Delete from "../../../assets/icons/icon_delete_color.svg";
import Edit from "../../../assets/icons/icon_edit_dark.svg";
import ConfirmDialog from "../../../components/Confirm.component";
import Header from "../../../components/header/Header.component";
import BasicSearchbar from "../../../components/inputs/BasicSearchBar.component";
import AddCategory from "./AddCategory.modale";

const CategoryScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchCategories = useEffectFn(searchCategoriesEffect);

  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<Category | undefined>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>();
  const [user] = useObservable(sessionQuery.user$);
  const [{ categories }] = useObservable(categoriesQuery.categories$);
  const [filters] = useObservable(categoriesQuery.filters$);
  const [pagination] = useObservable(categoriesQuery.pagination$);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [rowCountState, setRowCountState] = useState(pagination?.totalCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pagination?.totalCount !== undefined ? pagination?.totalCount : prevRowCountState
    );
  }, [pagination?.totalCount, setRowCountState]);

  useEffect(() => {
    if (!categoryModalOpen || !deleteModalOpen) searchCategories({ ...filters, page: paginationModel.page + 1 });
  }, [categoryModalOpen, deleteModalOpen, filters, searchCategories, paginationModel]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setPaginationModel({ page: 0, pageSize: 10 });
    categoriesServices.setFilters({
      orderByDirection: sortModel[0]?.sort || undefined,
      orderBy: sortModel[0]?.field || undefined,
    });
  }, []);

  const handleDeleteCategory = () => {
    categoriesServices.deleteCategory(categoryToDelete?.id ?? "").subscribe({
      next: () => {
        enqueueSnackbar(t("category.delete.success"), { variant: "success" });
        setDeleteModalOpen(false);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
    setCategoryToDelete(undefined);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("categories.table.categoryName")}</HeaderTypography>,
      flex: 1,
      minWidth: 200,
      renderCell(params) {
        return <Typography>{params.row.name}</Typography>;
      },
    },
    {
      field: "icon",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("categories.table.icon")}</HeaderTypography>,
      minWidth: 150,
      renderCell(params) {
        if (!params.row.logoUrl) {
          return;
        }
        const image = params.row.logoUrl;
        return <img src={image} alt="" style={{ height: "30px", width: "30px" }} />;
      },
    },
    {
      field: "mcc",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("categories.table.mcc")}</HeaderTypography>,
      flex: 1,
      minWidth: 200,
      renderCell(params) {
        return <Typography>{formatRangeToRangeString(params.row.mcc)}</Typography>;
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setSelectedCategory(params.row);
            }}
          >
            <img alt="" src={Edit} />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setCategoryToDelete(params.row);
            }}
          >
            <img src={Delete} alt="" />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    selectedCategory && setCategoryModalOpen(true);
  }, [selectedCategory]);

  useEffect(() => {
    categoryToDelete && setDeleteModalOpen(true);
  }, [categoryToDelete]);

  return (
    <>
      {categoryModalOpen && (
        <AddCategory
          category={selectedCategory}
          handleClose={() => {
            setCategoryModalOpen(false);
            setSelectedCategory(undefined);
          }}
        />
      )}
      {deleteModalOpen && (
        <ConfirmDialog
          title={t("categories.delete")}
          confirm={t("global.validate")}
          cancel={t("global.cancel")}
          handleClose={() => {
            setCategoryToDelete(undefined);
            setDeleteModalOpen(false);
          }}
          handleValidate={() => {
            handleDeleteCategory();
          }}
        />
      )}
      <Stack alignItems="start" height="100%" gap={2} pt={3}>
        <Stack width="100%">
          <Header role={user?.role ?? UserRole.SUPER_ADMIN} />
        </Stack>
        <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
          {t("global.back")}
        </Button>
        <Stack direction="row" justifyContent="space-between" width="100%" flexWrap="wrap">
          <Typography fontSize="30px" fontWeight={800} color={Colors.primaryText}>
            Catégories
          </Typography>
          <Stack direction="row" gap={2}>
            <BasicSearchbar
              placeholder="Rechercher ici"
              onChange={(value) => {
                categoriesServices.setFilters({ searchText: value, page: 0 });
                setPaginationModel({ page: 0, pageSize: 10 });
              }}
            />
            <Button
              style={{ height: "52px" }}
              variant="contained"
              color="secondary"
              onClick={() => setCategoryModalOpen(true)}
              startIcon={<ControlPoint />}
            >
              Ajouter
            </Button>
          </Stack>
        </Stack>
        <CustomDataGrid
          rows={categories}
          paginationModel={paginationModel}
          paginationMode="server"
          rowCount={rowCountState}
          onPaginationModelChange={setPaginationModel}
          pagination
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          disableDensitySelector
          disableColumnFilter
          rowSelection={false}
          filterMode="server"
          disableVirtualization
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columns={columns}
          slotProps={{
            pagination: {
              labelRowsPerPage: "test",
            },
          }}
        />
      </Stack>
    </>
  );
};

export default CategoryScreen;
