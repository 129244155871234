import { object, string, number } from 'yup';

import I18n from '../../../../utils/i18n.utils';
import * as YupUtils from '../../../../utils/yup.utils';
import { CategoryChildren } from '../../../../store/categoryChildren';

export const addCategoryChildValidation = (categoryChild: Partial<CategoryChildren>) => {
  const requiredString = string().required(I18n.t('errors.required') || '');

  try {
    const schema = object().shape({
      serviceCategory: object().required(),
    });
    schema.validateSync(categoryChild, { abortEarly: false });
    return {}
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}