import { OptionsObject, VariantType } from "notistack";
import i18n from "./i18n.utils";

const errorTexts: { [key: string]: string } = {
  "Company Inactive": "Votre compte est bloqué",
  PASSWORD_DONT_MATCH: i18n.t("login.incorrectLogs"),
};

class SnackError extends Error {
  private readonly text: string;
  private readonly options: OptionsObject;

  constructor(errorMessage: string, variant: VariantType, customMessage?: boolean, ...params: any[]) {
    super(...params);

    this.name = "SnackError";
    this.text = customMessage
      ? errorMessage
      : errorTexts[errorMessage] || "Une erreur est survenue. Veuilllez réessayer plus tard.";
    this.options = { variant };
  }
}

export default SnackError;
