import { TransactionLight } from "@store/transaction";
import dayjs from "dayjs";

interface GroupedObject {
  dateGroup: string;
  data: TransactionLight[];
}

export function groupObjectsByDate(objects: TransactionLight[]): GroupedObject[] {
  // Structures pour les données groupées et récentes
  const groupedData: Record<string, TransactionLight[]> = {};
  const recentData: Record<string, TransactionLight[]> = {};

  // Définition des bornes de temps pour "cette semaine" et "la semaine dernière"
  const weekStart = dayjs().startOf("week");
  const lastWeekStart = weekStart.subtract(1, "week");

  // Regroupement des transactions
  objects.forEach((obj) => {
    const currentDate = dayjs(obj.dateOfService ?? obj.date);
    let dateGroup: string;
    if (currentDate.isAfter(weekStart)) {
      dateGroup = "thisWeek";
    } else if (currentDate.isAfter(lastWeekStart)) {
      dateGroup = "lastWeek";
    } else {
      dateGroup = currentDate.format("MM/YY");
    }

    const targetData = dateGroup === "thisWeek" || dateGroup === "lastWeek" ? recentData : groupedData;

    if (!targetData[dateGroup]) {
      targetData[dateGroup] = [];
    }
    targetData[dateGroup].push(obj);
  });

  // Fonction pour trier les transactions à l'intérieur de chaque groupe par date
  const sortTransactions = (a: TransactionLight, b: TransactionLight) =>
    dayjs(a.dateOfService ?? a.date).diff(dayjs(b.dateOfService ?? b.date));

  // Transformation en array de GroupedObject avec tri des transactions
  const createSortedGroupObjects = (data: Record<string, TransactionLight[]>): GroupedObject[] =>
    Object.entries(data).map(([dateGroup, transactions]) => ({
      dateGroup,
      data: transactions.sort(sortTransactions).reverse(),
    }));

  // Création et tri des objets groupés
  const recentObjects = createSortedGroupObjects(recentData).sort((a, b) =>
    a.dateGroup === "thisWeek" ? -1 : b.dateGroup === "thisWeek" ? 1 : 0
  );
  const groupedObjects = createSortedGroupObjects(groupedData)
    .sort((a, b) =>
      dayjs(a.data[0].dateOfService ?? a.data[0].date).diff(dayjs(b.data[0].dateOfService ?? b.data[0].date))
    )
    .reverse();

  // Concaténation et retour de tous les objets groupés
  return [...recentObjects, ...groupedObjects];
}
