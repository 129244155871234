import { createStore, withProps } from '@ngneat/elf';
import { Session } from './session.model';
import { withRequestsStatus } from '@ngneat/elf-requests';
import { Buffer } from 'buffer';

export const sessionStore = createStore(
  { name: 'session' },
  withProps<Session>({
    accessToken: Buffer.from(localStorage.getItem('token') || '', 'base64').toString(),
  }),
  withRequestsStatus(),
);