import { createRequestDataSource } from '@ngneat/elf-requests';
import { selectAllEntities } from '@ngneat/elf-entities';
import { select } from '@ngneat/elf';

import { transactionStore } from './transaction.store';

export const getTransactionDataSource = createRequestDataSource({
  store: transactionStore,
  data$: () => transactionStore.pipe(selectAllEntities()),
  requestKey: 'getTransaction',
  dataKey: 'transactions'
});

export const GETTransactionByIdDataSource = createRequestDataSource({
  store: transactionStore,
  data$: () => transactionStore.pipe(select((state) => state.transaction)),
  requestKey: 'getTransaction',
  dataKey: 'transaction'
});

export const GETTransactionTotalDataSource = createRequestDataSource({
  store: transactionStore,
  data$: () => transactionStore.pipe(select((state) => state.totals)),
  requestKey: 'getTransactionTotal',
  dataKey: 'transactionTotal'
})
