import React, { useEffect, useState } from "react";
import { Stack, InputLabel, Paper, styled, TextField, Box } from "@mui/material";
import { Autocomplete } from "@mui/lab";

import { ArrowDropDown } from "@mui/icons-material";
import Arrow from '../../assets/icons/icon_arrow_down_grey.svg';

export interface SelectOption {
  label: string;
  value: any;
  data?: any;
}

const StyledTextField = styled(TextField)((props: any) => ({
  "& .MuiAutocomplete-endAdornment": {
    // display: props.disabled ? "none" : "block",
    display: 'none',
    top: '5px',
  },
  "& .MuiAutocomplete-popper": {
    display: 'flex',
    flexDirection: 'column'
  }
})) as typeof TextField;

interface AsyncSelectWithSearchComponentProps {
  disabled?: boolean;
  error?: boolean;
  getOptions: (search?: string) => Promise<SelectOption[]>;
  handleChange?: (value?: SelectOption) => void;
  label?: string;
  minimumSearchLength?: number;
  noResultComponent?: JSX.Element;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  searchIfEmpty?: boolean;
  value?: SelectOption | "";
  fullWidth?: boolean;
  ariaLabel?: string;
}

const AsyncSelectWithSearchComponent = (props: AsyncSelectWithSearchComponentProps) => {
  const {
    disabled,
    error,
    getOptions,
    handleChange,
    label,
    minimumSearchLength,
    noResultComponent,
    placeholder,
    readOnly,
    required,
    searchIfEmpty,
    value = "",
    ariaLabel
  } = props;

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let active = true;

    if ((!searchIfEmpty && inputValue === "") || (!!minimumSearchLength && inputValue.length < minimumSearchLength)) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (active && (!minimumSearchLength || inputValue.length >= minimumSearchLength)) {
      setLoading(true);
      (async () => {
        const results = await getOptions(inputValue);

        let newOptions: SelectOption[] = [];
        if (!!value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
        setLoading(false);
      })();
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, getOptions, minimumSearchLength, searchIfEmpty]);

  return (
    <Box display="flex" flexDirection="column" width={'100%'}>
      {!!label && <InputLabel error={error}>{label + (required ? "*" : "")}</InputLabel>}
      <Autocomplete
        getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value || null}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        loading={loading}
        disabled={disabled}
        open={minimumSearchLength && inputValue.length < minimumSearchLength ? false : open}
        onChange={(event: any, newValue: SelectOption | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          handleChange?.(newValue || undefined);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        PaperComponent={(params) => {
          return (
            <Paper {...params}>
              {!!(params.children as any)?.[0] && <Stack direction="column" {...(params.children as any)[0].props}>{(params.children as any)[0].props.children}</Stack>}
              {!noResultComponent && !!(params.children as any)?.[1] && (
                <Box p={1} {...(params.children as any)[1].props}>{(params.children as any)[1].props.children}</Box>
              )}
              <Box {...(params.children as any)[2]?.props} className="MuiAutocomplete-listbox" onMouseDown={(evt: any) => evt.preventDefault()}>
                {!!(params.children as any)?.[2] && (params.children as any)[2].props.children}
                {noResultComponent}
              </Box>
            </Paper>
          );
        }}
        noOptionsText={'Pas de résultats'}
        loadingText={'Chargement ...'}
        popupIcon={<img src={Arrow} alt='' />}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            fullWidth
            color="primary"
            focused={readOnly ? false : undefined}
            placeholder={placeholder}
            disabled={disabled}
            error={!!error && !disabled}
            aria-label={ariaLabel}
          />
        )}
      />
    </Box>
  );
};

export default AsyncSelectWithSearchComponent;
