import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";

import { usersStore } from "./users.store";

export const getUsersDataSource = createRequestDataSource({
  store: usersStore,
  data$: () => usersStore.pipe(selectAllEntities()),
  requestKey: 'getUsers',
  dataKey: 'users'
});