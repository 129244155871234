import { catchError, from, tap, Observable, map } from 'rxjs';
import { globalSettingsStore } from './globalSettings.store';
import APIAxios, { APIRoutes } from '../../api/axios.api';
import { GlobalSettings } from './globalSettings.models';
import { AxiosError, AxiosResponse } from 'axios';
import SnackError from '../../utils/errors.utils';
import { UploadFileResponse } from '@store/files';

export class GlobalSettingsServices {
	store = globalSettingsStore;

	getGlobalSettings = (): Observable<GlobalSettings> => {
		return from(APIAxios({ ...APIRoutes.GETGlobalSettings() })).pipe(
			catchError((err: AxiosError) => {
				throw new SnackError((err.response as any)?.data?.message, 'error');
			}),
			map((response: AxiosResponse<GlobalSettings>) => {
				return response.data;
			}),
			tap((globalSettings) => {
				this.store.update((state) => ({
					...state,
					globalSettings,
				}));
			})
		);
	};

	uploadCGU = (file: string): Observable<GlobalSettings> => {
		return from(APIAxios({ ...APIRoutes.PUTCGU(), data: { url: file } })).pipe(
			catchError((err: AxiosError) => {
				throw new SnackError((err.response as any)?.data?.message, 'error');
			}),
			map((response: AxiosResponse<GlobalSettings>) => {
				return response.data;
			}),
			tap((globalSettings) => {
				this.store.update((state) => ({
					...state,
					globalSettings,
				}));
			})
		);
	};

	uploadHelpDocument = (file: string): Observable<GlobalSettings> => {
		return from(APIAxios({ ...APIRoutes.PUTHelpDocument(), data: { url: file } })).pipe(
			catchError((err: AxiosError) => {
				throw new SnackError((err.response as any)?.data?.message, 'error');
			}),
			map((response: AxiosResponse<GlobalSettings>) => {
				return response.data;
			}),
			tap((globalSettings) => {
				this.store.update((state) => ({
					...state,
					globalSettings,
				}));
			})
		);
	};

	uploadLogo = (file: string): Observable<GlobalSettings> => {
		return from(APIAxios({ ...APIRoutes.PUTLogo(), data: { url: file } })).pipe(
			catchError((err: AxiosError) => {
				throw new SnackError((err.response as any)?.data?.message, 'error');
			}),
			map((response: AxiosResponse<GlobalSettings>) => {
				return response.data;
			}),
			tap((globalSettings) => {
				this.store.update((state) => ({
					...state,
					globalSettings,
				}));
			})
		);
	};

	uploafFile = (file: File): Observable<UploadFileResponse> => {
		const formData = new FormData();
		formData.append('attachment', file);

		return from(APIAxios({ ...APIRoutes.POSTFile(), data: formData })).pipe(
			catchError((err: AxiosError) => {
				throw new SnackError((err.response as any)?.data?.message, 'error');
			}),
			map((response: AxiosResponse<UploadFileResponse>) => {
				return response.data;
			})
		);
	};

	getFile = (key: string): Observable<UploadFileResponse> => {
		return from(APIAxios({ ...APIRoutes.GETFile(key) })).pipe(
			catchError((err: AxiosError) => {
				throw new SnackError((err.response as any)?.data?.message, 'error');
			}),
			map((response: AxiosResponse<UploadFileResponse>) => {
				return response.data;
			})
		);
	};
}

export const globalSettingsServices = new GlobalSettingsServices();