import React from 'react';
import { CardActionArea, Stack, Typography } from '@mui/material';
import { Colors } from '../../constants/colors.constants';
import { useResponsive } from '../../utils/useResponsive';

interface DashboardCardProps {
  icon?: JSX.Element;
  title?: string;
  subtitle?: string;
  disableClick?: boolean;
  onClick?: () => void;
}

const DashboardCard = (props: DashboardCardProps) => {
  const isMobile: boolean = useResponsive();
  const { icon, title, subtitle, onClick, disableClick } = props;

  return (
    <>
      <Stack 
        component={CardActionArea} 
        onClick={onClick}
        disabled={disableClick}
        bgcolor={Colors.white}
        justifyContent='flex-start'
        height={isMobile ? '230' : '200px'}
        alignItems='start'
        borderRadius='14px'
        p={isMobile ? '20px' : '40px'} 
        pt='28px'
        gap={3}
      >
        {icon}
        <Stack gap={1} sx={{overflow: isMobile ? "hidden" : null}}>
          <Typography fontSize={isMobile ? '16px' : '22px'} fontWeight={700} color={Colors.primaryText}>{title}</Typography>
          <Typography fontSize={isMobile ? '14px' : '16px'} fontWeight={400} color={Colors.secondaryText} >{subtitle}</Typography>
        </Stack>
      </Stack>
    </>
  )
}

export default DashboardCard;