import React, { useEffect, useState } from 'react';
import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Close from '../../assets/icons/icon_close_dark.svg';
import { useNavigate } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { globalSettingsQuery, globalSettingsServices } from '../../store/globalSettings';
import { sessionQuery } from '../../store/session';


interface CGUModaleProps {
  handleClose: () => void;
}

const CGUModale = (props: CGUModaleProps) => {

  const { handleClose } = props;

  const [{ globalSettings }] = useObservable(globalSettingsQuery.globalSettings$);
  const [{ company }] = useObservable(sessionQuery.company$);

  const [cguUrl, setCguUrl] = useState<string | undefined>();
  const [companyCguUrl, setCompanyCguUrl] = useState<string | undefined>()

  const { t } = useTranslation();

  useEffect(() => {
    globalSettingsServices
      .getFile(globalSettings?.cguUrl ?? '')
      .subscribe({
        next: (value) => {
          setCguUrl(value.url)
        }
      })
    company?.cguUrl &&
      globalSettingsServices
        .getFile(company.cguUrl)
        .subscribe({
          next: (value) => {
            setCompanyCguUrl(value.url)
          }
        })
  }, [globalSettings?.cguUrl, company?.cguUrl])

  return( 
    <Dialog open={true} maxWidth='xs' fullWidth>
      <Stack gap={5} p={4} alignItems='center'>
        <Stack 
          width='100%' 
          direction='row' 
          justifyContent='space-between' 
          alignItems='center'>
          <Typography fontSize='30px' fontWeight={800}>
            {t('cgu.title')}
          </Typography>
          <IconButton onClick={handleClose}>
            <img alt='' src={Close} />
          </IconButton>
        </Stack>
        <Stack gap={3}>
          <Button 
            style={{
              textTransform: 'none', 
              height: '52px'
            }} 
            color='primary' 
            variant='contained'
            onClick={() => {
              window.open(cguUrl,"_blank");
            }}>
              {t('cgu.impact')}
          </Button>
          {companyCguUrl && (
            <Button 
              style={{
                textTransform: 'none', 
                height: '52px'
              }} 
              color='primary' 
              variant='contained'
              onClick={() => {
                window.open(companyCguUrl,"_blank");
              }}>
                {t('cgu.company')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default CGUModale;