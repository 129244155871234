import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { BlacklistFilters, BlacklistedMerchant } from "./blacklist.models";

interface BlacklistStoreProps {
  filters: BlacklistFilters;
}

export const blacklistStore = createStore({ name: 'blacklist' },
  withProps<BlacklistStoreProps>({
    filters: { page: 1, orderBy: 'name'}
  }),
  withEntities<BlacklistedMerchant>(),
  withRequestsStatus()
)