import React, { FormEvent, useEffect, useState } from "react";
import { Button, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { Colors } from "../../../constants/colors.constants";
import { addMidValidation } from "./AddMIDException.yup";
import { midExceptionsServices, MIDException } from "../../../store/mid";
import { FieldErrors } from "../../../utils/yup.utils";
import { enqueueSnackbar } from "notistack";
import AsyncSelectWithSearchComponent, {
  SelectOption,
} from "../../../components/inputs/AsyncSelectWithSearchComponent.component";
import APIAxios, { APIRoutes } from "../../../api/axios.api";
import { Category } from "../../../store/serviceCategories";

import BasicTextfield from "../../../components/inputs/BasicTextfield.component";
import Close from "../../../assets/icons/icon_close_dark.svg";

interface AddMIDProps {
  handleClose: () => void;
  mid?: MIDException;
}

const AddMIDExceptionModal = (props: AddMIDProps) => {
  const { handleClose, mid } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<MIDException>>(mid || {});
  const [errors, setErrors] = useState<FieldErrors>({});
  const [selectedCategory, setSelectedCategory] = useState<SelectOption | undefined>(undefined);

  const getCategories = async (q?: string): Promise<SelectOption[]> => {
    return await APIAxios({
      ...APIRoutes.GETCategories({ page: 1, orderBy: undefined, orderByDirection: undefined, searchText: q }),
    }).then((res) => {
      if (res.data) {
        return res.data.serviceCategories.map((it: Category) => ({ label: it.name, value: it.id, data: it }));
      }
    });
  };

  const updateData = (name: string) => (value: any) => setData({ ...data, [name]: value });

  const handleValidate = (evt: FormEvent) => {
    evt.preventDefault();

    const errors = addMidValidation(data);
    setErrors(errors);

    if (!!Object.keys(errors).length) return;

    setLoading(true);

    if (mid) {
      midExceptionsServices
        .updateMidException(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: () => handleClose?.(),
        });
    } else {
      midExceptionsServices
        .addMidException(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => {
            enqueueSnackbar(err.text, err.options);
          },
          next: () => handleClose?.(),
        });
    }
  };

  return (
    <Dialog open={true} maxWidth="md" style={{ borderRadius: "8px" }}>
      <form onSubmit={handleValidate} noValidate>
        <Stack direction="column" gap={4} p={4}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="30px" fontWeight={800}>
              {!mid ? t("mid.add.title") : t("mid.update")}
            </Typography>
            <IconButton onClick={handleClose}>
              <img alt="" src={Close} />
            </IconButton>
          </Stack>
          <Grid container spacing={3} direction="row">
            <Grid item xs={6}>
              <BasicTextfield
                ariaLabel="white"
                onChange={updateData("name")}
                error={errors.name}
                value={data?.name}
                placeholder={t("mid.add.namePlaceholder") || ""}
              />
            </Grid>
            <Grid item xs={6}>
              <BasicTextfield
                ariaLabel="white"
                onChange={updateData("mid")}
                error={errors.mid}
                value={data?.mid}
                placeholder={t("mid.add.midPlaceholder") || ""}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <AsyncSelectWithSearchComponent
                ariaLabel="white"
                getOptions={(v) => getCategories(v)}
                placeholder={t("mid.add.category") || ""}
                value={
                  selectedCategory ||
                  (mid
                    ? { value: mid.serviceCategory.id, data: mid?.serviceCategory, label: mid?.serviceCategory.name }
                    : undefined)
                }
                handleChange={(value) => {
                  setSelectedCategory(value);
                  updateData("serviceCategory")(value?.data);
                }}
              />
            </Grid>
          </Grid>
          <Stack alignItems="center">
            <Button type="submit" color="secondary" disabled={loading} style={{ height: "52px" }}>
              {mid ? t("global.validate") : t("global.add")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default AddMIDExceptionModal;
