import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { UserFilter, User } from "./user.model";
import { PaginationDto } from "@utils/pagination.dto";

interface UserStoreProps {
  filters: UserFilter;
  pagination?: PaginationDto;
}

export const usersStore = createStore(
  { name: "users" },
  withProps<UserStoreProps>({
    filters: { page: 1, orderBy: "name" },
  }),
  withEntities<User>(),
  withRequestsStatus()
);
