import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";
import { CategoriesFilter } from "./categories.model";
import { categoriesServices } from "./categories.services";

export const searchCategoriesEffect = createEffectFn((params$: Observable<CategoriesFilter>) => {
  return params$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    switchMap((filters) => categoriesServices.getCategories(filters))
  );
});
