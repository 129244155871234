import { select } from '@ngneat/elf';

import { categoryChildrenStore } from './categoryChildren.store';
import { getCategoriesDataSource } from '@store/serviceCategories';
import { getCategoryChildrenDataSource } from './categoryChildren.requests';

export class CategoryChildrenQuery {
  store = categoryChildrenStore;

  categoryChildren$ = getCategoryChildrenDataSource.data$()

  filters$ = this.store.pipe(select((state) => state.filters));
}

export const categoryChildrenQuery = new CategoryChildrenQuery();