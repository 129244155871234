import { Category } from "../../store/serviceCategories";
import { Group } from "../groups";

export interface PaymentRule {
  id: string;
  company?: string;
  category?: Category;
  country?: CountryCode;
  group?: Group;
  perTransactionThreshold?: number;
  perDayThreshold?: number;
  perMonthThreshold?: number;
  perYearThreshold?: number;
  name?: string;
  daysAffected?: DayOfWeek[];
}

export interface GetPaymentControlRule {
  paymentControlRules: PaymentRule[];
  totalCount: number;
  currentPage: number;
  totalPageCount: number;
}

export interface PaymentControlRuleFilters {
  orderByDirection?: 'asc' | 'desc';
  groupId?
  : string;
  page: number;
  orderBy?: string;
}

export enum DayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export namespace DayOfWeek {
  export const label = (day: DayOfWeek): string => {
    switch (day) {
      case DayOfWeek.SUNDAY:
        return 'DIM';
      case DayOfWeek.MONDAY:
        return 'LUN';
      case DayOfWeek.TUESDAY:
        return 'MAR';
      case DayOfWeek.WEDNESDAY:
        return 'MER';
      case DayOfWeek.THURSDAY:
        return 'JEU';
      case DayOfWeek.FRIDAY:
        return 'VEN';
      case DayOfWeek.SATURDAY:
        return 'SAM';
    }
  }

  export const daysOfWeek: DayOfWeek[] = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY
  ]
}

export enum CountryCode {
  AFGHANISTAN = "AF",
  ALBANIA = "AL",
  ALGERIA = "DZ",
  ANDORRA = "AD",
  ANGOLA = "AO",
  ANTIGUA_AND_BARBUDA = "AG",
  ARGENTINA = "AR",
  ARMENIA = "AM",
  AUSTRALIA = "AU",
  AUSTRIA = "AT",
  AZERBAIJAN = "AZ",
  BAHAMAS = "BS",
  BAHRAIN = "BH",
  BANGLADESH = "BD",
  BARBADOS = "BB",
  BELARUS = "BY",
  BELGIUM = "BE",
  BELIZE = "BZ",
  BENIN = "BJ",
  BHUTAN = "BT",
  BOLIVIA = "BO",
  BOSNIA_AND_HERZEGOVINA = "BA",
  BOTSWANA = "BW",
  BRAZIL = "BR",
  BRUNEI = "BN",
  BULGARIA = "BG",
  BURKINA_FASO = "BF",
  BURUNDI = "BI",
  CABO_VERDE = "CV",
  CAMBODIA = "KH",
  CAMEROON = "CM",
  CANADA = "CA",
  CENTRAL_AFRICAN_REPUBLIC = "CF",
  CHAD = "TD",
  CHILE = "CL",
  CHINA = "CN",
  COLOMBIA = "CO",
  COMOROS = "KM",
  CONGO_BRAZZAVILLE = "CG",
  CONGO_KINSHASA = "CD",
  COSTA_RICA = "CR",
  COTE_DIVOIRE = "CI",
  CROATIA = "HR",
  CUBA = "CU",
  CYPRUS = "CY",
  CZECH_REPUBLIC = "CZ",
  DENMARK = "DK",
  DJIBOUTI = "DJ",
  DOMINICA = "DM",
  DOMINICAN_REPUBLIC = "DO",
  ECUADOR = "EC",
  EGYPT = "EG",
  EL_SALVADOR = "SV",
  EQUATORIAL_GUINEA = "GQ",
  ERITREA = "ER",
  ESTONIA = "EE",
  ESWATINI = "SZ",
  ETHIOPIA = "ET",
  FIJI = "FJ",
  FINLAND = "FI",
  FRANCE = "FR",
  GABON = "GA",
  GAMBIA = "GM",
  GEORGIA = "GE",
  GERMANY = "DE",
  GHANA = "GH",
  GREECE = "GR",
  GRENADA = "GD",
  GUATEMALA = "GT",
  GUINEA = "GN",
  GUINEA_BISSAU = "GW",
  GUYANA = "GY",
  HAITI = "HT",
  HOLY_SEE = "VA",
  HONDURAS = "HN",
  HUNGARY = "HU",
  ICELAND = "IS",
  INDIA = "IN",
  INDONESIA = "ID",
  IRAN = "IR",
  IRAQ = "IQ",
  IRELAND = "IE",
  ISRAEL = "IL",
  ITALY = "IT",
  JAMAICA = "JM",
  JAPAN = "JP",
  JORDAN = "JO",
  KAZAKHSTAN = "KZ",
  KENYA = "KE",
  KOREA_NORTH = "KP",
  KOREA_SOUTH = "KR",
  KOSOVO = "XK",
  KUWAIT = "KW",
  KYRGYZSTAN = "KG",
  LAOS = "LA",
  LATVIA = "LV",
  LEBANON = "LB",
  LESOTHO = "LS",
  LIBERIA = "LR",
  LIBYA = "LY",
  LIECHTENSTEIN = "LI",
  LITHUANIA = "LT",
  LUXEMBOURG = "LU",
  MADAGASCAR = "MG",
  MALAWI = "MW",
  MALAYSIA = "MY",
  MALDIVES = "MV",
  MALI = "ML",
  MALTA = "MT",
  MARSHALL_ISLANDS = "MH",
  MAURITANIA = "MR",
  MAURITIUS = "MU",
  MEXICO = "MX",
  MICRONESIA = "FM",
  MOLDOVA = "MD",
  MONACO = "MC",
  MONGOLIA = "MN",
  MONTENEGRO = "ME",
  MOROCCO = "MA",
  MOZAMBIQUE = "MZ",
  MYANMAR = "MM",
  NAMIBIA = "NA",
  NAURU = "NR",
  NEPAL = "NP",
  NETHERLANDS = "NL",
  NEW_ZEALAND = "NZ",
  NICARAGUA = "NI",
  NIGER = "NE",
  NIGERIA = "NG",
  NORTH_MACEDONIA = "MK",
  NORWAY = "NO",
  OMAN = "OM",
  PAKISTAN = "PK",
  PALAU = "PW",
  PANAMA = "PA",
  PAPUA_NEW_GUINEA = "PG",
  PARAGUAY = "PY",
  PERU = "PE",
  PHILIPPINES = "PH",
  POLAND = "PL",
  PORTUGAL = "PT",
  QATAR = "QA",
  ROMANIA = "RO",
  RUSSIA = "RU",
  RWANDA = "RW",
  SAINT_KITTS_AND_NEVIS = "KN",
  SAINT_LUCIA = "LC",
  SAINT_VINCENT_AND_THE_GRENADINES = "VC",
  SAMOA = "WS",
  SAN_MARINO = "SM",
  SAO_TOME_AND_PRINCIPE = "ST",
  SAUDI_ARABIA = "SA",
  SENEGAL = "SN",
  SERBIA = "RS",
  SEYCHELLES = "SC",
  SIERRA_LEONE = "SL",
  SINGAPORE = "SG",
  SLOVAKIA = "SK",
  SLOVENIA = "SI",
  SOLOMON_ISLANDS = "SB",
  SOMALIA = "SO",
  SOUTH_AFRICA = "ZA",
  SOUTH_SUDAN = "SS",
  SPAIN = "ES",
  SRI_LANKA = "LK",
  SUDAN = "SD",
  SURINAME = "SR",
  SWEDEN = "SE",
  SWITZERLAND = "CH",
  SYRIA = "SY",
  TAIWAN = "TW",
  TAJIKISTAN = "TJ",
  TANZANIA = "TZ",
  THAILAND = "TH",
  TIMOR_LESTE = "TL",
  TOGO = "TG",
  TONGA = "TO",
  TRINIDAD_AND_TOBAGO = "TT",
  TUNISIA = "TN",
  TURKEY = "TR",
  TURKMENISTAN = "TM",
  TUVALU = "TV",
  UGANDA = "UG",
  UKRAINE = "UA",
  UNITED_ARAB_EMIRATES = "AE",
  UNITED_KINGDOM = "GB",
  UNITED_STATES = "US",
  URUGUAY = "UY",
  UZBEKISTAN = "UZ",
  VANUATU = "VU",
  VENEZUELA = "VE",
  VIETNAM = "VN",
  YEMEN = "YE",
  ZAMBIA = "ZM",
  ZIMBABWE = "ZW"
}

export namespace CountryCode {
  export const countryCodes: CountryCode[] = Object.values(CountryCode) as CountryCode[];

  export const label = (code: CountryCode): string => {
    switch (code) {
      case CountryCode.AFGHANISTAN:
        return 'Afghanistan';
      case CountryCode.ALBANIA:
        return 'Albanie';
      case CountryCode.ALGERIA:
        return 'Algérie';
      case CountryCode.ANDORRA:
        return 'Andorre';
      case CountryCode.ANGOLA:
        return 'Angola';
      case CountryCode.ANTIGUA_AND_BARBUDA:
        return 'Antigua-et-Barbuda';
      case CountryCode.ARGENTINA:
        return 'Argentine';
      case CountryCode.ARMENIA:
        return 'Arménie';
      case CountryCode.AUSTRALIA:
        return 'Australie';
      case CountryCode.AUSTRIA:
        return 'Autriche';
      case CountryCode.BAHRAIN:
        return 'Bahreïn';
      case CountryCode.BANGLADESH:
        return 'Bangladesh';
      case CountryCode.BARBADOS:
        return 'Barbade';
      case CountryCode.BELARUS:
        return 'Biélorussie';
      case CountryCode.BELGIUM:
        return 'Belgique';
      case CountryCode.BELIZE:
        return 'Belize';
      case CountryCode.BENIN:
        return 'Bénin';
      case CountryCode.BHUTAN:
        return 'Bhoutan';
      case CountryCode.BOLIVIA:
        return 'Bolivie';
      case CountryCode.BOSNIA_AND_HERZEGOVINA:
        return 'Bosnie-Herzégovine';
      case CountryCode.BOTSWANA:
        return 'Botswana';
      case CountryCode.BRAZIL:
        return 'Brésil';
      case CountryCode.BRUNEI:
        return 'Brunéi Darussalam';
      case CountryCode.BULGARIA:
        return 'Bulgarie';
      case CountryCode.BURKINA_FASO:
        return 'Burkina Faso';
      case CountryCode.BURUNDI:
        return 'Burundi';
      case CountryCode.CABO_VERDE:
        return 'Cabo Verde';
      case CountryCode.CAMBODIA:
        return 'Cambodge';
      case CountryCode.CAMEROON:
        return 'Cameroun';
      case CountryCode.CANADA:
        return 'Canada';
      case CountryCode.CENTRAL_AFRICAN_REPUBLIC:
        return 'République centrafricaine';
      case CountryCode.CHAD:
        return 'Tchad';
      case CountryCode.CHILE:
        return 'Chili';
      case CountryCode.CHINA:
        return 'Chine';
      case CountryCode.COLOMBIA:
        return 'Colombie';
      case CountryCode.COMOROS:
        return 'Comores';
      case CountryCode.CONGO_BRAZZAVILLE:
        return 'Congo-Brazzaville';
      case CountryCode.CONGO_KINSHASA:
        return 'Congo-Kinshasa';
      case CountryCode.COSTA_RICA:
        return 'Costa Rica';
      case CountryCode.COTE_DIVOIRE:
        return "Côte d'Ivoire";
      case CountryCode.CROATIA:
        return 'Croatie';
      case CountryCode.CUBA:
        return 'Cuba';
      case CountryCode.CYPRUS:
        return 'Chypre';
      case CountryCode.CZECH_REPUBLIC:
        return 'République tchèque';
      case CountryCode.DENMARK:
        return 'Danemark';
      case CountryCode.DJIBOUTI:
        return 'Djibouti';
      case CountryCode.DOMINICA:
        return 'Dominique';
      case CountryCode.DOMINICAN_REPUBLIC:
        return 'République dominicaine';
      case CountryCode.ECUADOR:
        return 'Équateur';
      case CountryCode.EGYPT:
        return 'Égypte';
      case CountryCode.EL_SALVADOR:
        return 'Salvador';
      case CountryCode.EQUATORIAL_GUINEA:
        return 'Guinée équatoriale';
      case CountryCode.ERITREA:
        return 'Érythrée';
      case CountryCode.ESTONIA:
        return 'Estonie';
      case CountryCode.ESWATINI:
        return 'Eswatini';
      case CountryCode.ETHIOPIA:
        return 'Éthiopie';
      case CountryCode.FIJI:
        return 'Fidji';
      case CountryCode.FINLAND:
        return 'Finlande';
      case CountryCode.FRANCE:
        return 'France';
      case CountryCode.GABON:
        return 'Gabon';
      case CountryCode.GAMBIA:
        return 'Gambie';
      case CountryCode.GEORGIA:
        return 'Géorgie';
      case CountryCode.GERMANY:
        return 'Allemagne';
      case CountryCode.GHANA:
        return 'Ghana';
      case CountryCode.GREECE:
        return 'Grèce';
      case CountryCode.GRENADA:
        return 'Grenade';
      case CountryCode.GUATEMALA:
        return 'Guatemala';
      case CountryCode.GUINEA:
        return 'Guinée';
      case CountryCode.GUINEA_BISSAU:
        return 'Guinée-Bissau';
      case CountryCode.GUYANA:
        return 'Guyana';
      case CountryCode.HAITI:
        return 'Haïti';
      case CountryCode.HOLY_SEE:
        return 'Saint-Siège';
      case CountryCode.HONDURAS:
        return 'Honduras';
      case CountryCode.HUNGARY:
        return 'Hongrie';
      case CountryCode.ICELAND:
        return 'Islande';
      case CountryCode.INDIA:
        return 'Inde';
      case CountryCode.INDONESIA:
        return 'Indonésie';
      case CountryCode.IRAN:
        return 'Iran';
      case CountryCode.IRAQ:
        return 'Irak';
      case CountryCode.IRELAND:
        return 'Irlande';
      case CountryCode.ISRAEL:
        return 'Israël';
      case CountryCode.ITALY:
        return 'Italie';
      case CountryCode.JAMAICA:
        return 'Jamaïque';
      case CountryCode.JAPAN:
        return 'Japon';
      case CountryCode.JORDAN:
        return 'Jordanie';
      case CountryCode.KAZAKHSTAN:
        return 'Kazakhstan';
      case CountryCode.KENYA:
        return 'Kenya';
      case CountryCode.KOREA_NORTH:
        return 'Corée du Nord';
      case CountryCode.KOREA_SOUTH:
        return 'Corée du Sud';
      case CountryCode.KOSOVO:
        return 'Kosovo';
      case CountryCode.KUWAIT:
        return 'Koweït';
      case CountryCode.KYRGYZSTAN:
        return 'Kirghizistan';
      case CountryCode.LAOS:
        return 'Laos';
      case CountryCode.LATVIA:
        return 'Lettonie';
      case CountryCode.LEBANON:
        return 'Liban';
      case CountryCode.LESOTHO:
        return 'Lesotho';
      case CountryCode.LIBERIA:
        return 'Libéria';
      case CountryCode.LIBYA:
        return 'Libye';
      case CountryCode.LIECHTENSTEIN:
        return 'Liechtenstein';
      case CountryCode.LITHUANIA:
        return 'Lituanie';
      case CountryCode.LUXEMBOURG:
        return 'Luxembourg';
      case CountryCode.MADAGASCAR:
        return 'Madagascar';
      case CountryCode.MALAWI:
        return 'Malawi';
      case CountryCode.MALAYSIA:
        return 'Malaisie';
      case CountryCode.MALDIVES:
        return 'Maldives';
      case CountryCode.MALI:
        return 'Mali';
      case CountryCode.MALTA:
        return 'Malte';
      case CountryCode.MARSHALL_ISLANDS:
        return 'Îles Marshall';
      case CountryCode.MAURITANIA:
        return 'Mauritanie';
      case CountryCode.MAURITIUS:
        return 'Maurice';
      case CountryCode.MEXICO:
        return 'Mexique';
      case CountryCode.MICRONESIA:
        return 'Micronésie';
      case CountryCode.MOLDOVA:
        return 'Moldavie';
      case CountryCode.MONACO:
        return 'Monaco';
      case CountryCode.MONGOLIA:
        return 'Mongolie';
      case CountryCode.MONTENEGRO:
        return 'Monténégro';
      case CountryCode.MOROCCO:
        return 'Maroc';
      case CountryCode.MOZAMBIQUE:
        return 'Mozambique';
      case CountryCode.MYANMAR:
        return 'Myanmar';
      case CountryCode.NAMIBIA:
        return 'Namibie';
      case CountryCode.NAURU:
        return 'Nauru';
      case CountryCode.NEPAL:
        return 'Népal';
      case CountryCode.NETHERLANDS:
        return 'Pays-Bas';
      case CountryCode.NEW_ZEALAND:
        return 'Nouvelle-Zélande';
      case CountryCode.NICARAGUA:
        return 'Nicaragua';
      case CountryCode.NIGER:
        return 'Niger';
      case CountryCode.NIGERIA:
        return 'Nigéria';
      case CountryCode.NORTH_MACEDONIA:
        return 'Macédoine du Nord';
      case CountryCode.NORWAY:
        return 'Norvège';
      case CountryCode.OMAN:
        return 'Oman';
      case CountryCode.PAKISTAN:
        return 'Pakistan';
      case CountryCode.PALAU:
        return 'Palaos';
      case CountryCode.PANAMA:
        return 'Panama';
      case CountryCode.PAPUA_NEW_GUINEA:
        return 'Papouasie-Nouvelle-Guinée';
      case CountryCode.PARAGUAY:
        return 'Paraguay';
      case CountryCode.PERU:
        return 'Pérou';
      case CountryCode.PHILIPPINES:
        return 'Philippines';
      case CountryCode.POLAND:
        return 'Pologne';
      case CountryCode.PORTUGAL:
        return 'Portugal';
      case CountryCode.QATAR:
        return 'Qatar';
      case CountryCode.ROMANIA:
        return 'Roumanie';
      case CountryCode.RUSSIA:
        return 'Russie';
      case CountryCode.RWANDA:
        return 'Rwanda';
      case CountryCode.SAINT_KITTS_AND_NEVIS:
        return 'Saint-Kitts-et-Nevis';
      case CountryCode.SAINT_LUCIA:
        return 'Sainte-Lucie';
      case CountryCode.SAINT_VINCENT_AND_THE_GRENADINES:
        return 'Saint-Vincent-et-les-Grenadines';
      case CountryCode.SAMOA:
        return 'Samoa';
      case CountryCode.SAN_MARINO:
        return 'Saint-Marin';
      case CountryCode.SAO_TOME_AND_PRINCIPE:
        return 'Sao Tomé-et-Principe';
      case CountryCode.SAUDI_ARABIA:
        return 'Arabie saoudite';
      case CountryCode.SENEGAL:
        return 'Sénégal';
      case CountryCode.SERBIA:
        return 'Serbie';
      case CountryCode.SEYCHELLES:
        return 'Seychelles';
      case CountryCode.SIERRA_LEONE:
        return 'Sierra Leone';
      case CountryCode.SINGAPORE:
        return 'Singapour';
      case CountryCode.SLOVAKIA:
        return 'Slovaquie';
      case CountryCode.SLOVENIA:
        return 'Slovénie';
      case CountryCode.SOLOMON_ISLANDS:
        return 'Îles Salomon';
      case CountryCode.SOMALIA:
        return 'Somalie';
      case CountryCode.SOUTH_AFRICA:
        return 'Afrique du Sud';
      case CountryCode.SOUTH_SUDAN:
        return 'Soudan du Sud';
      case CountryCode.SPAIN:
        return 'Espagne';
      case CountryCode.SRI_LANKA:
        return 'Sri Lanka';
      case CountryCode.SUDAN:
        return 'Soudan';
      case CountryCode.SURINAME:
        return 'Suriname';
      case CountryCode.SWEDEN:
        return 'Suède';
      case CountryCode.SWITZERLAND:
        return 'Suisse';
      case CountryCode.SYRIA:
        return 'Syrie';
      case CountryCode.TAIWAN:
        return 'Taïwan';
      case CountryCode.TAJIKISTAN:
        return 'Tadjikistan';
      case CountryCode.TANZANIA:
        return 'Tanzanie';
      case CountryCode.THAILAND:
        return 'Thaïlande';
      case CountryCode.TIMOR_LESTE:
        return 'Timor oriental';
      case CountryCode.TOGO:
        return 'Togo';
      case CountryCode.TONGA:
        return 'Tonga';
      case CountryCode.TRINIDAD_AND_TOBAGO:
        return 'Trinité-et-Tobago';
      case CountryCode.TUNISIA:
        return 'Tunisie';
      case CountryCode.TURKEY:
        return 'Turquie';
      case CountryCode.TURKMENISTAN:
        return 'Turkménistan';
      case CountryCode.TUVALU:
        return 'Tuvalu';
      case CountryCode.UGANDA:
        return 'Ouganda';
      case CountryCode.UKRAINE:
        return 'Ukraine';
      case CountryCode.UNITED_ARAB_EMIRATES:
        return 'Émirats arabes unis';
      case CountryCode.UNITED_KINGDOM:
        return 'Royaume-Uni';
      case CountryCode.UNITED_STATES:
        return 'États-Unis';
      case CountryCode.URUGUAY:
        return 'Uruguay';
      case CountryCode.UZBEKISTAN:
        return 'Ouzbékistan';
      case CountryCode.VANUATU:
        return 'Vanuatu';
      case CountryCode.VENEZUELA:
        return 'Venezuela';
      case CountryCode.VIETNAM:
        return 'Viêt Nam';
      case CountryCode.YEMEN:
        return 'Yémen';
      case CountryCode.ZAMBIA:
        return 'Zambie';
      case CountryCode.ZIMBABWE:
        return 'Zimbabwe';
      default:
        return 'France';
    }
  }
}