import { Components } from '@mui/material';
import { Colors } from '../constants/colors.constants';

const card: Components = {
  MuiCard: {
    styleOverrides: {
      root: (props) => ({
        boxShadow: 'none',
        borderRadius: 10,
      })
    }
  },
  MuiCardActionArea: {
    styleOverrides: {
      root: (props) => ({
        ...(props).ownerState['aria-label'] === 'filter' ? {
          paddingTop: '16px',
          paddingBottom: '16px',
          paddingLeft: '24px',
          paddingRight: '24px',
          boxShadow: 'none',
        } : {},
        ...(props).ownerState['aria-label'] === 'selected' ? {
          border: `1px solid ${Colors.primary}`,
          paddingTop: '16px',
          paddingBottom: '16px',
          paddingLeft: '24px',
          paddingRight: '24px',
        } : {},
      })
    }
  }
}

export default card;