import { Dialog, IconButton, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { transactionServices } from "src/store/transaction";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Close from "src/assets/icons/icon_close_dark.svg";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

interface VerifyTransactionModalProps {
  open: boolean;
  handleClose: () => void;
}

const VerifyTransactionModal = (props: VerifyTransactionModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation(undefined, { keyPrefix: "superAdmin.verifyModal" });
  const [dateStart, setDateStart] = useState<Date>();
  const [dateEnd, setDateEnd] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);

  const handleGetTransactions = () => {
    setIsLoading(true);
    if (dateStart && dateEnd)
      transactionServices.getMissingTransactions(dateStart, dateEnd).subscribe({
        next: (data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "paiements-manquants.csv"); // or whatever file name you want
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          enqueueSnackbar(t("success"));
          setIsLoading(false);
        },
        error: (err) => {
          setIsLoading(false);
          enqueueSnackbar(t("error"), { variant: "error" });
        },
      });
  };

  return (
    <>
      <Dialog open={open} fullWidth>
        <Stack p={"20px"} direction="row" justifyContent="space-between">
          <Typography fontSize="30px" fontWeight={800}>
            {t("title")}
          </Typography>
          <IconButton onClick={handleClose}>
            <img alt="" src={Close} />
          </IconButton>
        </Stack>
        <Stack p="40px 20px" spacing={5}>
          <DatePicker
            format="dd-MM-yyyy"
            sx={{ border: "1px solid" }}
            label={t("dateStart")}
            value={dateStart}
            onChange={(newValue) => {
              if (newValue) setDateStart(newValue);
            }}
          />
          <DatePicker
            format="dd-MM-yyyy"
            sx={{ border: "1px solid" }}
            label={t("dateEnd")}
            value={dateEnd}
            onChange={(newValue) => {
              if (newValue) setDateEnd(newValue);
            }}
          />
          <LoadingButton
            loading={isLoading}
            disabled={!dateStart || !dateEnd}
            onClick={handleGetTransactions}
            variant="contained"
          >
            {t("verify")}
          </LoadingButton>
        </Stack>
      </Dialog>
    </>
  );
};

export default VerifyTransactionModal;
