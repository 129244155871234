import { Search, Close } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { createRef } from 'react';

interface BasicSearchbarProps {
  placeholder?: string | null;
  value?: string;
  onChange?: (value?: string) => void;
  fullWidth?: boolean;
  width?: string;
  minWidth?: string;
}

const BasicSearchbar = (props: BasicSearchbarProps) => {
  const {placeholder, value, onChange, fullWidth, width, minWidth } = props;

  const inputRef = createRef<HTMLInputElement>();

  return (
    <TextField
      value={value}
      placeholder={placeholder ?? ''}
      size='small'
      aria-label='search'
      style={{ width: width ?? '100%', height: '52px', minWidth: minWidth ?? 'auto'}}
      fullWidth={fullWidth ?? true}
      inputRef={inputRef}
      InputProps={{
        startAdornment: <Search />,
        endAdornment:
        <InputAdornment position="end">
            {value && (
              <IconButton
                onClick={() => {
                  if (inputRef.current) inputRef.current.value = "";
                  onChange?.("");
                }}>
                <Close />
              </IconButton>
            )}
          </InputAdornment>
      }}
      onChange={(evt) => onChange?.(evt.target.value)}
     />
  )
}

export default BasicSearchbar;
