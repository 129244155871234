import { PaletteOptions } from "@mui/material";
import { Colors } from '../constants/colors.constants';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

const palette: PaletteOptions = {
  primary: {
    main: Colors.primary
  },
  neutral: {
    main: Colors.white
  },
}

export default palette;