import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "@mui/icons-material";
import Header from "../../../../components/header/Header.component";
import SwitchCard from "../../../../components/card/SwitchCard.component";
import { UserRole } from "../../../../store/users";
import { Colors } from "../../../../constants/colors.constants";
import BasicTextfield from "../../../../components/inputs/BasicTextfield.component";
import { useObservable } from "@ngneat/react-rxjs";
import { Company, sessionQuery, sessionService } from "../../../../store/session";
import { enqueueSnackbar } from "notistack";

const InformationsToComplete = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [{ company }] = useObservable(sessionQuery.company$);
  const [data, setData] = useState<Partial<Company | undefined>>(company);

  const updateData = (name: string) => (value: any) => {
    setData({ ...data, [name]: value });
    updateCompany({ ...data, [name]: value });
  };

  const updateCompany = (data: Partial<Company>) => {
    if (data.id) {
      sessionService.updateCompany(data).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  };

  return (
    <Stack height="100%" pt={3} gap={3}>
      <Header selectedTab="params" role={UserRole.ADMIN_COMPANY} />
      <Stack alignItems="start">
        <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
          {t("global.back")}
        </Button>
      </Stack>
      <Typography fontSize="30px" fontWeight={800} color={Colors.primaryText}>
        {t("informations.title")}
      </Typography>
      <Stack height="100%" gap={2}>
        <SwitchCard
          title={t("informations.justificative")}
          subtitle={t("informations.askUsers")}
          isOn={company?.isInvoiceRequired ?? false}
          onChange={() => updateData("isInvoiceRequired")(!data?.isInvoiceRequired ?? true)}
        />
        <SwitchCard
          title={t("informations.motive")}
          subtitle={t("informations.motiveSubtitle")}
          isOn={company?.isReasonRequired ?? false}
          onChange={() => updateData("isReasonRequired")(!data?.isReasonRequired ?? true)}
        />
        <SwitchCard
          title={t("informations.maxNbr")}
          subtitle={t("informations.maxNbrSubtitle")}
          onChange={() => {}}
          noSwitch
          textfield={
            <BasicTextfield
              placeholder={t("informations.maxNbrPlaceholder") || ""}
              color="primary"
              value={data?.maxAmountOfPayments?.toString()}
              ariaLabel="white"
              onChange={(value) => updateData("maxAmountOfPayments")(value)}
            />
          }
        />
      </Stack>
    </Stack>
  );
};

export default InformationsToComplete;
