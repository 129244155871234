import React, { useCallback, useEffect, useState } from 'react';
import { Stack, styled, Button, Typography, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { ChevronLeft, ControlPoint } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../../../store/users';
import { Colors } from '../../../../constants/colors.constants';
import { useObservable } from '@ngneat/react-rxjs';
import { BlacklistedMerchant, blacklistQuery, blacklistServices, searchBlacklistEffect } from '../../../../store/blacklist';

import Header from '../../../../components/header/Header.component';
import BasicSearchbar from '../../../../components/inputs/BasicSearchBar.component';
import Edit from '../../../../assets/icons/icon_edit_dark.svg';
import Delete from '../../../../assets/icons/icon_delete_color.svg';
import AddBlacklist from './AddBlacklist.modal';
import { enqueueSnackbar } from 'notistack';
import ConfirmDialog from '../../../../components/Confirm.component';
import { useEffectFn } from '@ngneat/effects-hooks';

const CustomDataGrid = styled(DataGrid)({
  border: 'none !important',
  width: '100%',
  flexDirection: 'column-reverse',
  '& .MuiDataGrid-row': {
    backgroundColor: Colors.white,
    borderRadius: '8px',
    marginTop: '4px',
    marginBottom: '4px',
    border: 0,
    maxHeight: '54px !important',
    minHeight: '54px !important',
    fontSize: '18px',
    fontWeight: '600'
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 0
  },
  '& .MuiDataGrid-columnHeaders:focus': {
    outline: 'none'
  },
  '& .MuiDataGrid-cell': {
    border: 0,
    fontSize: '18px',
    fontWeight: 600,
    color: Colors.primaryText
  },
  '& .MuiDataGrid-withBorderColor': {
    borderColor: 'transparent'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGridCell--withRenderer': {
    outline: 'none !important',
  },
  '& .MuiTablePagination-root': {
    position: 'absolute',
    top: '52px',
    right: 0
  }
});

const HeaderTypography = styled(Typography)({
  fontSize: '12px',
  color: Colors.secondaryText,
  fontWeight: '600',
  textTransform: 'uppercase'
});

const Blacklist = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [filters] = useObservable(blacklistQuery.filters$)
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any | undefined>();
  const [blacklistToDelete, setBlacklistToDelete] = useState<BlacklistedMerchant | undefined>();
  const [deleteBlacklistOpen, setDeleteBlacklistOpen] = useState(false);

  const searchBlacklist = useEffectFn(searchBlacklistEffect);

  const [{ blacklist, error, loading }] = useObservable(blacklistQuery.blacklist$)

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    blacklistServices.setFilters({
      page: 1,
      orderByDirection: sortModel[0]?.sort || undefined,
      orderBy: sortModel[0]?.field || undefined,
    })
  }, []);

  const handleDeleteBlacklistedMerchant = () => {
    blacklistServices.deleteBlacklistedMerchant(blacklistToDelete?.id ?? '')
      .subscribe({
        next: () => {
          enqueueSnackbar(t('blacklist.delete.success'), { variant: 'success' });
          setDeleteBlacklistOpen(false)
        },
        error: (err) => enqueueSnackbar(err.text, err.options)
      })
    setBlacklistToDelete(undefined)
  }

  useEffect(() => {
    searchBlacklist(filters);
  }, [filters, searchBlacklist]);

  useEffect(() => {
    selectedItem &&
      setModalOpen(true);
  }, [selectedItem]);

  useEffect(() => {
    blacklistToDelete &&
      setDeleteBlacklistOpen(true)
  }, [blacklistToDelete])

  const columns: GridColDef[] = [
    {
      field: 'name',
      renderHeader: () => <HeaderTypography>{t('blacklist.name')}</HeaderTypography>,
      minWidth: 230,
      flex: 1,
      renderCell(params) {
        return (
          <Typography fontSize='18px' fontWeight='600'>{params.row.merchantName}</Typography>
        );
      }
    },
    {
      field: 'mid',
      renderHeader: () => <HeaderTypography>{t('blacklist.id')}</HeaderTypography>,
      minWidth: 150,
      flex: 2,
      renderCell(params) {
        return (
          <Typography fontSize='18px' fontWeight='600'>{params.row.mid}</Typography>
        );
      }
    },
    {
      field: 'edit',
      headerName: '',
      width: 45,
      renderCell(params) {
        return (
          <IconButton onClick={() => {
            setSelectedItem(params.row);
          }}>
            <img alt='' src={Edit} />
          </IconButton>
        );
      }
    },
    {
      field: 'delete',
      headerName: '',
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => setBlacklistToDelete(params.row)}>
            <img src={Delete} alt='' />
          </IconButton>
        );
      }
    }
  ];

  return (
    <>
      {modalOpen && (
        <AddBlacklist handleClose={() => {
          setModalOpen(false);
          setSelectedItem(undefined);
        }}
          blacklistedService={selectedItem} />
      )}
      {deleteBlacklistOpen && (
        <ConfirmDialog
          title={t('blacklist.delete.title')}
          confirm={t('global.validate')}
          cancel={t('global.cancel')}
          handleClose={() => {
            setBlacklistToDelete(undefined);
            setDeleteBlacklistOpen(false);
          }}
          handleValidate={() => handleDeleteBlacklistedMerchant()}
        />
      )}
      <Stack pt={3} gap={3} height='100%'>
        <Header role={UserRole.ADMIN_COMPANY} selectedTab='params' />
        <Stack alignItems='start' gap={2}>
          <Button variant='text' startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label='back'>{t('global.back')}</Button>
          <Stack direction='row' justifyContent='space-between' width='100%'>
            <Typography fontSize='30px' fontWeight={800} color={Colors.primaryText}>{t('blacklist.title')}</Typography>
            <Stack direction='row' gap={2}>
              <BasicSearchbar
                placeholder={t('global.search') || ''}
                onChange={(value) => { blacklistServices.setFilters({ searchText: value }) }} />
              <Button
                style={{ height: '52px' }}
                variant='contained'
                color='secondary'
                onClick={() => setModalOpen(true)}
                startIcon={<ControlPoint />}>
                {t('global.add')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <CustomDataGrid
          rows={blacklist}
          pagination
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          disableDensitySelector
          disableColumnFilter
          rowSelection={false}
          filterMode='server'
          disableVirtualization
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          columns={columns}
          pageSizeOptions={[]}
          slotProps={{
            pagination: {
              labelRowsPerPage: 'test'
            }
          }}
        />
      </Stack>
    </>
  )
}

export default Blacklist;