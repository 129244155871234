import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";

import { paymentRulesStore } from "./paymentRules.store";

export const getPaymentRulesDataSource = createRequestDataSource({
  store: paymentRulesStore,
  data$: () => paymentRulesStore.pipe(selectAllEntities()),
  requestKey: 'getPaymentRules',
  dataKey: 'paymentRules'
})