import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Button, FormControlLabel, Grid, IconButton, Stack, Switch, Typography, styled } from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { ChevronLeft, ControlPoint } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../constants/colors.constants";
import { UserRole } from "../../../../store/users";
import { useNavigate } from "react-router-dom";

import Header from "../../../../components/header/Header.component";
import Edit from "../../../../assets/icons/icon_edit_dark.svg";
import Delete from "../../../../assets/icons/icon_delete_color.svg";
import { ArrowForward } from "@mui/icons-material";
import AddPaymentControlRule from "./AddPaymentControl.modal";
import {
  CountryCode,
  PaymentRule,
  paymentRulesQuery,
  paymentRulesServices,
  searchPaymentControlRuleEffect,
} from "../../../../store/paymentRules";
import { useObservable } from "@ngneat/react-rxjs";
import { Group, groupsQuery, groupsServices } from "../../../../store/groups";
import { useEffectFn } from "@ngneat/effects-hooks";
import ConfirmDialog from "../../../../components/Confirm.component";
import { enqueueSnackbar } from "notistack";
import { sessionQuery, sessionService } from "src/store/session";

const CustomDataGrid = styled(DataGrid)({
  border: "none !important",
  flexDirection: "column-reverse",
  height: "100%",
  "& .MuiDataGrid-row": {
    backgroundColor: Colors.white,
    borderRadius: "8px",
    marginTop: "4px",
    marginBottom: "4px",
    border: 0,
    maxHeight: "54px !important",
    minHeight: "54px !important",
    fontSize: "18px",
    fontWeight: "600",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: 0,
  },
  "& .MuiDataGrid-columnHeaders:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    border: 0,
    fontSize: "18px",
    fontWeight: 600,
    color: Colors.primaryText,
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: "transparent",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGridCell--withRenderer":
    {
      outline: "none !important",
    },
  "& .MuiTablePagination-root": {
    position: "absolute",
    top: "52px",
    right: 0,
  },
});

const HeaderTypography = styled(Typography)({
  fontSize: "12px",
  color: Colors.secondaryText,
  fontWeight: "600",
  textTransform: "uppercase",
});

const PaymentControl = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<PaymentRule | undefined>();
  const [itemToDelete, setItemToDelete] = useState<PaymentRule | undefined>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>();

  const [filters] = useObservable(paymentRulesQuery.filters$);
  const [{ groups }] = useObservable(groupsQuery.groups$);
  const [{ paymentRules }] = useObservable(paymentRulesQuery.paymentRules$);
  const [{ company }] = useObservable(sessionQuery.company$);

  const searchPaymentControlRules = useEffectFn(searchPaymentControlRuleEffect);

  useEffect(() => {
    searchPaymentControlRules(filters);
  }, [filters, searchPaymentControlRules]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    paymentRulesServices.setFilters({
      page: 1,
      orderByDirection: sortModel[0]?.sort || undefined,
      orderBy: sortModel[0]?.field || undefined,
    });
  }, []);

  const handleDeleteRule = () => {
    paymentRulesServices.deletePaymentRule(itemToDelete?.id ?? "").subscribe({
      next: () => {
        enqueueSnackbar(t("paymentControl.delete.success"), { variant: "success" });
        setDeleteModalOpen(false);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
    setItemToDelete(undefined);
  };

  const handleChangeAuthorized = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.checked;

    const updateState = () => {
      if (selectedGroup) {
        setSelectedGroup((prevGroup) => {
          if (prevGroup) {
            return { ...prevGroup, authorizesSelectedCategoriesAndCountriesOnly: updatedValue };
          }
          return prevGroup;
        });
      } else {
        sessionService.getCompany().subscribe();
      }
    };

    if (selectedGroup) {
      groupsServices
        .updateGroup({ ...selectedGroup, authorizesSelectedCategoriesAndCountriesOnly: updatedValue })
        .subscribe({
          next: () => {
            updateState();
            groupsServices.getGroups().subscribe();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      sessionService
        .updateCompany({ ...company, authorizesSelectedCategoriesAndCountriesOnly: updatedValue })
        .subscribe({
          next: () => {
            updateState();
            sessionService.getCompany().subscribe();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  };

  useEffect(() => {
    groupsServices.getGroups().subscribe();
  }, []);

  useEffect(() => {
    selectedItem && setModalOpen(true);
  }, [selectedItem]);

  const columns: GridColDef[] = [
    {
      field: "categoryName",
      renderHeader: () => <HeaderTypography>{t("Restriction par catégorie")}</HeaderTypography>,
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        const value = params.row.category?.name;
        return (
          <Typography fontSize="18px" fontWeight="600">
            {value === "" || !value ? "-" : value}
          </Typography>
        );
      },
    },
    {
      field: "country",
      renderHeader: () => <HeaderTypography>{t("pays")}</HeaderTypography>,
      minWidth: 150,
      flex: 1,
      renderCell(params) {
        const value = params.row.country;
        return (
          <Typography fontSize="18px" fontWeight="600">
            {!value || value === "" ? "-" : CountryCode.label(params.row.country)}
          </Typography>
        );
      },
    },
    {
      field: "perTransactionThreshold",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("plafond par paiement")}</HeaderTypography>,
      minWidth: 100,
      flex: 2,
      renderCell(params) {
        const value = params.row.perTransactionThreshold;
        return (
          <Typography fontSize="18px" fontWeight="600">
            {!value || value === -1 ? "-" : value}
          </Typography>
        );
      },
    },
    {
      field: "perDayThreshold",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("plafond par jour")}</HeaderTypography>,
      minWidth: 60,
      flex: 2,
      renderCell(params) {
        const value = params.row.perDayThreshold;
        return (
          <Typography fontSize="18px" fontWeight="600">
            {!value || value === -1 ? "-" : value}
          </Typography>
        );
      },
    },
    {
      field: "perMonthThreshold",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("plafond par mois")}</HeaderTypography>,
      minWidth: 60,
      flex: 2,
      renderCell(params) {
        const value = params.row.perMonthThreshold;
        return (
          <Typography fontSize="18px" fontWeight="600">
            {!value || value === -1 ? "-" : value}
          </Typography>
        );
      },
    },
    {
      field: "perYearThreshold",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("plafond par année")}</HeaderTypography>,
      minWidth: 60,
      flex: 2,
      renderCell(params) {
        const value = params.row.perYearThreshold;
        return (
          <Typography fontSize="18px" fontWeight="600">
            {!value || value === -1 ? "-" : value}
          </Typography>
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setSelectedItem(params.row);
            }}
          >
            <img alt="" src={Edit} />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setItemToDelete(params.row);
              setDeleteModalOpen(true);
            }}
          >
            <img src={Delete} alt="" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      {modalOpen && (
        <AddPaymentControlRule
          rule={selectedItem}
          groupId={selectedGroup?.id}
          handleClose={() => {
            setModalOpen(false);
            setSelectedItem(undefined);
          }}
        />
      )}
      {deleteModalOpen && itemToDelete && (
        <ConfirmDialog
          title={t("paymentControl.delete")}
          confirm={t("global.validate")}
          cancel={t("global.cancel")}
          handleClose={() => {
            setItemToDelete(undefined);
            setDeleteModalOpen(false);
          }}
          handleValidate={() => {
            handleDeleteRule();
          }}
        />
      )}
      <Stack pt={3} gap={3} height="100%" width="100%" pb={3}>
        <Header role={UserRole.ADMIN_COMPANY} selectedTab="params" />
        <Stack alignItems="start" gap={3} width="100%" height="100%">
          <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
            {t("global.back")}
          </Button>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Typography fontSize="30px" fontWeight={800} color={Colors.primaryText}>
              {t("paymentControl.title")}
            </Typography>
          </Stack>
          <Grid container wrap="wrap" direction="row" spacing={2} width="100%" height="100%" alignItems="top">
            <Grid xs md={2} item sx={{ backgroundColor: Colors.white, borderRadius: 4 }} p={4}>
              <Stack justifyContent="space-between" height="100%">
                <Stack gap={2}>
                  <Typography fontSize={22} fontWeight={700}>
                    {t("paymentControl.rules")}
                  </Typography>
                  <Stack gap={0.5}>
                    <>
                      <Button
                        color={selectedGroup?.id === undefined ? "primary" : "secondary"}
                        onClick={() => {
                          setSelectedGroup(undefined);
                          paymentRulesServices.setFilters({ groupId: undefined });
                        }}
                        variant="contained"
                        endIcon={<ArrowForward />}
                      >
                        {"Utilisateurs sans groupe"}
                      </Button>
                      {groups.map((it: Group) => (
                        <Button
                          color={selectedGroup?.id === it.id ? "primary" : "secondary"}
                          variant="contained"
                          endIcon={<ArrowForward />}
                          onClick={() => {
                            setSelectedGroup(it);
                            paymentRulesServices.setFilters({ groupId: it?.id });
                          }}
                        >
                          {it.name}
                        </Button>
                      ))}
                    </>
                  </Stack>
                </Stack>
                <Button color="secondary" onClick={() => setModalOpen(true)} startIcon={<ControlPoint />}>
                  {t("global.add")}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={10} width="100%" height="100%" justifyContent="top">
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      selectedGroup?.authorizesSelectedCategoriesAndCountriesOnly ??
                      company?.authorizesSelectedCategoriesAndCountriesOnly
                    }
                    onChange={handleChangeAuthorized}
                  />
                }
                label={t("paymentControl.authorizesSelectedCategoriesAndCountriesOnly")}
              />

              <CustomDataGrid
                rows={paymentRules}
                pagination
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
                disableDensitySelector
                disableColumnFilter
                rowSelection={false}
                filterMode="server"
                disableVirtualization
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                columns={columns}
                pageSizeOptions={[]}
                slotProps={{
                  pagination: {
                    labelRowsPerPage: "test",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default PaymentControl;
