import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";

import { midStore } from "./mid.store";

export const getMidDataSource = createRequestDataSource({
  store: midStore,
  data$: () => midStore.pipe(selectAllEntities()),
  requestKey: 'getMid',
  dataKey: 'mid'
});