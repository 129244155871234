import { Components, alpha } from "@mui/material";
import { Colors } from "../constants/colors.constants";

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsColorOverrides {
    neutral: true;
  }
}

const button: Components = {
  MuiButton: {
    styleOverrides: {
      root: (props) => ({
        borderRadius: 10,
        height: 60,
        padding: "0px 16px",
        color: Colors.white,
        fontWeight: 700,
        boxShadow: `none`,
        ":hover": {
          boxShadow: `none`,
        },
        "&:disabled": {
          backgroundColor: Colors.lightGrey + " !important",
          color: Colors.white + " !important",
        },
        ...(props.ownerState["aria-label"] === "back"
          ? {
              fontSize: 12,
              fontWeight: 600,
              padding: 0,
              height: "unset",
              width: "unset",
              color: Colors.secondaryText,
              textTransform: "capitalize",
            }
          : {}),
        ...(props.ownerState["aria-label"] === "underline"
          ? {
              backgroundColor: "transparent",
              textDecoration: "underline",
              textTransform: "none",
              fontWeight: 800,
              textAlign: "center",
              height: "unset",
              width: "unset",
              color: `${Colors.primary}`,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }
          : {}),
        ...(props.ownerState["aria-label"] === "select-file-textfield"
          ? {
              backgroundColor: Colors.background,
              height: 54,
              color: `${alpha(Colors.secondaryText, 0.3)}`,
              fontSize: 16,
              fontWeight: 500,
              textAlign: "left",
              display: "flex",
              textTransform: "none",
              justifyContent: "space-between",
              position: "relative",
              small: {
                fontSize: 12,
                fontWeight: 400,
                position: "absolute",
                right: 40,
              },
            }
          : {}),
        ...(props.ownerState["aria-label"] === "weekday-selected"
          ? {
              fontSize: 16,
              fontWeight: 600,
              padding: 0,
              height: "38px",
              width: "38px",
              color: Colors.white,
              textTransform: "capitalize",
              backgroundColor: Colors.primary,
            }
          : {}),
        ...(props.ownerState["aria-label"] === "weekday"
          ? {
              fontSize: 16,
              fontWeight: 600,
              padding: 0,
              height: "38px",
              width: "38px",
              color: Colors.secondaryText,
              textTransform: "capitalize",
              backgroundColor: alpha(Colors.primary, 0.3),
            }
          : {}),
      }),
    },
    variants: [
      {
        props: { color: "secondary" },
        style: {
          backgroundColor: Colors.primaryText,
          ":hover": {
            backgroundColor: Colors.primaryText,
          },
        },
      },
      {
        props: { color: "error" },
        style: {
          backgroundColor: Colors.red,
          ":hover": {
            backgroundColor: Colors.red,
          },
        },
      },
      {
        props: { color: "success" },
        style: {
          backgroundColor: Colors.green,
          ":hover": {
            backgroundColor: Colors.green,
          },
        },
      },
      {
        props: { color: "info" },
        style: {
          backgroundColor: Colors.white,
          color: Colors.primaryText,
          ":hover": {
            backgroundColor: Colors.white,
          },
        },
      },
    ],
  },
};

export default button;
