import React, { FormEvent, useState } from 'react';
import { Button, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs';
import { FieldErrors } from '../../../../utils/yup.utils';
import { enqueueSnackbar } from 'notistack';

import BasicTextfield from '../../../../components/inputs/BasicTextfield.component';
import Close from '../../../../assets/icons/icon_close_dark.svg';
import { BlacklistedMerchant, blacklistServices } from '../../../../store/blacklist';

interface AddBlacklistProps {
  handleClose: () => void;
  blacklistedService?: any;
}

const AddBlacklist = (props: AddBlacklistProps) => {

  const { handleClose, blacklistedService } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<BlacklistedMerchant>>(blacklistedService || {});
  const [errors, setErrors] = useState<FieldErrors>({});
  
  const { t } = useTranslation();

  const updateData = (name: string) => (value: any) => setData({ ...data, [name]: value });

  const handleValidate = (evt: FormEvent) => {
    evt.preventDefault();

    if (!data) return;

    setLoading(true);

    if (blacklistedService) {
      blacklistServices
        .updateBlacklistedMerchant(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: () => handleClose?.()
        })
    } else {
      blacklistServices
        .addBlacklistedMMerchant(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: () => handleClose?.()
        })
    }
  }

  return (
    <Dialog open={true} fullWidth maxWidth={'md'}>
      <form>
        <Stack direction='column' gap={4} p={4}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography fontSize='30px' fontWeight={800}>{t('blacklist.add.title')}</Typography>
            <IconButton onClick={handleClose}><img alt='' src={Close} /></IconButton>
          </Stack>
          <Stack gap={3} width='100%'>
            <Typography fontSize='20px' fontWeight={700}>{t('blacklist.add.subtitle')}</Typography>
            <Grid gap={3} container direction='row' width='100%'>
              <Grid item xs>
                <BasicTextfield
                  ariaLabel='white'
                  color='secondary'
                  onChange={updateData('merchantName')}
                  error={errors.name}
                  fullWidth
                  value={data?.merchantName}
                  placeholder={t('blacklist.add.namePlaceholder') || ''}
                  />
              </Grid>
               <Grid item xs>
                <BasicTextfield
                  ariaLabel='white'
                  fullWidth
                  color='secondary'
                  onChange={updateData('mid')}
                  error={errors.id}
                  value={data?.mid}
                  placeholder={t('blacklist.add.idPlaceholder') || ''}
                />
               </Grid>
            </Grid>
          </Stack>
          <Stack alignItems='center' pt={4}>
            <Button style={{height: '52px'}} color='secondary' onClick={handleValidate} type='submit'>{t('global.add')}</Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}

export default AddBlacklist;