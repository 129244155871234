import { select } from "@ngneat/elf";

import { categoriesStore } from "./categories.store";
import { getCategoriesDataSource } from "./categories.requests";

export class CategoriesQuery {
  store = categoriesStore;

  categories$ = getCategoriesDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));
  pagination$ = this.store.pipe(select((state) => state.pagination));
}

export const categoriesQuery = new CategoriesQuery();
