import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { Payment } from "./payments.models";

interface PaymentStoreProps {

}

export const paymentStore = createStore(
  { name: 'payments' },
  withProps<PaymentStoreProps>({

  }),
  withEntities<Payment>(),
  withRequestsStatus()
)