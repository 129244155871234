import { Grid, Stack, Typography } from "@mui/material";
import { Colors } from "../../../../constants/colors.constants";
import { useNavigate } from "react-router-dom";

import Dashboard from "../../../../assets/icons/icon_category_color.svg";
import Contract from "../../../../assets/icons/icon_contract_color.svg";
import Exclamation from "../../../../assets/icons/icon_exclamationmark_exception_color.svg";
import TVA from "../../../../assets/icons/icon_percent_tva_color.svg";
import Blacklist from "../../../../assets/icons/icon_prohibition_color.svg";
import Group from "../../../../assets/icons/icon_group_color.svg";
import Settings from "../../../../assets/icons/icon_wrench_color.svg";

import DashboardCard from "../../../../components/card/DashboardCard.component";
import Header from "../../../../components/header/Header.component";
import { UserRole } from "../../../../store/users";
import { useResponsive } from "../../../../utils/useResponsive";
import { useEffect, useState } from "react";
import SuspendSepaModal from "./SuspendSepa.modal";
import { sessionService } from "src/store/session";

const AdminCompanyDashboard = () => {
  const isMobile: boolean = useResponsive();
  const navigate = useNavigate();
  const [openSepaModal, setOpenSepaModal] = useState(false);

  useEffect(() => {
    sessionService.getCompany().subscribe();
  }, []);

  return (
    <Stack gap={4} mt={3} style={{ backgroundColor: Colors.background }} pb={3}>
      {/* <Stack justifyContent='flex-end' direction='row' width='100%' gap={2}>
        <IconButton aria-label='dashboard' color='secondary'><img alt='' src={File} /></IconButton>
        <IconButton aria-label='dashboard' color='primary'><img alt='' src={Help} /></IconButton>
      </Stack> */}
      <Header role={UserRole.ADMIN_COMPANY} selectedTab={"params"} />
      <Typography fontSize={30} fontWeight={800} color={Colors.primaryText}>
        Tous vos paramètres
      </Typography>
      <Grid container gap={3} direction={{ xs: "column", md: "row" }}>
        <Grid item xs>
          <DashboardCard
            icon={<img alt="" src={Dashboard} />}
            title="Catégories d'achat et comptes comptables"
            subtitle="Vous pouvez relier les catégories d'achat à vos comptes comptables (6x), pour l’export Excel des paiements à télécharger dans votre outil comptable"
            onClick={() => navigate("/categories-company-settings")}
          />
        </Grid>
        <Grid item xs>
          <DashboardCard
            icon={<img alt="" src={Settings} />}
            title="Données à compléter"
            subtitle="L'utilisateur doit fournir un justificatif ou renseigner un motif pour chaque paiement ? Vous pouvez définir vos exigences"
            onClick={() => navigate("/data-to-complete")}
          />
        </Grid>
      </Grid>
      <Grid container gap={3} direction={{ xs: "column", md: "row" }}>
        <Grid item xs>
          <DashboardCard
            icon={<img alt="" src={TVA} />}
            title="TVA"
            subtitle="Vous pouvez définir vos règles de calcul automatique de la TVA récupérable par catégorie d'achat"
            onClick={() => navigate("/tva")}
          />
        </Grid>
        <Grid item xs>
          <DashboardCard
            icon={<img alt="" src={Blacklist} />}
            title="Liste noire"
            subtitle="Vous pouvez mettre certains commerçants sur liste noire"
            onClick={() => navigate("/blacklist")}
          />
        </Grid>
      </Grid>
      <Grid container gap={3} direction={{ xs: "column", md: "row" }}>
        <Grid item xs>
          <DashboardCard
            icon={<img alt="" src={Group} />}
            title="Groupes de cartes"
            subtitle="Vous pouvez définir vos groupes de cartes pour suivre les paiements ou définir vos règles de contrôle automatique des paiements selon ces groupes"
            onClick={() => navigate("/groups")}
          />
        </Grid>
        <Grid item xs>
          <DashboardCard
            icon={<img alt="" src={Contract} />}
            title="Contrôle automatique des paiements"
            subtitle="Vous pouvez définir vos règles de contrôle automatique d’utilisation de la carte par groupe d’utilisateurs"
            onClick={() => navigate("/payment-control")}
          />
        </Grid>
      </Grid>
      <Grid container gap={3} marginBottom={isMobile ? "20px" : 0}>
        <Grid item xs>
          <DashboardCard
            icon={<img alt="" src={Exclamation} />}
            title="Logo et politique de frais"
            subtitle="Vous pouvez télécharger votre logo et vos règles d’utilisation de la carte qui seront affichés dans l’en-tête de l’application"
            onClick={() => navigate("/cgu-logo")}
          />
        </Grid>
        <Grid item xs>
          <DashboardCard
            icon={<img alt="" src={Exclamation} />}
            title="Suspendre un mandat SEPA"
            subtitle="Vous pouvez suspendre un mandat SEPA grâce à son ID"
            onClick={() => setOpenSepaModal(true)}
          />
        </Grid>
      </Grid>
      <SuspendSepaModal open={openSepaModal} handleClose={() => setOpenSepaModal(false)} />
    </Stack>
  );
};

export default AdminCompanyDashboard;
