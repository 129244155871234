import { Colors } from "src/constants/colors.constants";
import { Button, Dialog, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import Close from "src/assets/icons/icon_close_dark.svg";
import { Dispatch, FormEvent, SetStateAction, useRef } from "react";
import html2canvas from "html2canvas";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery } from "src/store/session";
import dayjs from "dayjs";

interface NoProofModalProps {
  setProof: Dispatch<
    SetStateAction<{
      modalOpen: boolean;
      proofExist: boolean;
      proof: File | null;
    }>
  >;
  updateAllTaxPercents: (value: string) => void;
}

export const NoProofFileName = "justificatifScreen.png";

const NoProofModal = (props: NoProofModalProps) => {
  const { setProof, updateAllTaxPercents } = props;
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [user] = useObservable(sessionQuery.user$);

  const handleClose = () => setProof((prevProof) => ({ ...prevProof, modalOpen: false }));

  const handleValidate = async (event: FormEvent) => {
    event.preventDefault();
    if (modalRef.current) {
      const canvas = await html2canvas(modalRef.current);
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], NoProofFileName, { type: "image/png" });
          setProof((prevProof) => ({ ...prevProof, proof: file, proofExist: true }));
          updateAllTaxPercents("0");
          handleClose();
        }
      });
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} style={{ borderRadius: "8px", zIndex: 3000 }}>
      <Stack p={"20px"}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="30px" fontWeight={800}>
            {t("payments.edit.noProofModal.title")}
          </Typography>
          <IconButton onClick={handleClose}>
            <img alt="" src={Close} />
          </IconButton>
        </Stack>
        <form onSubmit={handleValidate}>
          <Stack ref={modalRef}>
            <Trans
              i18nKey={"payments.edit.noProofModal.firstPart"}
              values={{ firstLastName: `${user?.firstName} ${user?.lastName}` }}
            />
            <Stack my="15px">
              <TextField
                required
                sx={{ border: `1px solid ${Colors.primary}` }}
                label={t("payments.edit.noProofModal.facts")}
              />
            </Stack>
            <Trans i18nKey={"payments.edit.noProofModal.secondPart"} />
            <Trans
              i18nKey={"payments.edit.noProofModal.dateHour"}
              values={{ date: dayjs().format("DD/MM/YYYY"), hour: dayjs().format("HH:mm") }}
            />
          </Stack>
          <Stack mt="20px">
            <Button color="secondary" type="submit">
              {t("global.validate")}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Dialog>
  );
};

export default NoProofModal;
