import { Stack, Tab, Tabs } from "@mui/material";
import { NavLink } from "react-router-dom";
import SwanIconDark2 from "../assets/icons/icon_swan_dark.svg";
import { UserRole } from "../store/users";
import { useResponsive } from "../utils/useResponsive";
import { Colors } from "../constants/colors.constants";
import { Routes, useRouteMatch } from "src/utils/routes.utils";

interface TabRouteComponentProps {
  routes: Array<Routes>;
  handleChange?: () => void;
  role: UserRole;
}

const TabRouteComponent = (props: TabRouteComponentProps) => {
  const isMobile: boolean = useResponsive();
  const { routes, handleChange, role } = props;

  const currentTab = useRouteMatch(routes.map((route) => route.path));

  return (
    <Tabs
      centered
      value={false}
      onChange={handleChange}
      sx={{
        width: isMobile ? "100%" : null,
        marginTop: isMobile ? "40px" : null,
      }}
      TabIndicatorProps={{ style: { display: "none" } }}
    >
      <Stack
        gap={isMobile ? 0 : 1}
        sx={{
          flexDirection: isMobile ? "row" : "column",
          justifyContent: "center",
        }}
      >
        {role !== UserRole.SUPER_ADMIN && (
          <Tab
            onClick={() => (window.location.href = "https://partner.banking.swan.io/")}
            icon={<img width="100%" src={SwanIconDark2} alt="" />}
            sx={{ opacity: 1, backgroundColor: "white" }}
          />
        )}

        {routes.map((route, index) => (
          <Tab
            key={`Tab ${index}`}
            label={route.label}
            component={NavLink}
            to={route.path}
            value={route.path}
            sx={{ opacity: 1, backgroundColor: currentTab === route.path ? Colors.black : "transparent" }}
            icon={<img src={currentTab === route.path ? route.iconLight : route.iconDark} alt="" />}
          />
        ))}
      </Stack>
    </Tabs>
  );
};

export default TabRouteComponent;
