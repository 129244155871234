import React, {FormEvent, useState} from 'react';
import {Box, Button, Dialog, Grid, IconButton, Stack, Typography} from '@mui/material';
import Close from '../../../../assets/icons/icon_close_dark.svg';
import {
  TransactionClosePeriod,
  TransactionFields,
  transactionServices,
  TransactionStatus
} from '../../../../store/transaction';
import BasicSelect from '../../../../components/inputs/BasicSelect.component';
import {useTranslation} from "react-i18next";
import BasicDatePickerComponent from "../../../../components/inputs/BasicDatePicker.component";
import ConfirmDialog from "../../../../components/Confirm.component";
import {DateField} from "@mui/x-date-pickers";
import {BlacklistedMerchant} from "@store/blacklist";
import {enqueueSnackbar} from "notistack";
import dayjs from "../../../../utils/dayjs.utils";
import {finalize} from "rxjs";
interface ClosePeriodProps {
  handleClose: () => void;
  handleValidate?: () => void;
}

const ClosePeriod = (props: ClosePeriodProps) => {
  const { handleClose } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<TransactionClosePeriod>>(
    {
      from: new Date(new Date().getFullYear(), new Date().getMonth() -1, 1).toDateString(),
      to: new Date(new Date().getFullYear(), new Date().getMonth(), 0).toDateString()
    } || {});
  const [confirmClosePeriod, setConfirmClosePeriod] = useState(false);

  const updateData = (name: string) => (value: any) => {
    setData({...data, [name]: value})
  };

  const handleVerifPeriod = (evt: FormEvent) => {
    evt.preventDefault();

    setLoading(true);

    if (!data?.from) {
      return enqueueSnackbar(t('global.errors.startDateRequired'), { variant: 'error' });
    }

    if (!data?.to) {
      return enqueueSnackbar(t('global.errors.endDateRequired'), { variant: 'error' });
    }

    const fromDate = new Date(Date.parse(data.from));
    const toDate = new Date(Date.parse(data.to));

    if (fromDate > toDate) {
      return enqueueSnackbar(t('global.errors.startDateMustBeBeforeEndDate'), { variant: 'error' });
    }

    setConfirmClosePeriod(true);
  }

  const closePeriodConfirm = () => {
    setLoading(true);
    const period = {from: data?.from, to: data?.to};
    transactionServices.closePeriod(period)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
      error: (err) => {
        enqueueSnackbar(err.text, err.options)
      },
      next: () => {
        enqueueSnackbar(t('payments.closePeriod.confirm.success'), { variant: 'success'});
        setConfirmClosePeriod(false);
        setLoading(true);
        setData({
          from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toDateString(),
          to: new Date(new Date().getFullYear(), new Date().getMonth(), 0).toDateString()
        });
        handleClose();
      }
    })
  }

  return (<>
      {confirmClosePeriod && (
        <ConfirmDialog
          title={t('payments.closePeriod.confirm.title')}
          confirm={t('global.validate')}
          description={`${t('payments.closePeriod.confirm.description', {
            from:   dayjs(Date.parse(data.from!)).format('DD/MM/YYYY'),
            to:  dayjs(Date.parse(data.to!)).format('DD/MM/YYYY')
          })}`}
          cancel={t('global.cancel')}
          handleClose={() => {
            setConfirmClosePeriod(false);
          }}
          handleValidate={() => closePeriodConfirm()}
        />
      )}
      <Dialog open={true} maxWidth='xs' fullWidth>
      <form onSubmit={handleVerifPeriod}>
        <Stack gap={5} p={4} alignItems='center'>
          <Stack
            width='100%'
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={4}>
                <Typography fontSize='30px' fontWeight={800}>
                    {t('payments.closePeriod.title')}
                </Typography>
                <IconButton onClick={handleClose}>
                    <img alt='' src={Close} />
                </IconButton>
            </Stack>
            <Stack direction="row" spacing={2}>
              {/* TO DO BEST */}
              {/*<BasicDatePickerComponent */}
              {/*  value={data.from ? new Date(data.from) : undefined}*/}
              {/*  handleChange={(d) => updateData('from')(d)}*/}
              {/*  label={`${t('payments.closePeriod.startDate')}`}/>*/}
              <Grid item xs >
                <Typography>{t('payments.closePeriod.startDate')}</Typography>
                <DateField
                  value={data.from ? Date.parse(data.from) : undefined}
                  onChange={updateData('from')}
                  aria-label='white'
                  format='dd-MM-yyyy'/>
              </Grid>

              <Grid item xs >
                <Typography>{t('payments.closePeriod.endDate')}</Typography>
                <DateField
                  value={data.to ? Date.parse(data.to) : undefined}
                  onChange={updateData('to')}
                  aria-label='white'
                  format='dd-MM-yyyy'/>
              </Grid>
              {/* TO DO BEST */}
              {/*<BasicDatePickerComponent*/}
              {/*  value={period.to}*/}
              {/*  handleChange={(d) => setPeriod({...period, to: d})}*/}
              {/*  label={`${t('payments.closePeriod.endDate')}`}/>*/}
            </Stack>
          <Stack direction="row" spacing={2}>
            <Button style={{height: '52px'}} color='secondary' onClick={handleVerifPeriod} type='submit'>{t('global.validate')}</Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
    </>
  )
}

export default ClosePeriod;
