import translationFR from '../assets/locales/fr/translations.json';

export enum Locales {
  FR = 'fr',
}

export const LocaleTranslations = {
  [Locales.FR]: {
    translation: translationFR
  }
}