import { select } from '@ngneat/elf';

import { taxesStore } from './taxes.store';
import { getTaxesDataSource } from './taxes.requests';

export class TaxesQuery {
  store = taxesStore;

  taxes$ = getTaxesDataSource.data$();
}

export const taxesQuery = new TaxesQuery();