import { useEffect, useState } from "react";
import { Button, IconButton, Stack, Typography, styled } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Colors } from "../../../../constants/colors.constants";
import { ChevronLeft, ControlPoint } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../../../store/users";

import Edit from "../../../../assets/icons/icon_edit_dark.svg";
import Delete from "../../../../assets/icons/icon_delete_color.svg";
import Header from "../../../../components/header/Header.component";
import { useObservable } from "@ngneat/react-rxjs";
import { taxesQuery } from "../../../../store/taxes/taxes.query";
import { CompanyTax } from "../../../../store/taxes";
import ConfirmDialog from "../../../../components/Confirm.component";
import { taxesServices } from "../../../../store/taxes/taxes.services";
import { enqueueSnackbar } from "notistack";
import { sessionQuery, sessionService } from "../../../../store/session";
import AddTax from "./AddTax.modale";

const CustomDataGrid = styled(DataGrid)({
  border: "none !important",
  width: "100%",
  flexDirection: "column-reverse",
  "& .MuiDataGrid-row": {
    backgroundColor: Colors.white,

    borderRadius: "8px",
    marginTop: "4px",
    marginBottom: "4px",
    border: 0,
    maxHeight: "54px !important",
    minHeight: "54px !important",
    fontSize: "18px",
    fontWeight: "600",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: 0,
  },
  "& .MuiDataGrid-columnHeaders:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    border: 0,
    fontSize: "18px",
    fontWeight: 600,
    color: Colors.primaryText,
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: "transparent",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGridCell--withRenderer":
    {
      outline: "none !important",
    },
  "& .MuiTablePagination-root": {
    position: "absolute",
    top: "52px",
    right: 0,
  },
});

const HeaderTypography = styled(Typography)({
  fontSize: "12px",
  color: Colors.secondaryText,
  fontWeight: "600",
  textTransform: "uppercase",
});

const TVA = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CompanyTax | undefined>();
  const [itemToDelete, setItemToDelete] = useState<CompanyTax | undefined>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [{ taxes, error, loading }] = useObservable(taxesQuery.taxes$);
  const [{ company }] = useObservable(sessionQuery.company$);

  useEffect(() => {
    selectedItem && setModalOpen(true);
  }, [selectedItem]);

  useEffect(() => {
    itemToDelete && setDeleteModalOpen(true);
  }, [itemToDelete]);

  useEffect(() => {
    sessionService.getCompany().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
    if (company?.id)
      taxesServices.getTaxes(company?.id).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }, [company?.id]);

  const handleDeleteTax = () => {
    taxesServices.deleteTax(itemToDelete?.companyId ?? "", itemToDelete?.id ?? "").subscribe({
      next: () => {
        enqueueSnackbar(t("taxes.delete.success"), { variant: "success" });
        setDeleteModalOpen(false);
        setItemToDelete(undefined);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      renderHeader: () => <HeaderTypography>{t("tva.name")}</HeaderTypography>,
      minWidth: 230,
      flex: 1,
      renderCell(params) {
        return (
          <Typography fontSize="18px" fontWeight="600">
            {params.row.serviceCategory.name}
          </Typography>
        );
      },
    },
    {
      field: "tva",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("tva.tva")}</HeaderTypography>,
      minWidth: 150,
      flex: 2,
      renderCell(params) {
        return (
          <Typography fontSize="18px" fontWeight="600">
            {params.row.taxPercent + "%"}
          </Typography>
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setSelectedItem(params.row);
            }}
          >
            <img alt="" src={Edit} />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setItemToDelete(params.row);
              setDeleteModalOpen(true);
            }}
          >
            <img src={Delete} alt="" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      {deleteModalOpen && itemToDelete && (
        <ConfirmDialog
          title={"Supprimer une TVA"}
          confirm={t("global.validate")}
          cancel={t("global.cancel")}
          handleClose={() => {
            setItemToDelete(undefined);
            setDeleteModalOpen(false);
          }}
          handleValidate={() => handleDeleteTax()}
        />
      )}
      {modalOpen && (
        <AddTax
          taxes={taxes}
          companyId={company?.id ?? ""}
          tax={selectedItem}
          handleClose={() => {
            setModalOpen(false);
            setSelectedItem(undefined);
          }}
        />
      )}
      <Stack height="100%" gap={2} pt={3} alignItems="start">
        <Stack width="100%">
          <Header role={UserRole.ADMIN_COMPANY} selectedTab="params" />
        </Stack>
        <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
          {t("global.back")}
        </Button>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography fontSize="30px" fontWeight={800} color={Colors.primaryText}>
            {t("tva.title")}
          </Typography>
          {/* <BasicSearchbar 
            placeholder={t('global.search') || ''} 
            onChange={() => {}} /> */}
          <Stack direction="row" gap={2}>
            <Button
              style={{ height: "52px" }}
              variant="contained"
              color="secondary"
              onClick={() => setModalOpen(true)}
              startIcon={<ControlPoint />}
            >
              Ajouter
            </Button>
          </Stack>
        </Stack>
        <CustomDataGrid
          rows={taxes}
          pagination
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          disableDensitySelector
          disableColumnFilter
          rowSelection={false}
          filterMode="server"
          disableVirtualization
          sortingMode="server"
          columns={columns}
          pageSizeOptions={[]}
          slotProps={{
            pagination: {
              labelRowsPerPage: "test",
            },
          }}
        />
      </Stack>
    </>
  );
};

export default TVA;
