import { createStore } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { CompanyTax } from './taxes.models';

export const taxesStore = createStore(
  {name: 'taxes'},
  withEntities<CompanyTax>(),
  withRequestsStatus()
);
