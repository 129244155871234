import { Button, Dialog, IconButton, Stack, Typography } from "@mui/material";
import { TransactionFull, transactionServices } from "src/store/transaction";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Close from "src/assets/icons/icon_close_dark.svg";

interface DeleteTransactionModalProps {
  open: boolean;
  handleClose: () => void;
  transaction: TransactionFull;
}
const DeleteTransactionModal = (props: DeleteTransactionModalProps) => {
  const { open, handleClose, transaction } = props;
  const { t } = useTranslation();

  const handleDeleteTransaction = () => {
    transactionServices.deleteTransactionById(transaction).subscribe({
      next: () => {
        enqueueSnackbar(t("payments.delete.success"), { variant: "success" });
        handleClose();
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };
  return (
    <Dialog open={open} maxWidth="md" style={{ borderRadius: "8px", zIndex: 2000 }}>
      <Stack direction="column" gap={4} p={4}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="30px" fontWeight={800}>
            {t("payments.delete.title")}
          </Typography>
          <IconButton onClick={handleClose}>
            <img alt="" src={Close} />
          </IconButton>
        </Stack>
        <Stack alignItems={"center"} spacing={5}>
          <Typography fontSize={"24px"}>{t("payments.delete.description")}</Typography>
          <Button color="error" onClick={handleDeleteTransaction}>
            {t("payments.delete.delete")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DeleteTransactionModal;
