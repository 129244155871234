import { select } from '@ngneat/elf';
import { globalSettingsStore } from './globalSettings.store';
import { GlobalSettings } from './globalSettings.models';
import { getGlobalSettingsDataSource } from './globalSettings.requests';

export class GlobalSettingsQuery {
  store = globalSettingsStore;

  globalSettings$ = getGlobalSettingsDataSource.data$();
}

export const globalSettingsQuery = new GlobalSettingsQuery();