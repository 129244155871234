export interface ConfirmActionProps {
  type: OtherActionType;
  row: any;
}

interface OtherActionProps {
  handleClick?: (row: any) => void;
  hidden?: (row: any) => boolean;
  type: OtherActionType;
}

export enum OtherActionType {
  DELETE = "DELETE",
  EDIT = "EDIT",
  CREATE = "CREATE",
  CANCEL = "CANCEL",
  SAVE = "SAVE",
}

export namespace OtherActionType {
  //TODO CHECK WITH CAMILLE
  // export const label = (action: OtherActionType) => {
  //   //TODO LINK THIS
  //   switch (action) {
  //     case OtherActionType.DELETE:
  //       return I18nUtils.t('global.delete')
  //     case OtherActionType.EDIT:
  //       return I18nUtils.t('global.modify')
  //     default:
  //       return ''
  //   }
  // }

  export const translationKey = (action: OtherActionType) => {
    switch (action) {
      case OtherActionType.DELETE:
        return "delete";
      default:
        return "";
    }
  };
}

interface EditActionComponentProps {
  rowId: string | number;
  showInMenu?: boolean;
}

// const EditActionComponent = (props: EditActionComponentProps) => {
//   const apiRef = useGridApiContext()
//
//   return (
//     <GridActionsCellItem
//       icon={OtherActionType.icon(OtherActionType.EDIT)}
//       onClick={() => apiRef.current.startRowEditMode({ id: props.rowId })}
//       label={OtherActionType.label(OtherActionType.EDIT)}
//       showInMenu={props.showInMenu}
//     />
//   )
// }

// interface CancelAndSaveActionsComponentProps {
//   handleCancel?: () => void
//   rowId: string | number
// }

// const CancelAndSaveActionsComponent = (props: CancelAndSaveActionsComponentProps) => {
//   const apiRef = useGridApiContext()
//
//   const isEditMode = apiRef.current.getRowMode(props.rowId) === GridRowModes.Edit
//
//   if (!isEditMode) return null
//
//   return [
//     <GridActionsCellItem
//       icon={OtherActionType.icon(OtherActionType.SAVE)}
//       onClick={() => apiRef.current.stopRowEditMode({ id: props.rowId })}
//       label={OtherActionType.label(OtherActionType.SAVE)}
//       showInMenu={false}
//     />,
//     <GridActionsCellItem
//       icon={OtherActionType.icon(OtherActionType.CANCEL)}
//       onClick={() => {
//         apiRef.current.stopRowEditMode({ id: props.rowId, ignoreModifications: true })
//         props.handleCancel?.()
//       }}
//       label={OtherActionType.label(OtherActionType.CANCEL)}
//       showInMenu={false}
//     />,
//   ]
// }
