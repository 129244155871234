import { createRequestDataSource } from "@ngneat/elf-requests";
import { sessionStore } from "./session.store";
import { select } from "@ngneat/elf";

export const getUserDataSource = createRequestDataSource({
  store: sessionStore,
  data$: () => sessionStore.pipe(
    select((state) => state.user)
  ),
  requestKey: 'getUser',
  dataKey: 'user'
})

export const getCompanyDataSource = createRequestDataSource({
  store: sessionStore,
  data$: () => sessionStore.pipe(
    select((state) => state.company)
  ),
  requestKey: 'getCompany',
  dataKey: 'company'
})