export interface Coordinates {
	lat: number;
	lng: number;
}

export interface DistanceInfo {
	distance: number;
	duration: number;
}

// Constante pour l'émission de CO2 en grammes par km
export const CO2_EMISSION_RATE_PER_KM = 82;

/**
 * Calcul de l'émission de CO2 pour une distance donnée.
 * @param distanceKm 
 * @returns
 */
export const calculateCO2Emission = (distanceKm: number): number => {
	return distanceKm * CO2_EMISSION_RATE_PER_KM;
};

/**
 * Convertit les degrés en radians.
 * @param degrees 
 * @returns
 */
function toRadians(degrees: number): number {
	return (degrees * Math.PI) / 180;
}

/**
 * Calcul de la distance à vol d'oiseau entre deux points 
 * géographiques en utilisant la formule de Haversine.
 * @param origin 
 * @param destination 
 * @returns
 */
export function calculateDistance(
	origin: Coordinates,
	destination: Coordinates
): DistanceInfo {
	const radius = 6371;
	const deltaLat = toRadians(destination.lat - origin.lat);
	const deltaLng = toRadians(destination.lng - origin.lng);
	const a =
		Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
		Math.cos(toRadians(origin.lat)) *
		Math.cos(toRadians(destination.lat)) *
		Math.sin(deltaLng / 2) *
		Math.sin(deltaLng / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const distance = radius * c;

	const duration = distance / 80;

	return {
		distance: distance,
		duration: duration,
	};
}
