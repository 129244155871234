import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/colors.constants';
import { Stack, Grid, Typography } from '@mui/material';

import Smartphone from '../../../assets/images/img_smartphone_home.svg';

import LoginForm from './login.form';
import ForgotPasswordForm from './forgotPassword.form';

interface LoginProps {
  forgotPassword?: boolean;
}

const LoginScreen = (props: LoginProps) => {
  const { forgotPassword } = props;

  const { t } = useTranslation();

  return (
    <Grid container height='100%' direction='row'>
      <Grid item container 
        direction='column' 
        p={4}
        xs
        color='primary' 
        sx={{backgroundColor: Colors.primary}}
        justifyContent='center'
        alignItems='center'
        >
        <Grid item xs={7}>
          <img alt='' src={Smartphone} />
        </Grid>
        <Grid item xs={2}>
          <Stack gap={2}>
            <Typography fontSize='34px' fontWeight='900' align='center'>
              {t('login.title')}
            </Typography>
            <Typography fontSize='22px' fontWeight='500' align='center'>
              {t('login.subtitle')}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item container justifyContent='center' xs p={2}>
        {forgotPassword ? (
          <ForgotPasswordForm />
        ) : (
          <LoginForm />
        )}
      </Grid>
    </Grid>
  )
};

export default LoginScreen;