import I18n from "./i18n.utils";
import { array, mixed, number, object, string, ValidationError } from "yup";

export interface FieldErrors {
  [field: string]: string;
}

export enum FieldValidationType {
  REQUIRED_STRING = "REQUIRED_STRING",
  REQUIRED_NUMBER = "REQUIRED_NUMBER",
  REQUIRED_EMAIL = "REQUIRED_EMAIL",
  REQUIRED_ARRAY = "REQUIRED_ARRAY",
  REQUIRED_SELECT_ITEM = "REQUIRED_SELECT_ITEM",
  REQUIRED_PASSWORD = "REQUIRED_PASSWORD",
  REQUIRED_FILE = "REQUIRED_FILE",
}

const fieldsValidation = {
  [FieldValidationType.REQUIRED_STRING]: string().nullable()
    .required(I18n.t("errors.required") || ''),
  [FieldValidationType.REQUIRED_EMAIL]: string().nullable()
    .required(I18n.t("errors.required") || '')
    .email(I18n.t("errors.email") || ''),
  [FieldValidationType.REQUIRED_ARRAY]: array().nullable()
    .required(I18n.t("errors.required") || '')
    .min(1, I18n.t("errors.required") || ''),
  [FieldValidationType.REQUIRED_NUMBER]: number().nullable()
    .required(I18n.t("errors.required") || ''),
  [FieldValidationType.REQUIRED_SELECT_ITEM]: object().nullable()
    .required(I18n.t("errors.required") || ''),
  [FieldValidationType.REQUIRED_FILE]: mixed()
    .required(I18n.t("errors.importFile") || ''),
    // .test("fileSize", I18n.t("errors.fileTooLarge") || '', (value) => value && value.size < 10000000),
  [FieldValidationType.REQUIRED_PASSWORD]: string()
    .required(I18n.t("errors.required") || '')
    .min(8, I18n.t("errors.tooShort", { count: 8 }) || '')
    // .test("oneUppercase", I18n.t("errors.oneUppercase"), (value) => !!value && /[A-Z]/.test(value))
    // .test("oneLowercase", I18n.t("errors.oneLowercase"), (value) => !!value && /[a-z]/.test(value))
};

export const getFieldError = (value: any, validationType: FieldValidationType) => {
  try {
    fieldsValidation[validationType].validateSync(value);
  } catch (err: any) {
    return err.message;
  }
};

export const checkIfErrors: any = (errors: Object) => Object.values(errors).some(
  (err) => typeof err === "string" || typeof err === "undefined" ? !!err : checkIfErrors(err)
);


export const transformError = (err: ValidationError): FieldErrors => {
  return err.inner.reduce((formError: any, innerError: any) => {
    const oldError = formError[innerError.path!];

    return {
      ...formError,
      [innerError.path!]: oldError || innerError.message
    };
  }, {} as FieldErrors);
};
