import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs';
import { FieldErrors } from '../../../../utils/yup.utils';
import { enqueueSnackbar } from 'notistack';
import { addCategoryChildValidation } from './AddCategoryChild.yup';

import BasicTextfield from '../../../../components/inputs/BasicTextfield.component';
import BasicSelect from '../../../../components/inputs/BasicSelect.component';
import Close from '../../../../assets/icons/icon_close_dark.svg';
import { CategoryChildren, categoryChildrenServices } from '../../../../store/categoryChildren';
import APIAxios, { APIRoutes } from '../../../../api/axios.api';
import AsyncSelectWithSearchComponent, { SelectOption } from '../../../../components/inputs/AsyncSelectWithSearchComponent.component';
import { Category } from '../../../../store/serviceCategories';

interface AddCategoryChildProps {
  affectedCategories: string[];
  handleClose?: () => void;
  categoryChild?: CategoryChildren;
}

const AddCategoryChild = (props: AddCategoryChildProps) => {

  const { handleClose, categoryChild, affectedCategories } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<CategoryChildren>>(categoryChild || {});
  const [errors, setErrors] = useState<FieldErrors>({});
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<SelectOption | undefined>(
    categoryChild?.serviceCategory ? 
    { label: categoryChild.serviceCategory.name, 
      value: categoryChild.serviceCategory.id, 
      data: categoryChild.serviceCategory } : undefined);

  const { t } = useTranslation();
  
  const getCategories = async (): Promise<void> => {
    return await APIAxios({...APIRoutes.GETAllCategories()})
    .then((res) => {
      if (res.data) {
        var newcategories: Category[] = res.data;
        newcategories = newcategories.filter((it) => {
          return (!affectedCategories.includes(it.id) || it.id === categoryChild?.serviceCategory.id)
        })
        setCategories(newcategories)
      }
    })
  }

  useEffect(() => {
    getCategories()
  }, []);

  const updateData = (name: string) => (value: any) => setData({ ...data, [name]: value });

  const handleValidate = (evt: FormEvent) => {

    evt.preventDefault();
    if (!data) return;

    const errors = addCategoryChildValidation(data);

    setErrors(errors);

    if (!!Object.keys(errors).length) return;

    setLoading(true);
    
    if (categoryChild) {
      categoryChildrenServices
        .updateCategoryChild(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: () => handleClose?.()
      })
    } else {
      categoryChildrenServices
      .addCategoryChild(data)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        error: (err) => {
          enqueueSnackbar(err.text, err.options)
        },
        next: () => handleClose?.()
      })
    }
  }

  return (
    <Dialog open={true} maxWidth={'md'} fullWidth style={{borderRadius: '8px'}}>
      <form onSubmit={handleValidate} noValidate>
        <Stack direction='column' gap={4} p={4}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography fontSize='30px' fontWeight={800}>{t('categories.add.title')}</Typography>
            <IconButton onClick={handleClose}><img alt='' src={Close} /></IconButton>
          </Stack>
          <Stack gap={3} direction='column'>
            <Typography fontSize='20px' fontWeight={700}>Associer une catégorie à un compte comptable</Typography>
            <Grid container gap={3}>
              <Grid item xs>
                  <BasicSelect
                    ariaLabel='white'
                    color='secondary'
                    handleChange={(value) => {
                      updateData('serviceCategory')(categories.find((cat: Category) => cat.id === value))}
                    }
                    items={categories.map((it) => ({label: it.name, value: it.id}))}
                    fullWidth
                    value={data.serviceCategory?.id || ''}
                    placeholder={'Catégorie'}
                  />
              </Grid>
              <Grid item xs>
                <BasicTextfield
                  ariaLabel='white'
                  onChange={updateData('associatedAccount')}
                  // error={errors.name}
                  value={data?.associatedAccount}
                  placeholder={t('categories.table.associatedAccount') || ''}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack width='100%' alignItems='center'>
            <Button 
              type='submit'
              disabled={loading}
              style={{ width: '215px', height: '52px' }}
              color='secondary' 
              variant='contained'>
              {categoryChild ? t('global.validate') : 'Associer'}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}

export default AddCategoryChild;