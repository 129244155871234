import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";
import { TransactionFilter } from "./transaction.models";
import { transactionServices } from "./transaction.services";

export const searchTransactionEffects = createEffectFn((params$: Observable<TransactionFilter>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((filters) => transactionServices.getTransactions(filters))
  );
});

export const searchTransactionTotalEffects = createEffectFn((params$: Observable<TransactionFilter>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((filters) => transactionServices.getTransactionTotal(filters))
  );
});
