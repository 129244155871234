import { select } from '@ngneat/elf';

import { transactionStore } from './transaction.store';
import { GETTransactionByIdDataSource, GETTransactionTotalDataSource, getTransactionDataSource } from './transaction.requests';

export class TransactionQuery {
  store = transactionStore;

  transactions$ = getTransactionDataSource.data$();
  trasaction$ = GETTransactionByIdDataSource.data$();
  totals$ = GETTransactionTotalDataSource.data$();

  companyExportTransactionConfig$ = this.store.pipe(select((state) => state.exportTransaction));

  filters$ = this.store.pipe(select((state) => state.filters));
}


export const transactionsQuery = new TransactionQuery();
