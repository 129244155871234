import { catchError, from, map, Observable, tap } from 'rxjs';
import { addEntities, deleteEntities, setEntities, updateEntities } from '@ngneat/elf-entities';
import { taxesStore } from './taxes.store';
import { CompanyTax } from './taxes.models';
import { getTaxesDataSource } from './taxes.requests';
import APIAxios, { APIRoutes } from '../../api/axios.api';
import SnackError from '../../utils/errors.utils';
import { AxiosError, AxiosResponse } from 'axios';

export class TaxesServices {
  store = taxesStore;

  resetStore = () => this.store.reset();

  addTax = (companyId: string, newTax: Partial<CompanyTax>): Observable<CompanyTax> => {
    return from(APIAxios({
      ...APIRoutes.POSTCompanyTaxes(companyId),
      data: {
        ...newTax,
        serviceCategory: newTax.serviceCategory?.id
      }
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error')
      }),
      map((response: AxiosResponse<CompanyTax>) => {
        return response.data;
      }),
      tap((tax) => {
        this.store.update(addEntities(tax))
      })
    )
  }

  getTaxes = (companyId: string): Observable<CompanyTax[]> => {
    return from(APIAxios({...APIRoutes.GETCompanyTaxes(companyId)})).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error')
      }),
      map((response: AxiosResponse<CompanyTax[]>) => {
        return response.data;
      }),
      tap((taxes) => {
        this.store.update(setEntities(taxes), getTaxesDataSource.setSuccess())
      })
    )
  }

  updateTaxes = (tax: Partial<CompanyTax>): Observable<CompanyTax> => {
    return from(APIAxios({...APIRoutes.PATCHCompanyTaxes(tax.companyId ?? '', tax.id ?? ''),
      data: {
        ...tax,
        serviceCategory: tax.serviceCategory?.id
      }
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error');
      }),
      map((response: AxiosResponse<CompanyTax>) => {
        return response.data;
      }),
      tap((tax) => {
        this.store.update(updateEntities(tax.id, tax))
      })
    )
  }

  deleteTax = (companyId: string, taxId: string): Observable<AxiosResponse> => {
    return from(APIAxios({...APIRoutes.DELETECompanyTaxes(companyId, taxId)})).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error');
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        this.store.update(deleteEntities(taxId));
        return response.data;
      })
    )
  }
}

export const taxesServices = new TaxesServices();