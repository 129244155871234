import { object, string, number } from 'yup';
import { Category } from '../../../store/serviceCategories';

import I18n from '../../../utils/i18n.utils';
import * as YupUtils from '../../../utils/yup.utils';

export const addCategoryValidation = (category: Partial<Category>) => {
  const requiredString = string().required(I18n.t('errors.required') || '');
  const requiredNumber = number().required(I18n.t('errors.required') || '');

  try {
    const schema = object().shape({
      type: requiredString,
      name: requiredString,
    });
    schema.validateSync(category, { abortEarly: false });
    return {}
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}