import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";

import { groupsStore } from "./groups.store";

export const getGroupsDataSource = createRequestDataSource({
  store: groupsStore,
  data$: () => groupsStore.pipe(selectAllEntities()),
  requestKey: 'getGroups',
  dataKey: 'groups'
})