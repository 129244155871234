import React, { useEffect, useState } from 'react';
import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Close from '../../assets/icons/icon_close_dark.svg';
import { useObservable } from '@ngneat/react-rxjs';
import { globalSettingsQuery, globalSettingsServices } from '../../store/globalSettings';
import { sessionQuery } from '../../store/session';
import SnackError from '../../utils/errors.utils'; 

interface HelpModaleProps {
  handleClose: () => void;
}

const HelpModale = (props: HelpModaleProps) => {
  const { handleClose } = props;

  const [{ globalSettings }] = useObservable(globalSettingsQuery.globalSettings$);
  const [{ company }] = useObservable(sessionQuery.company$);

  const [onlineHelpUrl, setOnlineHelpUrl] = useState<string | undefined>();
  const [companyHelpDocumentUrl, setCompanyHelpDocumentUrl] = useState<string | undefined>()

  const { t } = useTranslation();

  useEffect(() => {
    globalSettingsServices
      .getFile(globalSettings?.onlineHelpUrl ?? '')
      .subscribe({
        next: (value) => {
          setOnlineHelpUrl(value.url)
        }
      })
    company?.onlineHelpUrl &&
      globalSettingsServices
        .getFile(company.onlineHelpUrl)
        .subscribe({
          next: (value) => {
            setCompanyHelpDocumentUrl(value.url)
          }
        })
  }, [globalSettings?.onlineHelpUrl, company?.onlineHelpUrl])

  const handleFileError = (err: any) => {
    if (err.response && err.response.data && err.response.data.message) {
      throw new SnackError(err.response.data.message, 'error');
    } else {
      throw new SnackError('Une erreur inattendue s\'est produite', 'error');
    }
  }

  return (
    <Dialog open={true} maxWidth='xs' fullWidth>
      <Stack gap={5} p={4} alignItems='center'>
        <Stack
          width='100%'
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
          <Typography fontSize='30px' fontWeight={800}>
            {t('help.title')}
          </Typography>
          <IconButton onClick={handleClose}>
            <img alt='' src={Close} />
          </IconButton>
        </Stack>
        <Stack gap={3}>
          {onlineHelpUrl && onlineHelpUrl!='undefined' && <Button
            style={{
              textTransform: 'none',
              height: '52px'
            }}
            color='primary'
            variant='contained'
            onClick={() => {
              window.open(onlineHelpUrl, "_blank");
            }}>
            {t('help.impact')}
          </Button>}
          {companyHelpDocumentUrl && (
            <Button
              style={{
                textTransform: 'none',
                height: '52px'
              }}
              color='primary'
              variant='contained'
              onClick={() => {
                window.open(companyHelpDocumentUrl, "_blank");
              }}>
              {t('help.company')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default HelpModale;
