import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { alpha, Button, Chip, IconButton, Stack, styled, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../constants/colors.constants";
import { ChevronLeft, ControlPoint } from "@mui/icons-material";
import {
  MembershipStatus,
  searchUsersEffect,
  User,
  UserCard,
  UserRole,
  usersQuery,
  usersServices,
} from "../../../../store/users";

import Edit from "../../../../assets/icons/icon_edit_dark.svg";
import Delete from "../../../../assets/icons/icon_delete_color.svg";
import Header from "../../../../components/header/Header.component";
import BasicSearchbar from "../../../../components/inputs/BasicSearchBar.component";
import AddGroup from "./AddGroup.modal";
import { Group, groupsQuery, groupsServices } from "../../../../store/groups";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";
import APIAxios, { APIRoutes } from "../../../../api/axios.api";
import { enqueueSnackbar } from "notistack";
import ConfirmDialog from "../../../../components/Confirm.component";
import { OtherActionType } from "../../../../utils/table/columnTypes/actions.columnType";
import BasicSelect from "../../../../components/inputs/BasicSelect.component";

const CustomDataGrid = styled(DataGrid)({
  border: "none !important",
  width: "100%",
  flexDirection: "column-reverse",
  "& .MuiDataGrid-row": {
    backgroundColor: Colors.white,
    borderRadius: "8px",
    marginTop: "4px",
    marginBottom: "4px",
    border: 0,
    maxHeight: "54px !important",
    minHeight: "54px !important",
    fontSize: "18px",
    fontWeight: "600",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: 0,
  },
  "& .MuiDataGrid-columnHeaders:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    border: 0,
    fontSize: "18px",
    fontWeight: 600,
    color: Colors.primaryText,
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: "transparent",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGridCell--withRenderer":
    {
      outline: "none !important",
    },
  "& .MuiTablePagination-root": {
    position: "absolute",
    top: "52px",
    right: 0,
  },
});

const HeaderTypography = styled(Typography)({
  fontSize: "12px",
  color: Colors.secondaryText,
  fontWeight: "600",
  textTransform: "uppercase",
});

const Groups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any | undefined>();
  const [selectedDeleteItem, setSelectedDeleteItem] = useState<{ groupId: string; user: User } | undefined>();
  const [usersCard] = useObservable(usersQuery.usersCard$);
  const [filters] = useObservable(groupsQuery.filters$);
  const [groups, setGroups] = useState<Group[]>([]);
  const [pagination] = useObservable(usersQuery.pagination$);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const rowCountRef = useRef(pagination?.totalCount || 0);

  const rowCount = useMemo(() => {
    if (pagination?.totalCount !== undefined) {
      rowCountRef.current = pagination.totalCount;
    }
    return rowCountRef.current;
  }, [pagination?.totalCount]);

  useEffect(() => {
    groupsServices.setFilters({
      page: paginationModel.page + 1,
    });
  }, [paginationModel]);

  const searchUsers = useEffectFn(searchUsersEffect);

  const getGroups = async (q?: string): Promise<Group[]> => {
    return await APIAxios({ ...APIRoutes.GETGroups() }).then((res) => {
      if (res.data) {
        setGroups(res.data);
      }
      return res.data;
    });
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    groupsServices.setFilters({
      page: 1,
      orderByDirection: sortModel[0]?.sort || undefined,
      orderBy: sortModel[0]?.field || undefined,
    });
  }, []);

  const handleAddRemoveUserFromGroup = (groupId: string, userCard: UserCard, type?: OtherActionType) => {
    usersServices.addRemoveCardFromGroup(groupId, userCard).subscribe({
      next: () => {
        switch (type) {
          case OtherActionType.DELETE:
            enqueueSnackbar(t("groups.cardRemoved"), { variant: "success" });
            break;
          default:
            enqueueSnackbar(t("groups.cardAdded"), { variant: "success" });
        }
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handleRemoveGroup = (groupId: string) => {
    usersServices.removeGroup(groupId).subscribe({
      next: () => {
        setSelectedDeleteItem(undefined);
        setDeleteModalOpen(false);
        enqueueSnackbar(t("Le groupe a été supprimé"), { variant: "success" });
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    searchUsers(filters);
  }, [filters, searchUsers]);

  useEffect(() => {
    selectedItem && setModalOpen(true);
  }, [selectedItem]);

  const columns: GridColDef[] = [
    {
      field: "name",
      renderHeader: () => <HeaderTypography>{t("groups.name")}</HeaderTypography>,
      minWidth: 230,
      flex: 2,
      renderCell(params) {
        return (
          <Typography fontSize="18px" fontWeight="600">
            {` ${params.row.userFirstName} ${params.row.userLastName} - ${params.row.cardName ?? t("card")}`}
          </Typography>
        );
      },
    },
    {
      field: "status",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("groups.status")}</HeaderTypography>,
      minWidth: 150,
      flex: 1,
      renderCell(params) {
        return (
          <Chip
            style={{ backgroundColor: alpha(MembershipStatus.color(params.row.accountMembershipStatus), 0.3) }}
            label={MembershipStatus.label(params.row.accountMembershipStatus)}
          />
        );
      },
    },
    {
      field: "group",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("groups.group")}</HeaderTypography>,
      minWidth: 150,
      flex: 2,
      renderCell(params) {
        let value = params.row?.cardGroup?.toString();
        if (!value || !groups.find((g) => g.id === params.row?.cardGroup)) value = "";
        return (
          <BasicSelect
            canBeEmpty={true}
            ariaLabel="white"
            color="secondary"
            handleChange={(value) => {
              if (!value) {
                handleAddRemoveUserFromGroup(params.row.cardGroup, params.row, OtherActionType.DELETE);
                return;
              } else {
                handleAddRemoveUserFromGroup(value, params.row);
              }
            }}
            items={groups ? groups.map((it) => ({ label: it.name, value: it.id })) : []}
            fullWidth
            value={value}
            placeholder={t("categories.add.default") ?? ""}
          />
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            disabled={!!!params.row.group}
            onClick={() => {
              const lastGroupValue = groups?.find((g) => g.id === params.row.group.id);
              if (lastGroupValue) {
                setSelectedItem(lastGroupValue);
              }
            }}
          >
            <img alt="" src={Edit} />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            disabled={!!!params.row.group}
            onClick={() => {
              setSelectedDeleteItem({ groupId: params.row.group.id, user: params.row });
              setDeleteModalOpen(true);
            }}
          >
            <img src={Delete} alt="" />
          </IconButton>
        );
      },
    },
  ];
  return (
    <>
      {modalOpen && (
        <AddGroup
          group={selectedItem}
          handleRefreshGroup={(data, type) => {
            setModalOpen(false);
            setSelectedItem(undefined);
            if (type === OtherActionType.CREATE) {
              setGroups([...groups, ...[data]]);
            } else {
              getGroups();
              setModalOpen(false);
            }
          }}
          handleClose={() => {
            setModalOpen(false);
            setSelectedItem(undefined);
          }}
        />
      )}
      {deleteModalOpen && (
        <ConfirmDialog
          title="Etes-vous sûr de vouloir supprimer ce groupe ?"
          confirm="Supprimer"
          cancel="Annuler"
          handleValidate={() => {
            if (selectedDeleteItem) {
              handleRemoveGroup(selectedDeleteItem.groupId);
              setGroups([...groups.filter((g) => g.id !== selectedDeleteItem.groupId)]);
            }
          }}
          handleClose={() => {
            setSelectedDeleteItem(undefined);
            setDeleteModalOpen(false);
          }}
        />
      )}
      <Stack pt={3} gap={3} height="100%">
        <Header role={UserRole.ADMIN_COMPANY} selectedTab="params" />
        <Stack alignItems="start" gap={2}>
          <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
            {t("global.back")}
          </Button>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Typography fontSize="30px" fontWeight={800} color={Colors.primaryText}>
              {t("groups.title")}
            </Typography>
            <Stack direction="row" gap={2}>
              <BasicSearchbar
                placeholder={t("global.search") || ""}
                onChange={(v) => groupsServices.setFilters({ searchText: v })}
              />
              <Button
                style={{ height: "52px" }}
                variant="contained"
                color="secondary"
                onClick={() => setModalOpen(true)}
                startIcon={<ControlPoint />}
              >
                {t("global.add")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        {usersCard && (
          <CustomDataGrid
            rows={usersCard}
            rowCount={rowCount}
            pagination
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            disableDensitySelector
            disableColumnFilter
            rowSelection={false}
            filterMode="server"
            paginationMode="server"
            disableVirtualization
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            columns={columns}
          />
        )}
      </Stack>
    </>
  );
};

export default Groups;
