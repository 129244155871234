import { alpha, createTheme } from "@mui/material";
import { Colors } from "../constants/colors.constants";
import fonts from "../assets/fonts/index";
import palette from "./palette.theme";
import textfield from "./input.theme";
import typography from "./typography.theme";
import button from "./button.theme";
import iconButton from "./iconButton.theme";
import switchInput from "./switch.theme";
import accordion from "./accordion.theme";
import tab from "./tab.theme";
import card from "./card.theme";
import chip from "./chip.theme";

const theme = createTheme({
  palette,
  typography: {
    fontFamily: "Urbanist",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `${fonts}
        html {
          height: 100%;
          min-height:100%;
          // -webkit-font-smoothing: auto;
          backgroundColor: ${Colors.background};
        }
        body {
          height: 100%;
          scrollbar-color: ${Colors.primary} ${alpha(Colors.primary, 0.1)};
          scrollbar-width: thin;
          backgroundColor: ${Colors.background};
        }
        div#root {
					height: 100%;
          white-space: pre-line;
          background-color: ${Colors.background};
				}
        *::-webkit-scrollbar {
          height: 8px;
          width: 8px;
        }
        *::-webkit-scrollbar-track {
          background-color: ${alpha(Colors.primary, 0.1)};
          border-radius: 4px;
          margin: 15px;
        }
        *::-webkit-scrollbar-thumb {
          background-color: ${Colors.primary};
          border-radius: 4px;`,
    },
    ...textfield,
    ...typography,
    ...button,
    ...iconButton,
    ...switchInput,
    ...accordion,
    ...tab,
    ...card,
    ...chip,
  },
});

export default theme;
