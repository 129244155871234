import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { CategoryChildren, CategoryChildrenFilter } from "./categoryChildren.models";

interface CategoryChildrenStoreProps {
  filters: CategoryChildrenFilter;
}

export const categoryChildrenStore = createStore(
  { name: 'categoryChildren' },
  withProps<CategoryChildrenStoreProps>({
    filters: { page: 1 }
  }),
  withEntities<CategoryChildren>(),
  withRequestsStatus()
)