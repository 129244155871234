import { Colors } from "src/constants/colors.constants";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const CustomDataGrid = styled(DataGrid)({
  border: "none !important",
  width: "100%",
  flexDirection: "column-reverse",
  "& .MuiDataGrid-row": {
    backgroundColor: Colors.white,
    borderRadius: "8px",
    marginTop: "4px",
    marginBottom: "4px",
    border: 0,
    maxHeight: "54px !important",
    minHeight: "54px !important",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: 0,
  },
  "& .MuiDataGrid-columnHeaders:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    border: 0,
    fontSize: "18px",
    fontWeight: 600,
    color: Colors.primaryText,
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: "transparent",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGridCell--withRenderer":
    {
      outline: "none !important",
    },
  "& .MuiTablePagination-root": {
    position: "absolute",
    top: "52px",
    right: 0,
  },
});

export const HeaderTypography = styled(Typography)({
  fontSize: "12px",
  color: Colors.secondaryText,
  fontWeight: "600",
  textTransform: "uppercase",
});
