import { useTranslation } from "react-i18next";
import { Grid, Stack, Typography } from "@mui/material";
import { Colors } from "../../../../constants/colors.constants";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../../../store/users";

import Dashboard from "../../../../assets/icons/icon_category_color.svg";
import Contract from "../../../../assets/icons/icon_contract_color.svg";
import Exclamation from "../../../../assets/icons/icon_exclamationmark_exception_color.svg";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";

import DashboardCard from "../../../../components/card/DashboardCard.component";
import Header from "../../../../components/header/Header.component";
import AppsIcon from "@mui/icons-material/Apps";
import { Pages } from "src/utils/routes.utils";
import { useState } from "react";
import VerifyTransactionModal from "./VerifyTransaction.modal";

const SuperAdminDashboard = () => {
  const { t } = useTranslation();
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Stack gap={4} mt={3}>
        <Header role={UserRole.SUPER_ADMIN} />
        <Typography fontSize={30} fontWeight={800} color={Colors.primaryText}>
          Tous vos paramètres
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={<img alt="" src={Dashboard} />}
              title="Catégories"
              subtitle="Paramétrez ici toutes les catégories (type, MCC, CO2 pour les MSP)"
              onClick={() => navigate("/categories")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={<img alt="" src={Contract} />}
              title="Exceptions MID"
              subtitle="Forcez ici la catégorie de certains fournisseurs (MCC inapproprié)"
              onClick={() => navigate("/mid")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={<img alt="" src={Exclamation} />}
              title="Logo et CGU"
              subtitle="Téléchargez ici votre logo et vos conditions d’utilisation du service"
              onClick={() => navigate("/cgu-logo")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={<AppsIcon color="primary" fontSize="large" />}
              title="Application Impact des clients"
              subtitle="Accéder à l'application impact de vos clients"
              onClick={() => navigate(`/${Pages.ACCOUNT_HOLDER}`)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={<DomainVerificationIcon color="primary" fontSize="large" />}
              title="Vérifier les paiements"
              subtitle="Vérifier les paiements sur une période"
              onClick={() => setOpenVerifyModal(true)}
            />
          </Grid>
        </Grid>
      </Stack>
      <VerifyTransactionModal open={openVerifyModal} handleClose={() => setOpenVerifyModal(false)} />
    </>
  );
};

export default SuperAdminDashboard;
