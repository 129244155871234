import { paymentRulesStore } from './paymentRules.store'
import { getPaymentRulesDataSource } from './paymentRules.requests';
import { select } from '@ngneat/elf';

export class PaymentRulesQuery {
  store = paymentRulesStore;

  paymentRules$ = getPaymentRulesDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters))
}

export const paymentRulesQuery = new PaymentRulesQuery();