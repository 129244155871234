import { CompanyTax } from '@store/taxes';
import I18n from '../../../../utils/i18n.utils';
import { object, string, number } from 'yup';
import * as YupUtils from '../../../../utils/yup.utils';

export const addTaxValidation = (tax: Partial<CompanyTax>) => {
  const requiredNumber = number().required(I18n.t('errors.required') || '');

  try {
    const schema = object().shape({
      // serviceCategory: object().required(),
      taxPercent: requiredNumber
    })
    schema.validateSync(tax, { abortEarly: false });
    return {}
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}