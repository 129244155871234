import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { ScoreDto } from "@store/scores";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../../constants/colors.constants";
import Score from "./Score.component";

interface GroupScoreProps {
  scores: ScoreDto;
}

const GroupScore = (props: GroupScoreProps) => {
  const { scores } = props;
  const { t: t_dashboard } = useTranslation(undefined, {
    keyPrefix: "individualDashboard",
  });
  return (
    <Stack
      sx={{
        backgroundColor: Colors.white,
        borderRadius: "20px",
        padding: "30px",
        flex: 4,
        justifyContent: "center",
        alignItems: "center",
        gap: "30px",
      }}
    >
      {!scores ? (
        <Stack>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Stack sx={{ width: "100%", gap: "30px", alignItems: "center" }}>
            <Typography
              fontSize="22px"
              fontWeight="700"
              alignSelf={"flex-start"}
            >
              {t_dashboard("groupAverageScore", { groupName: "Commerciaux" })}
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Stack alignItems={"center"}>
                  <Score score={scores.bestGroupScore} size={90} />
                  <Typography
                    fontSize="16px"
                    fontWeight="500"
                    color={Colors.secondaryText}
                  >
                    {t_dashboard("bestScore")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack alignItems={"center"}>
                  <Score score={scores.averageGroupScore} size={90} />
                  <Typography
                    fontSize="16px"
                    fontWeight="500"
                    color={Colors.secondaryText}
                  >
                    {t_dashboard("averageScore")}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Stack sx={{ width: "100%", gap: "30px", alignItems: "center" }}>
            <Typography
              fontSize="22px"
              fontWeight="700"
              alignSelf={"flex-start"}
            >
              {t_dashboard("companyAverageScore")}
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Stack alignItems={"center"}>
                  <Score score={scores.bestCompanyScore} size={90} />
                  <Typography
                    fontSize="16px"
                    fontWeight="500"
                    color={Colors.secondaryText}
                  >
                    {t_dashboard("bestScore")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack alignItems={"center"}>
                  <Score score={scores.averageCompanyScore} size={90} />
                  <Typography
                    fontSize="16px"
                    fontWeight="500"
                    color={Colors.secondaryText}
                  >
                    {t_dashboard("averageScore")}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </>
      )}
    </Stack>
  );
};
export default GroupScore;
