import { PaginationDto } from "@utils/pagination.dto";

export interface Category {
  id: string;
  type: CategoryType;
  name: string;
  mcc: Mcc[];
  isAvailable: boolean;
  averageCO2Consumption: number;
  logoUrl: string;
  mccString?: string;
  isSubjectToKilometers?: boolean;
}

export interface Mcc {
  min: number;
  max: number;
}

export enum CategoryType {
  MSP = "MSP",
  ASP = "ASP",
  OSP = "OSP",
}

export namespace CategoryType {
  export const categories: CategoryType[] = [CategoryType.MSP, CategoryType.ASP, CategoryType.OSP];
}

export interface GetCategories extends PaginationDto {
  serviceCategories: Category[];
}

export interface NewCategory {
  type?: CategoryType;
  name?: TranslatedName;
  mcc?: Mcc[];
  isAvailable?: boolean;
  averageCO2Consumption?: number;
  logoUrl?: string;
  mccString?: string;
}

export interface CategoriesFilter {
  orderByDirection?: "asc" | "desc";
  page: number;
  pageSize?: number;
  orderBy?: string;
  searchText?: string;
  fetchLogos?: boolean;
}

export interface TranslatedName {
  lang: LangEnum;
  value: string;
}

export enum LangEnum {
  FR = "FR",
}

export const formatRangeStringtoRange = (rangeString: string): Mcc[] => {
  // 12,20,30-35 --> [{ 12, 12}, { 20, 20 }, { 30, 35 }]
  return rangeString
    .replace(" ", "")
    .split(",")
    .flatMap((mcc) => {
      const rangeMcc = mcc.split("-");
      if (rangeMcc.length > 1) {
        return { min: parseInt(rangeMcc[0]), max: parseInt(rangeMcc[1]) } as Mcc;
      } else {
        const uniqMcc = parseInt(mcc);
        if (!uniqMcc) {
          return [];
        }
        return { min: uniqMcc, max: uniqMcc } as Mcc;
      }
    });
};

export const formatRangeToRangeString = (range: Mcc[]): string => {
  return range
    .map((it) => {
      if (it.min === it.max) {
        return `${it.min}`;
      } else {
        return `${it.min}-${it.max}`;
      }
    })
    .join(",");
};
