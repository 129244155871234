import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { alpha, CircularProgress, Stack, Typography } from "@mui/material";
import { Colors } from "../../../../../constants/colors.constants";
import { useEffect, useState } from "react";

export enum ScoreStatus {
  GOOD = "GOOD",
  AVERAGE = "AVERAGE",
  BAD = "BAD",
}

export const ScoreStatusTab = [
  {
    title: ScoreStatus.GOOD,
    icon: (
      <CelebrationIcon
        sx={{ color: Colors.score.green, height: "25px", width: "25px" }}
      />
    ),
    color: Colors.score.green,
    limit: 90,
  },
  {
    title: ScoreStatus.AVERAGE,
    icon: (
      <ThumbUpOffAltIcon
        sx={{ color: Colors.score.yellow, height: "25px", width: "25px" }}
      />
    ),
    color: Colors.score.yellow,
    limit: 170,
  },
  {
    title: ScoreStatus.BAD,
    icon: (
      <ThumbDownOffAltIcon
        sx={{ color: Colors.score.red, height: "25px", width: "25px" }}
      />
    ),
    color: Colors.score.red,
    limit: 1000000000,
  },
];

interface ScoreProps {
  score: number;
  size?: number;
  noLabel?: boolean;
}

const Score = (props: ScoreProps) => {
  const { score, size = 50, noLabel = false } = props;
  const status = ScoreStatusTab.find((tab) => score < tab.limit)?.title;
  const scoreColor =
    ScoreStatusTab.find((tab) => tab.title === status)?.color ||
    Colors.score.green;

  const maxValue = 300;
  const [value, setValue] = useState(0);
  const finalValue =
    (score * 100) / maxValue > 100 ? 100 : (score * 100) / maxValue;
  useEffect(() => {
    const interval = setInterval(() => {
      if (value < finalValue) {
        setValue(value + 1);
      }
    }, 7);
    return () => clearInterval(interval);
  }, [value, finalValue]);

  return (
    <Stack alignItems={"center"} justifyContent={"center"} gap={1}>
      <Stack
        position="relative"
        minHeight={size}
        minWidth={size}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress
          variant="determinate"
          value={100}
          size={size}
          sx={{
            position: "absolute",
            color: alpha(scoreColor, 0.1),
          }}
        />
        <CircularProgress
          variant="determinate"
          value={value}
          size={size}
          sx={{
            position: "absolute",
            color: alpha(scoreColor, 0.8),
          }}
        />
        <Stack position="absolute">
          {ScoreStatusTab.find((tab) => tab.title === status)?.icon}
        </Stack>
      </Stack>
      {!noLabel ? (
        <Typography
          fontSize={(size * 12) / 50 + "px"}
          fontWeight={600}
          color={scoreColor}
        >
          {score} g/km
        </Typography>
      ) : null}
    </Stack>
  );
};
export default Score;
