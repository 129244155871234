import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';

import BasicTextfield from '../../../components/inputs/BasicTextfield.component';
import { AlternateEmail, ChevronLeft } from '@mui/icons-material';
import { Colors } from '../../../constants/colors.constants';
import { useNavigate } from 'react-router-dom';
import { sessionService } from '../../../store/session';
import { enqueueSnackbar } from 'notistack';

interface ForgotPasswordFormProps {

}

const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {

  const { t } = useTranslation();
  const navigate =  useNavigate();

  const [email, setEmail] = useState('');

  const handleForgotPassword = () => {
    sessionService.getForgotPassword(email)
    .subscribe({
      next: () => {
        enqueueSnackbar(t('login.recovery.success'), { variant: 'success' });
      },
      error: (err) => enqueueSnackbar(err.text, err.options)
    })
  }

  return (
    <Stack position='relative' justifyContent='space-between' width='100%' p={6} alignItems='center'>
      <Stack alignItems='start' justifyContent='left' width='100%'>
        <Button 
          variant='text' 
          startIcon={<ChevronLeft/>} 
          onClick={() => navigate(-1)}
          aria-label='back'>
            {t('global.back')}
        </Button>
        <Typography fontWeight='800' fontSize='30px' color={Colors.primaryText}>{t('login.recovery.title')}</Typography>
      </Stack>
      <Stack width='70%' gap={6} justifyContent='center'>
        <Stack alignItems='center' gap={1}>
          <Typography color={Colors.primaryText} fontSize='30px' fontWeight='800'>{t('login.recovery.subtitle')}</Typography>
          <Typography color={Colors.secondaryText} fontSize='18px' fontWeight='400' align='center'>
            {t('login.recovery.email')}
          </Typography>
        </Stack>
        <Stack gap={5}>
          <BasicTextfield 
            value={email}
            onChange={(v) => setEmail(v.toLowerCase())}
            placeholder={t('login.email') || ''} 
            endAdornment={<AlternateEmail />} 
            fullWidth />
          <Button 
            variant='contained' 
            color='secondary' 
            onClick={() => handleForgotPassword()}
            fullWidth>
            {t('login.recovery.sendReinitEmail')}
          </Button>
        </Stack>
      </Stack>
      <Stack alignItems='center'>
        <Typography fontSize='16px' fontWeight='500'>{t('login.noAccountYet')}</Typography>
        <Button aria-label='underline'>{t('login.register')}</Button>
      </Stack>
    </Stack>
  )
}

export default ForgotPasswordForm;