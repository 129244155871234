import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from 'react';
import { Button, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs';
import { FieldErrors } from '../../../../utils/yup.utils';
import { enqueueSnackbar } from 'notistack';

import BasicTextfield from '../../../../components/inputs/BasicTextfield.component';
import Close from '../../../../assets/icons/icon_close_dark.svg';
import { groupsServices, Group } from '../../../../store/groups';
import {OtherActionType} from "../../../../utils/table/columnTypes/actions.columnType";

interface AddGroupProps {
  handleClose: () => void;
  handleRefreshGroup:(data: Group, type: OtherActionType) => void;
  group?: Group;
}


const AddGroup = (props: AddGroupProps) => {

  const { group, handleClose, handleRefreshGroup } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<Group | undefined>>(group || undefined);
  const [errors, setErrors] = useState<FieldErrors>({});

    const updateData = (name: string) => (value: any) => setData({ ...data, [name]: value });

  const handleValidate = (evt: FormEvent) => {
    evt.preventDefault();

    if (!data) return;

    setLoading(true);

    if (group) {
      groupsServices
        .updateGroup(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: (data) => {
           enqueueSnackbar(t('Le groupe a été mis à jour'), { variant: 'success' })
            return handleRefreshGroup?.(data, OtherActionType.EDIT)
          }
        })
    } else {
      groupsServices
        .addGroup(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: (data) => {
            enqueueSnackbar(t('Le groupe a été crée'), { variant: 'success' })
            return handleRefreshGroup?.(data, OtherActionType.CREATE)
          }
        })
    }
  }

  return (
    <Dialog open={true} maxWidth='sm' fullWidth>
      <form>
        <Stack direction='column' gap={4} p={4}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography fontSize='30px' fontWeight={800}>{group ? t('groups.add.edit') : t('groups.add.title')}</Typography>
            <IconButton onClick={handleClose}><img alt='' src={Close} /></IconButton>
          </Stack>
          <Stack gap={3} width='100%'>
            <Typography fontSize='20px' fontWeight={700}>{t('groups.add.subtitle')}</Typography>
            <BasicTextfield
              ariaLabel='white'
              color='secondary'
              onChange={updateData('name')}
              error={errors.name}
              fullWidth
              value={data?.name}
              placeholder={t('groups.add.namePlaceholder') || ''}
            />
          </Stack>
          <Stack alignItems='center' pt={4}>
            <Button style={{height: '52px'}} color='secondary' onClick={handleValidate} type='submit'>
              {group ? t('global.validate') : t('global.add')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}

export default AddGroup;
