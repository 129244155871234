import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { groupsStore } from "./groups.store";
import { GroupsFilter, Group, GetGroups } from "./groups.models";
import { getGroupsDataSource } from "./groups.requests";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import { AxiosError, AxiosResponse } from "axios";
import SnackError from "../../utils/errors.utils";


export class GroupsServices {
  store = groupsStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<GroupsFilter>) => 
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters
      }
    }));
  
  addGroup = (newGroup: Partial<Group>): Observable<Group> => {
    return from(APIAxios({
      ...APIRoutes.POSTGroup(),
      data: {
        ...newGroup,
      }
    })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error');
      }),
      map((response: AxiosResponse<Group>) => {
        return response.data;
      }),
      tap((group) => {
        this.store.update(addEntities(group))
      })
    )
  }

  getGroupsPaginated = (filters: GroupsFilter): Observable<GetGroups> => {
    return from(
      APIAxios({
        ...APIRoutes.GETGroupsPaginated(filters.page, filters.orderBy, filters.orderByDirection || 'asc', filters.searchText),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error')
      }),
      map((response: AxiosResponse<GetGroups>) => {
        return response.data;
      }),
      tap((groups) => {
        this.store.update(setEntities(groups.groups), getGroupsDataSource.setSuccess());
      }),
      getGroupsDataSource.trackRequestStatus()
    )
  };

  getGroups = (): Observable<Group[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETGroups(),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error')
      }),
      map((response: AxiosResponse<Group[]>) => {
        return response.data;
      }),
      tap((groups) => {
        this.store.update(setEntities(groups), getGroupsDataSource.setSuccess());
      }),
      getGroupsDataSource.trackRequestStatus()
    )
  };

  updateGroup = (group: Partial<Group>): Observable<Group> => {
    return from(APIAxios({
      ...APIRoutes.PUTGroup(group?.id ?? ''),
      data: {
        ...group,
        id: undefined,
        users: undefined
      }
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error');
      }),
      map((response: AxiosResponse<Group>) => {
        return response.data;
      }),
      tap((group) => {
        this.store.update(updateEntities(group.id, group))
      })
    )
  };

  deleteCategory = (categoryId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETEGroup(categoryId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error');
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        this.store.update(deleteEntities(categoryId));
        return response.data;
      })
    )
  };
}

export const groupsServices = new GroupsServices();