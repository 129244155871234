import { select } from "@ngneat/elf";

import { usersStore } from "./users.store";
import { getUsersDataSource } from "./users.requests";
import { map } from "rxjs";

export class UsersQuery {
  store = usersStore;

  users$ = getUsersDataSource.data$();
  pagination$ = this.store.pipe(select((state) => state.pagination));

  usersCard$ = getUsersDataSource.data$().pipe(
    map((resp) =>
      resp.users.flatMap((user) =>
        user.cards.map((card) => ({
          id: card.id,
          userId: user.id,
          cardId: card.id,
          userFirstName: user.firstName,
          userLastName: user.lastName,
          cardName: card.name,
          cardStatus: card.status,
          cardType: card.type,
          cardGroup: card.group,
          accountMembershipStatus: user.accountMembershipStatus,
        }))
      )
    )
  );

  filters$ = this.store.pipe(select((state) => state.filters));
}

export const usersQuery = new UsersQuery();
