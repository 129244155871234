import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { MIDFilters } from "./mid.models";
import { midExceptionsServices } from "./mid.services";

export const searchMIDEffect = createEffectFn((params$: Observable<MIDFilters>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((filters) => midExceptionsServices.getMidExceptions(filters))
  )
})