import { Components } from '@mui/material';
import { Colors } from '../constants/colors.constants';

const iconButton: Components = {
  MuiIconButton: {
    styleOverrides: {
      root: (props) => ({
        ...(props.ownerState['aria-label'] === 'dashboard' ? {
          height: '52px',
          width: '52px',
          borderRadius: 10,
          color: Colors.white
        } : {}),

      })
    },
    variants: [
      {
        props: { color: 'primary' },
        style: {
          backgroundColor: Colors.primary,
          ':hover': {
            backgroundColor: Colors.primary,
          }
        }
      },
      {
        props: { color: 'secondary' },
        style: {
          backgroundColor: Colors.primaryText,
          ':hover': {
            backgroundColor: Colors.primaryText,
          }
        }
      },
      {
        props: { color: 'warning' },
        style: {
          backgroundColor: 'none',
          color: Colors.warning
        }
      },
      {
        props: { color: 'info' },
        style: {
          backgroundColor: 'none',
          color: Colors.primaryText,
          ':hover': {
            backgroundColor: 'none'
          }
        }
      }
    ]
  }
}

export default iconButton;