import { Colors } from "src/constants/colors.constants";
import { Button, Dialog, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Close from "src/assets/icons/icon_close_dark.svg";
import { SuspendSepa } from "src/store/swan/swan.services";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";

interface SuspendSepaModalProps {
  open: boolean;
  handleClose: () => void;
}
const SuspendSepaModal = (props: SuspendSepaModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation(undefined, { keyPrefix: "suspendSepaModal" });
  const [sepaID, setSepaID] = useState<string>();

  const handleSuspendSepa = () => {
    if (sepaID)
      SuspendSepa(sepaID).then((x) => {
        if (x) {
          enqueueSnackbar(t("success", { id: sepaID }), { variant: "success" });
          handleClose();
        }
      });
  };
  return (
    <Dialog open={open} maxWidth="md" style={{ borderRadius: "8px", zIndex: 2000 }}>
      <Stack direction="column" gap={4} p={4} sx={{ backgroundColor: Colors.background }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="30px" fontWeight={800}>
            {t("title")}
          </Typography>
          <IconButton onClick={handleClose}>
            <img alt="" src={Close} />
          </IconButton>
        </Stack>
        <Stack spacing={5}>
          <Typography fontSize={"24px"}>{t("description")}</Typography>
          <TextField onChange={(e) => setSepaID(e.target.value)} value={sepaID} variant="outlined" label={t("id")} />
          <Button color="secondary" onClick={handleSuspendSepa}>
            {t("suspend")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SuspendSepaModal;
