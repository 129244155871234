import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { paymentStore } from "./payments.store";
import { Payment } from "./payments.models";
import { getPaymentDataSource } from "./payments.requests";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import SnackError from '../../utils/errors.utils'
import { AxiosError, AxiosResponse } from "axios";


export class PaymentServices {
  store = paymentStore;

  resetStore = () => this.store.reset();



  setPaymentIdFromUrl = (paymentId: string) => {
    localStorage.setItem('payment_id', paymentId)
  }

  getPaymentIdFromUrl = (): string | null => {
    return localStorage.getItem('payment_id')
  }

  removePaymentIdFromLocal = () => {
    return localStorage.removeItem('payment_id')
  }
}

export const paymentService = new PaymentServices();
