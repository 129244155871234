import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { MIDException, MIDFilters } from "./mid.models";
import { PaginationDto } from "@utils/pagination.dto";

interface MIDStoreProps {
  filters: MIDFilters;
  pagination?: PaginationDto;
}

export const midStore = createStore(
  { name: "mid" },
  withProps<MIDStoreProps>({
    filters: { page: 1, orderBy: "name" },
  }),
  withEntities<MIDException>(),
  withRequestsStatus()
);
