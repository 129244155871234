import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { blacklistStore } from './blacklist.store';
import { getBlacklistDataSource } from "./blacklist.requests";
import { BlacklistedMerchant, BlacklistFilters, GetBlacklist } from "./blacklist.models";
import { AxiosError, AxiosResponse } from "axios";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import SnackError from "../../utils/errors.utils";

export class BlacklistServices {
  store = blacklistStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<BlacklistFilters>) =>
  this.store.update((state) => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters
    }
}));

  getBlacklist = (filters: BlacklistFilters): Observable<GetBlacklist> => {
    return from(
      APIAxios({...APIRoutes.GETBlacklistedServices(filters.page, filters.orderBy, filters.orderByDirection || 'asc', filters.searchText)})
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error');
      }),
      map((response: AxiosResponse<GetBlacklist>) => {
        return response.data;
      }),
      tap((blacklist) => {
        this.store.update(setEntities(blacklist.blacklistedServices), getBlacklistDataSource.setSuccess());
      }),
      getBlacklistDataSource.trackRequestStatus()
    )
  }

  addBlacklistedMMerchant = (merchant: Partial<BlacklistedMerchant>): Observable<BlacklistedMerchant> => {
    return from(APIAxios({
      ...APIRoutes.POSTBlacklistedService(), data: merchant })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error')
      }),
      map((response: AxiosResponse<BlacklistedMerchant>) => {
        return response.data;
      }),
      tap((blacklistedMerchant) => {
        this.store.update(addEntities(blacklistedMerchant))
      }),
    )
  }

  updateBlacklistedMerchant = (merchant: Partial<BlacklistedMerchant>): Observable<BlacklistedMerchant> => {
    return from(APIAxios({
      ...APIRoutes.PUTBlacklistedService(merchant.id ?? ''), 
      data: {
        ...merchant,
        id: undefined
      }
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error');
      }),
      map((response: AxiosResponse<BlacklistedMerchant>) => {
        return response.data;
      }),
      tap((blacklistedMerchant) => {
        this.store.update(updateEntities(blacklistedMerchant.id, blacklistedMerchant))
      })
    )
  }

  deleteBlacklistedMerchant = (id: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETEBlacklistedService(id))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, 'error')
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        this.store.update(deleteEntities(id));
        return response.data
      })
    )
  };
}

export const blacklistServices = new BlacklistServices();