import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Stack, Grid, Button, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { Colors } from "../../../../constants/colors.constants";

import Header from "../../../../components/header/Header.component";
import { UserRole } from "../../../../store/users";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery, sessionService } from "../../../../store/session";
import { FileUploader } from "react-drag-drop-files";
import UploadImage from "../../../../assets/icons/icon_upload_color.svg";
import { globalSettingsQuery, globalSettingsServices } from "../../../../store/globalSettings";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

const LogoCGU = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "logoCGU" });
  const { t: t_global } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [user] = useObservable(sessionQuery.user$);
  const [{ company }] = useObservable(sessionQuery.company$);
  const [{ globalSettings }] = useObservable(globalSettingsQuery.globalSettings$);

  const [logo, setLogo] = useState<File | undefined>();
  const [cgu, setCgu] = useState<File | undefined>();
  const [helpDocument, setHelpDocument] = useState<File | undefined>();

  const [logoUrl, setLogoUrl] = useState<string | undefined>();
  const [cguUrl, setCguUrl] = useState<string | undefined>();
  const [helpDocumentUrl, setHelpDocumentUrl] = useState<string | undefined>();
  const isSuperAdminWithoutCompany = user?.role === UserRole.SUPER_ADMIN && !sessionQuery.user.accountHolderId;

  const handleUploadLogo = () => {
    if (!logo) return;
    globalSettingsServices.uploafFile(logo).subscribe({
      error: (err) => {
        enqueueSnackbar(err.text, err.options);
      },
      next: (value) => {
        handleUpdateLogo(value.key);
      },
    });
  };

  const handleUpdateLogo = (url: string) => {
    if (user?.role === UserRole.SUPER_ADMIN) {
      globalSettingsServices.uploadLogo(url).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => enqueueSnackbar(t("logoSection.uploadSuccess"), { variant: "success" }),
      });
    } else {
      sessionService.uploadLogoCompany(url, company?.id ?? "").subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => enqueueSnackbar(t("logoSection.uploadSuccess"), { variant: "success" }),
      });
    }
  };

  const handleUploadCGU = () => {
    if (!cgu) return;
    globalSettingsServices.uploafFile(cgu).subscribe({
      error: (err) => {
        enqueueSnackbar(err.text, err.options);
      },
      next: (value) => {
        handleUpdateCGU(value.key);
      },
    });
  };

  const handleUpdateCGU = (url: string) => {
    if (user?.role === UserRole.SUPER_ADMIN) {
      globalSettingsServices.uploadCGU(url).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => enqueueSnackbar(t("cguSection.uploadSuccess"), { variant: "success" }),
      });
    } else {
      sessionService.uploadCGUCompany(url, company?.id ?? "").subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => enqueueSnackbar(t("cguSection.uploadSuccess"), { variant: "success" }),
      });
    }
  };

  const handleUploadHelpDocument = () => {
    if (!helpDocument) return;
    globalSettingsServices.uploafFile(helpDocument).subscribe({
      error: (err) => {
        enqueueSnackbar(err.text, err.options);
      },
      next: (value) => {
        handleUpdateHelpDocument(value.key);
      },
    });
  };

  const handleUpdateHelpDocument = (url: string) => {
    if (user?.role === UserRole.SUPER_ADMIN) {
      globalSettingsServices.uploadHelpDocument(url).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => enqueueSnackbar(t("helpDocumentSection.uploadSuccess"), { variant: "success" }),
      });
    } else {
      sessionService.updateHelpDocumentForCompany(url, company?.id ?? "").subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => enqueueSnackbar(t("helpDocumentSection.uploadSuccess"), { variant: "success" }),
      });
    }
  };

  useEffect(() => {
    company?.cguUrl &&
      globalSettingsServices.getFile(company?.cguUrl).subscribe({
        next: (value) => setCguUrl(value.url),
      });
  }, [company?.cguUrl]);

  useEffect(() => {
    company?.onlineHelpUrl &&
      globalSettingsServices.getFile(company?.onlineHelpUrl).subscribe({
        next: (value) => setHelpDocumentUrl(value.url),
      });
  }, [company?.onlineHelpUrl]);

  useEffect(() => {
    if (company?.logoUrl) {
      globalSettingsServices.getFile(company?.logoUrl).subscribe({
        next: (value) => setLogoUrl(value.url),
      });
    } else if (user?.role === UserRole.SUPER_ADMIN) {
      globalSettingsServices.getGlobalSettings().subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: (value) => {
          globalSettingsServices.getFile(value.logoUrl).subscribe({
            next: (value) => setLogoUrl(value.url),
          });
        },
      });
    }
  }, [company?.logoUrl, enqueueSnackbar, user?.role]);

  return (
    <Stack alignItems="start" gap={2} height="100%" p={3} direction="column">
      <Header role={user?.role || UserRole.ADMIN_COMPANY} />
      <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
        {t_global("global.back")}
      </Button>
      <Stack direction="column" justifyContent="space-between" width="100%" height="100%" gap={3}>
        <Typography fontSize="30px" fontWeight={800} color={Colors.primaryText}>
          {t("title")}
        </Typography>
        <Grid container direction="row" height="100%" gap={2} justifyContent="space-between">
          <Grid item xs style={{ backgroundColor: "white", borderRadius: "20px" }} p={4}>
            <Stack gap={3} height="100%">
              <Stack gap={1}>
                <Typography fontSize={18} fontWeight={700}>
                  {t("logoSection.title")}
                </Typography>
                <Typography fontSize={16} fontWeight={400} color={Colors.secondaryText}>
                  {t("logoSection.description")}
                </Typography>
              </Stack>
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                style={{
                  border: `solid ${Colors.primary}`,
                  borderWidth: "1px",
                  borderStyle: "dashed",
                  borderRadius: "14px",
                  cursor: "pointer",
                }}
              >
                <FileUploader
                  color={Colors.primary}
                  handleChange={(value: File) => setLogo(value)}
                  file={logo}
                  name={"test"}
                  types={["PNG", "JPG", "JPEG"]}
                  label="test"
                >
                  <Stack gap={3} style={{ cursor: "pointer" }} alignItems="center" justifyContent="center">
                    <img
                      style={{
                        maxHeight: "100px",
                        maxWidth: "100px",
                      }}
                      alt=""
                      src={logo ? URL.createObjectURL(logo) : logoUrl ?? UploadImage}
                    />
                    <Stack alignItems="center">
                      <Typography fontWeight={500} fontSize={14}>
                        {t("logoSection.dropZoneTitle")}
                      </Typography>
                      <Typography fontWeight={400} fontSize={12}>
                        {t("logoSection.dropZoneDescription")}
                      </Typography>
                    </Stack>
                  </Stack>
                </FileUploader>
              </Stack>
              <Stack alignItems="center" gap={2}>
                <Typography fontSize={12} fontWeight={400} color={Colors.secondaryText}>
                  {t("logoSection.acceptedFormats")}
                </Typography>
                <Button color="primary" variant="contained" disabled={!logo} onClick={() => handleUploadLogo()}>
                  {t("logoSection.buttonUpload")}
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs style={{ backgroundColor: "white", borderRadius: "20px" }} p={4}>
            <Stack gap={3} height="100%">
              <Stack gap={1}>
                <Typography fontSize={18} fontWeight={700}>
                  {t("cguSection.title")}
                </Typography>
                <Typography fontSize={16} fontWeight={400} color={Colors.secondaryText}>
                  {t("cguSection.description")}
                </Typography>
              </Stack>
              <Stack
                style={{ backgroundColor: Colors.background, borderRadius: "8px" }}
                justifyContent="space-between"
                direction="row"
                p={1}
              >
                <Typography fontSize={16} fontWeight={500} color={Colors.secondaryText}>
                  {t("updateDate")}
                </Typography>
                <Typography fontSize={16} fontWeight={500} color={Colors.secondaryText}>
                  {isSuperAdminWithoutCompany
                    ? globalSettings?.cguUpdatedAt
                      ? format(new Date(globalSettings.cguUpdatedAt), "dd/MM/yyyy")
                      : ""
                    : company?.cguUpdatedAt
                    ? format(new Date(company.cguUpdatedAt), "dd/MM/yyyy")
                    : ""}
                </Typography>
              </Stack>
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                style={{
                  border: `solid ${Colors.primary} 1px`,
                  borderStyle: "dashed",
                  borderRadius: "14px",
                  cursor: "pointer",
                }}
              >
                <FileUploader
                  color={Colors.primary}
                  handleChange={(value: File) => setCgu(value)}
                  types={["PDF"]}
                  label="test"
                >
                  <Stack gap={3} style={{ cursor: "pointer" }} alignItems="center" justifyContent="center">
                    <img alt="" src={UploadImage} />
                    <Stack alignItems="center">
                      <Typography fontWeight={500} fontSize={14}>
                        {t("cguSection.dropZoneTitle")}
                      </Typography>
                      <Typography fontWeight={400} fontSize={12}>
                        {t("cguSection.dropZoneDescription")}
                      </Typography>
                    </Stack>
                  </Stack>
                </FileUploader>
              </Stack>
              <Stack alignItems="center" gap={2}>
                <Typography fontSize={12} fontWeight={400} color={Colors.secondaryText}>
                  {t("cguSection.acceptedFormats")}
                </Typography>
                <Button color="primary" variant="contained" disabled={!cgu} onClick={() => handleUploadCGU()}>
                  {t("cguSection.buttonUpload")}
                </Button>
              </Stack>
            </Stack>
          </Grid>
          {isSuperAdminWithoutCompany && (
            <Grid item xs style={{ backgroundColor: "white", borderRadius: "20px" }} p={4}>
              <Stack gap={3} height="100%">
                <Stack gap={1}>
                  <Typography fontSize={18} fontWeight={700}>
                    {t("helpDocumentSection.title")}
                  </Typography>
                  <Typography fontSize={16} fontWeight={400} color={Colors.secondaryText}>
                    {t("helpDocumentSection.description")}
                  </Typography>
                </Stack>
                <Stack
                  style={{ backgroundColor: Colors.background, borderRadius: "8px" }}
                  justifyContent="space-between"
                  direction="row"
                  p={1}
                >
                  <Typography fontSize={16} fontWeight={500} color={Colors.secondaryText}>
                    {t("updateDate")}
                  </Typography>
                  <Typography fontSize={16} fontWeight={500} color={Colors.secondaryText}>
                    {/* Date de mise à jour de la documentation d'aide en ligne */}
                    {user?.role === UserRole.SUPER_ADMIN
                      ? globalSettings?.onlineHelpUrlUpdatedAt
                        ? format(new Date(globalSettings.onlineHelpUrlUpdatedAt), "dd/MM/yyyy")
                        : ""
                      : company?.onlineHelpUrlUpdatedAt
                      ? format(new Date(company.onlineHelpUrlUpdatedAt), "dd/MM/yyyy")
                      : ""}
                  </Typography>
                </Stack>
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    border: `solid ${Colors.primary} 1px`,
                    borderStyle: "dashed",
                    borderRadius: "14px",
                    cursor: "pointer",
                  }}
                >
                  <FileUploader
                    color={Colors.primary}
                    handleChange={(value: File) => setHelpDocument(value)}
                    types={["PDF"]}
                    label="test"
                  >
                    <Stack gap={3} style={{ cursor: "pointer" }} alignItems="center" justifyContent="center">
                      <img alt="" src={UploadImage} />
                      <Stack alignItems="center">
                        <Typography fontWeight={500} fontSize={14}>
                          {t("helpDocumentSection.dropZoneTitle")}
                        </Typography>
                        <Typography fontWeight={400} fontSize={12}>
                          {t("helpDocumentSection.dropZoneDescription")}
                        </Typography>
                      </Stack>
                    </Stack>
                  </FileUploader>
                </Stack>
                <Stack alignItems="center" gap={2}>
                  <Typography fontSize={12} fontWeight={400} color={Colors.secondaryText}>
                    {t("helpDocumentSection.acceptedFormats")}
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!helpDocument}
                    onClick={() => handleUploadHelpDocument()}
                  >
                    {t("helpDocumentSection.buttonUpload")}
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default LogoCGU;
