import { object, string } from 'yup';
import { MIDException } from '../../../store/mid';

import I18n from '../../../utils/i18n.utils';
import * as YupUtils from '../../../utils/yup.utils';

export const addMidValidation = (mid: Partial<MIDException>) => {
  const requiredString = string().required(I18n.t('errors.required') || '');

  try {
    const schema = object().shape({
      name: requiredString,
      mid: requiredString,
      // serviceCategory: 
    });
    schema.validateSync(mid, { abortEarly: false });
    return {}
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}