import { alpha, Stack, Typography } from "@mui/material";
import { globalSettingsServices } from "src/store/globalSettings";
import { DistancebyCategoryModel } from "src/store/scores";
import { Colors } from "../../../../../constants/colors.constants";

interface CategoryScoreProps {
  category: DistancebyCategoryModel;
}

const CategoryScore = (props: CategoryScoreProps) => {
  const { category } = props;
  const getImage = (key: string): any => {
    globalSettingsServices.getFile(key).subscribe({
      next: (value) => {
        return value.url;
      },
    });
  };
  return (
    <Stack
      flex={1}
      key={Math.random().toString(36).substring(7)}
      sx={{
        backgroundColor: alpha(Colors.white, 0.7),
        borderRadius: "10px",
        padding: "20px",
        gap: "8px",
        mb: "8px",
        height: "100%",
        alignItems: "center",
        justifyContent: "space-around",
        textAlign: "center",
        "&:hover": {
          backgroundColor: Colors.white,
          cursor: "pointer",
        },
      }}
    >
      <img
        src={getImage(category.categoryLogo)}
        alt={category.categoryName}
        style={{ width: "50px", height: "50px" }}
      />
      <Typography fontSize="20px" fontWeight="700">
        {category.categoryName}
      </Typography>
      <Typography
        fontSize="18px"
        fontWeight="800"
        color={
          category.totalDistance > 1000
            ? Colors.score.red
            : category.totalDistance > 500
            ? Colors.score.yellow
            : Colors.score.green
        }
      >
        {category.totalDistance} km
      </Typography>
    </Stack>
  );
};

export default CategoryScore;
