import { Components } from '@mui/material';
import { Colors } from '../constants/colors.constants';

const tab: Components = {
  MuiTabs: {
    styleOverrides: {
      root: (props) => ({
        backgroundColor: 'white',
        borderRadius: 10,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 72
      })
    }
  },
  MuiTab: {
    styleOverrides: {
      root: () => ({
        backgroundColor: Colors.black,
        borderRadius: 8,
        height: 44,
        maxHeight: 44,
        minWidth: 44,
        minHeight: 0,
        maxWidth: 44,
        padding: 0
      })
    }
  }
}

export default tab;