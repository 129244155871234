import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { paymentRulesStore } from "./paymentRules.store";
import { GetPaymentControlRule, PaymentControlRuleFilters, PaymentRule } from "./paymentRules.models";
import { getPaymentRulesDataSource } from "./paymentRules.requests";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import { AxiosError, AxiosResponse } from "axios";
import SnackError from "../../utils/errors.utils";

export class PaymentRulesServices {
  store = paymentRulesStore;
  setFilters = (filters: Partial<PaymentControlRuleFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  resetStore = () => this.store.reset();

  addPaymentRule = (newRule: Partial<PaymentRule>): Observable<PaymentRule> => {
    return from(APIAxios({ ...APIRoutes.POSTPaymentRules(), data: newRule })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaymentRule>) => {
        return response.data;
      }),
      tap((rule) => {
        this.store.update(addEntities(rule));
      })
    );
  };

  getPaymentRules = (filters: PaymentControlRuleFilters): Observable<GetPaymentControlRule> => {
    return from(
      APIAxios({
        ...APIRoutes.GETPaymentRules(filters.page, filters.groupId, filters.orderBy, filters.orderByDirection || "asc"),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<GetPaymentControlRule>) => {
        return response.data;
      }),
      tap((paymentRules) => {
        this.store.update(setEntities(paymentRules.paymentControlRules), getPaymentRulesDataSource.setSuccess());
      }),
      getPaymentRulesDataSource.trackRequestStatus()
    );
  };

  updatePaymentRule = (rule: Partial<PaymentRule>): Observable<PaymentRule> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTPaymentRule(rule.id ?? ""),
        data: {
          ...rule,
          category: rule.category?.id,
          id: undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaymentRule>) => {
        return response.data;
      }),
      tap((rule) => {
        this.store.update(updateEntities(rule.id, rule));
      })
    );
  };

  deletePaymentRule = (ruleId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETEPaymentRule(ruleId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        paymentRulesServices.store.update(deleteEntities(ruleId));
        return response.data;
      })
    );
  };
}

export const paymentRulesServices = new PaymentRulesServices();
