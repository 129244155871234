import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import theme from './themes/index';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';

import Screens from './screens';
import { LocalizationProvider } from '@mui/x-date-pickers';

import PwaModal from '../src/components/PwaModal';

function App() {
  const [showInstallModal, setShowInstallModal] = useState<Boolean>(false);
  const [prompt, setPrompt] = useState<any>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault();
      setPrompt(event);

      if (!window.matchMedia("(display-mode: standalone)").matches &&
          window.matchMedia("(max-width: 600px)").matches) {
        setShowInstallModal(true);
      }
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const handleCloseModal = () => setShowInstallModal(false);
  const handleInstallClick = () => {
    if (prompt) {
      prompt.prompt();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider 
          maxSnack={3}
          autoHideDuration={3000}
          iconVariant={{}}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
          <CssBaseline/>
            <BrowserRouter>
              <Screens/>
            </BrowserRouter>
            {showInstallModal && <PwaModal show={showInstallModal} onClose={handleCloseModal} onInstall={handleInstallClick}/>}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
