import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { PaymentControlRuleFilters } from "./paymentRules.models";
import { paymentRulesServices } from "./paymentRules.services";

export const searchPaymentControlRuleEffect = createEffectFn((params$: Observable<PaymentControlRuleFilters>) => {
  return params$.pipe(
    debounceTime(300),
    switchMap((filters) => paymentRulesServices.getPaymentRules(filters))
  )
})