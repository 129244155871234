import { IconButton, Stack, Typography } from "@mui/material";
import { ExportTransactionFilters, TransactionFields, TransactionStatus } from "../../../../store/transaction";
import BasicSelect from "../../../../components/inputs/BasicSelect.component";
import { useTranslation } from "react-i18next";
import { DateField } from "@mui/x-date-pickers";
import Delete from "../../../../assets/icons/icon_delete_color.svg";

interface ExportTransactionsProps {
  exportModalOpen: boolean;
  updateExportTransactionFilters: (name: keyof ExportTransactionFilters) => (value: any) => void;
  exportTransactionFilters: Partial<ExportTransactionFilters>;
  fieldsString: string[];
  updateFieldsString: (value: string[]) => void;
}

const FormExportTransaction = (props: ExportTransactionsProps) => {
  const {
    exportModalOpen,
    updateExportTransactionFilters,
    exportTransactionFilters,
    fieldsString,
    updateFieldsString,
  } = props;
  const { t } = useTranslation();

  //todo ask to put: initFieldString here to above error with status

  return (
    <Stack direction="row" width="100%" height="75%">
      <Stack flex={1} spacing={4}>
        <Stack direction="row" alignItems={"center"} justifyContent="space-between">
          <Typography fontSize="20px" fontWeight={700}>
            {t("payments.export.form.status")}
          </Typography>
          <BasicSelect
            maxWidth={"50%"}
            ariaLabel="white"
            color="secondary"
            handleChange={updateExportTransactionFilters("status")}
            items={[
              { label: t("payments.allPayments"), value: undefined },
              { label: t("payments.filter.completed"), value: TransactionStatus.OK },
              { label: t("payments.toBeCompleted"), value: TransactionStatus.ACTION_REQUIRED },
              { label: t("payments.filter.refused"), value: TransactionStatus.FAILED },
            ]}
            fullWidth
            value={exportTransactionFilters?.status}
          />
        </Stack>
        <Stack direction="row" alignItems={"center"} justifyContent="space-between">
          <Typography fontSize="20px" fontWeight={700}>
            {t("payments.export.form.period")}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Stack>
              <Typography>{t("payments.export.form.startDate")}</Typography>
              <DateField
                value={exportTransactionFilters.from ? Date.parse(exportTransactionFilters.from) : undefined}
                onChange={updateExportTransactionFilters("from")}
                aria-label="white"
                format="dd-MM-yyyy"
              />
            </Stack>
            <Stack>
              <Typography>{t("payments.closePeriod.endDate")}</Typography>
              <DateField
                value={exportTransactionFilters.to ? Date.parse(exportTransactionFilters.to) : undefined}
                onChange={updateExportTransactionFilters("to")}
                aria-label="white"
                aria-placeholder="Depuis le début si vide"
                format="dd-MM-yyyy"
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems={"center"} justifyContent="space-between">
          <Typography fontSize="20px" fontWeight={700}>
            {t("payments.export.form.paymentCBUpdatable")}
          </Typography>
          <BasicSelect
            maxWidth={"50%"}
            ariaLabel="white"
            color="secondary"
            handleChange={(value) => {
              updateExportTransactionFilters("isUpdatable")(value);
            }}
            items={[
              { label: t("payments.export.form.all"), value: undefined },
              { label: t("payments.export.form.yes"), value: true },
              { label: t("payments.export.form.no"), value: false },
            ]}
            fullWidth
            value={exportTransactionFilters.isUpdatable}
          />
        </Stack>
        <Stack flex={1} spacing={2}>
          <Stack direction="row" alignItems={"center"} justifyContent="space-between">
            <Typography fontSize="20px" fontWeight={700}>
              {t("payments.export.form.fieldsToExport")}
            </Typography>
            <Stack direction="row" width={"50%"} spacing={1}>
              <BasicSelect
                maxWidth={"100%"}
                ariaLabel="white"
                color="secondary"
                value={""}
                handleChange={(field) => updateFieldsString([...fieldsString!, field])}
                placeholder={t("global.selectFields") || ""}
                items={TransactionFields.fields
                  .filter((it) => !fieldsString?.includes(it))
                  .map((it, idx) => ({ label: TransactionFields.label(it), value: it }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                fullWidth
              />
              {/*<Button*/}
              {/*  onClick={() => {*/}
              {/*    setFields([...fields, fieldToAdd!]);*/}
              {/*    setFieldToAdd(undefined);*/}
              {/*  }}*/}
              {/*  disabled={!fieldToAdd}*/}
              {/*  style={{ width: '124px', height: '52px' }}*/}
              {/*  color='secondary'*/}
              {/*  variant='contained'>Ajouter</Button>*/}
            </Stack>
          </Stack>
          <Stack direction="row" alignItems={"center"} justifyContent="space-between">
            <Stack></Stack>
            <Stack
              direction="column"
              alignItems={"flex-end"}
              justifyContent="start"
              height="280px"
              width={"50%"}
              overflow="auto"
            >
              <>
                {fieldsString &&
                  fieldsString.map((field, idx) => {
                    return (
                      <Stack
                        key={`${field}-${idx}`}
                        direction="row"
                        alignItems={"center"}
                        width={"100%"}
                        spacing={1}
                        justifyContent="space-between"
                      >
                        <Typography fontSize="18px" fontWeight={700}>
                          {idx + 1}:
                        </Typography>
                        <Typography>{TransactionFields.label(field as TransactionFields)}</Typography>
                        <IconButton
                          onClick={() => {
                            const newFields = fieldsString.filter((_, index) => index !== idx);
                            updateFieldsString(newFields);
                          }}
                        >
                          <img alt="" src={Delete} />
                        </IconButton>
                      </Stack>
                    );
                  })}
              </>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FormExportTransaction;
