import { select } from '@ngneat/elf';

import { sessionStore } from './session.store';
import { getCompanyDataSource, getUserDataSource } from './session.requests';

import { User } from '../users/user.model';
import { Company } from './session.model';

export class SessionQuery {
  store = sessionStore;

  isAuthenticated$ = getUserDataSource.data$();

  user$ = this.store.pipe(select((state) => state.user));
  company$ = getCompanyDataSource.data$();

  get company(): Company {
    return this.store.getValue().company!;
  }

  get user(): User {
    return this.store.getValue().user!;
  }

  get token() {
    return this.store.getValue().accessToken;
  }

  get role() {
    return this.store.getValue().user?.role;
  }
}

export const sessionQuery = new SessionQuery(); 