import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { PaymentControlRuleFilters, PaymentRule } from "./paymentRules.models";

interface PaymentControlStoreProps {
  filters: PaymentControlRuleFilters;
}

export const paymentRulesStore = createStore({ name: 'paymentRules' },
  withProps<PaymentControlStoreProps>({
    filters: { page: 1, orderBy: 'name' },
  }),
  withEntities<PaymentRule>(),
  withRequestsStatus()
);