import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from '@ngneat/elf-entities';

import { blacklistStore } from "./blacklist.store";

export const getBlacklistDataSource = createRequestDataSource({
  store: blacklistStore,
  data$: () => blacklistStore.pipe(selectAllEntities()),
  requestKey: 'getBlacklist',
  dataKey: 'blacklist'
});