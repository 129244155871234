import { object, string } from 'yup';

import { AuthData } from '../../../store/session';
import * as YupUtils from '../../../utils/yup.utils';
import I18n from '../../../utils/i18n.utils';

export const signInValidation = (signInData: AuthData) => {
  const requiredString = string().required(I18n.t('errors.required') || '');
  try {
    const schema = object().shape({
      email: requiredString.email(I18n.t('errors.email') || ''),
      password: requiredString,
    });

    schema.validateSync(signInData, { abortEarly: false });
    return {}
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}