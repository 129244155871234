import { Components } from '@mui/material';
import { Colors } from '../constants/colors.constants';

const switchInput: Components = {
  MuiSwitch: {
    styleOverrides: {
      root: (props) => ({
        position: 'relative',
        height: 22,
        padding: 0,
        justifyContent: 'center',
        '& .MuiSwitch-switchBase': {
          padding: 5,
          paddingLeft: 6,
          alignSelf: 'center',
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: Colors.white,
            '& + .MuiSwitch-track': {
              opacity: 1,
            },
          },
        },
        '& .MuiSwitch-thumb': {
          width: 12,
          height: 12,
          left: '1px',
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          position: 'absolute',
          backgroundColor: Colors.primaryText,
          height: 22,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 11,
          width: 37,
        },
      })
    }
  }
}

export default switchInput;