import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import Login from './login/login.screen';

import { RoutesUnAuth } from '../../utils/routes.utils';

const UnAuth = () => {
  return (
    <Routes>
      <Route path={RoutesUnAuth.LOGIN} element={<Login />} />
      <Route path={RoutesUnAuth.LOST_PASSWORD} element={<Login forgotPassword={true} />} />
      <Route path='*' element={<Navigate to={RoutesUnAuth.LOGIN} replace />} />
    </Routes>
  )
}

export default UnAuth;