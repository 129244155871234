import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { Group, GroupsFilter } from "./groups.models";
import { groupsServices } from "./groups.services";

export const searchGroupsEffect = createEffectFn((param$: Observable<GroupsFilter>) => {
  return param$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((filters) => groupsServices.getGroupsPaginated(filters))
  )
})