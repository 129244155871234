import { SwanStatusInfo, TransactionLight, TransactionStatus } from "src/store/transaction";

export const filterDuplicatePayments = (transactions: TransactionLight[]): TransactionLight[] => {
  const filteredTransactions: TransactionLight[] = [];

  transactions.forEach((transaction) => {
    if (transaction.serviceCategory?.name === "Carburant") {
      const duplicateIndex = filteredTransactions.findIndex((t) => {
        const isSameCard = t.card?.id === transaction.card?.id;
        const isSameAmount = t.amount === transaction.amount;
        const isCloseInTime =
          Math.abs(new Date(t.date).getTime() - new Date(transaction.date).getTime()) < 15 * 60 * 1000;
        return isSameCard && isSameAmount && isCloseInTime;
      });

      if (duplicateIndex !== -1) {
        const duplicate = filteredTransactions[duplicateIndex];
        if (transaction.status === TransactionStatus.OK) {
          filteredTransactions[duplicateIndex] = transaction;
        } else if (
          duplicate.swanStatusInfo === SwanStatusInfo.PENDING &&
          duplicate.status === TransactionStatus.ACTION_REQUIRED
        ) {
          filteredTransactions[duplicateIndex] = transaction;
        } else if (
          transaction.status === TransactionStatus.ACTION_REQUIRED &&
          transaction.swanStatusInfo !== SwanStatusInfo.PENDING &&
          duplicate.status !== TransactionStatus.OK
        ) {
          filteredTransactions.push(transaction);
        }
      } else {
        filteredTransactions.push(transaction);
      }
    } else {
      filteredTransactions.push(transaction);
    }
  });

  return filteredTransactions;
};
