import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";

import { categoriesStore } from "./categories.store";

export const getCategoriesDataSource = createRequestDataSource({
  store: categoriesStore,
  data$: () => categoriesStore.pipe(selectAllEntities()),
  requestKey: 'getCategories',
  dataKey: 'categories'
});