import JSZip from "jszip";
import { Observable } from "rxjs";
import { saveAs } from "file-saver";

export const downloadCSV = async (data: string, filename: string): Promise<void> => {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const downloadZip = async (accountingData: string, transactionData: string, fileName: string) => {
  const zip = new JSZip();

  // Ajoutez les fichiers CSV au zip
  zip.file("exportAccounting.csv", accountingData);
  zip.file("exportTransaction.csv", transactionData);

  // Générez le zip et téléchargez-le
  const content = await zip.generateAsync({ type: "blob" });
  saveAs(content, `${fileName}.zip`);
};

export const downloadCSVObservable = (data: any, fileName: string) => {
  return new Observable<void>((observer) => {
    const BOM = "\uFEFF";
    const url = window.URL.createObjectURL(new Blob([BOM + data], { type: "text/csv; charset=utf8" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    observer.complete();
  });
};
