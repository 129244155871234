import { DistanceInfo, Coordinates } from "@utils/distanceUtils";

export async function getDistanceInfo(
	origin: Coordinates,
	destination: Coordinates,
	mode: string
): Promise<DistanceInfo> {
	if (!(mode.toUpperCase() in google.maps.TravelMode)) {
		throw new Error('Invalid travel mode');
	}

	const directionsService = new google.maps.DirectionsService();

	const request = {
		origin: new google.maps.LatLng(origin.lat, origin.lng),
		destination: new google.maps.LatLng(destination.lat, destination.lng),
		travelMode:
			google.maps.TravelMode[
			mode.toUpperCase() as keyof typeof google.maps.TravelMode
			],
	};

	return new Promise((resolve, reject) => {
		directionsService.route(request, (response, status) => {
			if (status === google.maps.DirectionsStatus.OK) {
				if (response !== null) {
					const route = response.routes[0];
					const leg = route.legs[0];

					resolve({
						distance: (leg.distance?.value || 0) / 1000,
						duration: leg.duration?.value || 0,
					});
				} else {
					reject(new Error('No response from Google Directions API'));
				}
			} else {
				reject(
					new Error('Failed to fetch distance info from Google Directions API')
				);
			}
		});
	});
}
